import React from 'react';
import { useDispatch } from 'react-redux';
import { applySearchParams } from '../../features/searchParams/searchParamsSlice';
import { resetListings } from '../../features/listings/listingsSlice';

const ApplyButton = () => {
	const dispatch = useDispatch();

	const handleApply = () => {
		dispatch(resetListings());
		dispatch(applySearchParams());
	};

	return (
		<button
			onClick={handleApply}
			className="px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
		>
			Apply
		</button>
	);
};

export default ApplyButton;
