import React from 'react';
import { useDispatch } from 'react-redux';
import { applySearchParams } from '../../features/searchParams/searchParamsSlice';
import { toggleFilterPanel } from '../../features/active/activeSlice';

const MoreOptions = () => {
	const dispatch = useDispatch();

	const handleApply = () => {
		dispatch(toggleFilterPanel());
		// dispatch(applySearchParams());
	};

	return (
		<button
			onClick={handleApply}
			className="flex items-center 
                    justify-between 
                    px-3 py-2 
                    border border-gray-300 
                    rounded-md 
                    shadow-sm "
		>
			More Options
		</button>
	);
};

export default MoreOptions;
