import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';
import { updateUserProfile, deleteUserAccount } from '../../features/user/userSlice';
import { uploadImages } from '../../features/searchParams/searchParamsSlice';

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const [formData, setFormData] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    username: user?.username || '',
    email: user?.email || '',
    currentAddress: user?.currentAddress || '',
    preferredLocations: user?.preferredLocations || [],
    budget: user?.budget || '',
    preferredBeds: user?.preferredBeds || '',
    preferredBaths: user?.preferredBaths || '',
    preferredParking: user?.preferredParking || '',
    houseDescription: user?.houseDescription || '',
    favoriteRoom: user?.favoriteRoom || '',
    stayingWith: user?.stayingWith || [],
  });
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [roomStyles, setRoomStyles] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
    // Dispatch action to upload profile photo
    dispatch(uploadImages(file));
  };

  const handleRoomStyleUpload = (e) => {
    const files = Array.from(e.target.files);
    setRoomStyles(prevStyles => [...prevStyles, ...files]);
    // Dispatch action to upload room styles
    files.forEach(file => dispatch(uploadImages(file)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch action to update user profile
    dispatch(updateUserProfile(formData));
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      dispatch(deleteUserAccount());
      navigate('/');
    }
  };

  const handleInviteCollaborator = (email) => {
    // Dispatch action to invite collaborator
    console.log(`Inviting collaborator: ${email}`);
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white p-4">
      <div className="w-full max-w-2xl">
        <div className="mb-8 text-center">
          <Logo className="mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-pinhous-red">Edit Profile</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Profile Photo</label>
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoUpload}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Current Address</label>
            <input
              type="text"
              name="currentAddress"
              value={formData.currentAddress}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Preferred Locations</label>
            <input
              type="text"
              name="preferredLocations"
              value={formData.preferredLocations.join(', ')}
              onChange={(e) => setFormData({...formData, preferredLocations: e.target.value.split(', ')})}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Budget</label>
            <input
              type="number"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div className="flex space-x-4">
            <div className="w-1/3">
              <label className="block text-sm font-medium text-gray-700">Preferred Beds</label>
              <input
                type="number"
                name="preferredBeds"
                value={formData.preferredBeds}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
              />
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium text-gray-700">Preferred Baths</label>
              <input
                type="number"
                name="preferredBaths"
                value={formData.preferredBaths}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
              />
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium text-gray-700">Preferred Parking</label>
              <input
                type="number"
                name="preferredParking"
                value={formData.preferredParking}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">House Description</label>
            <textarea
              name="houseDescription"
              value={formData.houseDescription}
              onChange={handleChange}
              rows="4"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            ></textarea>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Favorite Room</label>
            <input
              type="text"
              name="favoriteRoom"
              value={formData.favoriteRoom}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Room Styles</label>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleRoomStyleUpload}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Staying With</label>
            <input
              type="text"
              name="stayingWith"
              value={formData.stayingWith.join(', ')}
              onChange={(e) => setFormData({...formData, stayingWith: e.target.value.split(', ')})}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pinhous-red focus:border-pinhous-red"
            />
          </div>

          <div>
            <button
              type="button"
              onClick={() => handleInviteCollaborator(prompt('Enter collaborator email:'))}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pinhous-red hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pinhous-red"
            >
              Invite Collaborator
            </button>
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pinhous-red hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pinhous-red"
            >
              Save Changes
            </button>
          </div>

          <div>
            <button
              type="button"
              onClick={() => navigate('/change-password')}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Change Password
            </button>
          </div>

          <div>
            <button
              type="button"
              onClick={handleDeleteAccount}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;