import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';
import {
	loginUser,
	selectUserError,
	selectUserStatus,
	clearError,
	resetStatus,
	selectIsAuthenticated
} from '../../features/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const loginError = useSelector(selectUserError);
	const loginStatus = useSelector(selectUserStatus);
	const isAuthenticated = useSelector(selectIsAuthenticated);

	// useEffect(() => {
	// 	dispatch(resetStatus());
	// }, []);

	useEffect(() => {
		if (loginStatus === 'succeeded' || isAuthenticated) {
			dispatch(resetStatus());
			navigate('/');
		}
	}, [loginStatus, navigate]);

	useEffect(() => {
		return () => {
			dispatch(clearError());
		};
	}, [dispatch]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value
		}));
		// Clear error when user starts typing
		if (errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: ''
			}));
		}
	};

	const validateForm = () => {
		let newErrors = {};
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			newErrors.email = 'Email is invalid';
		}
		if (!formData.password) {
			newErrors.password = 'Password is required';
		} else if (formData.password.length < 6) {
			newErrors.password = 'Password must be at least 6 characters';
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			// Handle login logic here
			// console.log('Login attempt with:', formData);
			// You would typically make an API call here
			// For now, let's simulate a successful login
			dispatch(loginUser(formData));
			// navigate('/dashboard');
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
			<div className="w-full max-w-md">
				<div className="mb-8 text-center">
					<div className="flex justify-center mb-4">
						<Logo />
					</div>
					<h2 className="text-xl font-normal text-[#000]">
						The Right Way to Find Your
					</h2>
					<h2 className="text-xl font-normal text-[#000]">Dream Home</h2>
					{!loginError ? (
						<p className="mt-5 text-[#000]">Welcome back!</p>
					) : (
						<p className="text-pinhous-red text-sm mt-2">{loginError}</p>
					)}
				</div>

				<form className="space-y-4" onSubmit={handleSubmit}>
					<div>
						<input
							type="email"
							name="email"
							placeholder="Email"
							value={formData.email}
							onChange={handleChange}
							className={`w-full px-4 py-2 border ${
								errors.email ? 'border-pinhous-red' : 'border-[#878787]'
							} rounded-full focus:outline-none focus:border-pinhous-red`}
						/>
						{errors.email && (
							<p className="text-pinhous-red text-xs mt-1">{errors.email}</p>
						)}
					</div>
					<div className="relative">
						<input
							type={showPassword ? 'text' : 'password'}
							name="password"
							placeholder="Password"
							value={formData.password}
							onChange={handleChange}
							className={`w-full px-4 py-2 border ${
								errors.password
									? 'border-pinhous-red'
									: 'border-[#878787]'
							} rounded-full focus:outline-none focus:border-pinhous-red`}
						/>
						<button
							type="button"
							onClick={togglePasswordVisibility}
							className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
						>
							{showPassword ? 'Hide' : 'Show'}
						</button>
						{errors.password && (
							<p className="text-red-500 text-xs mt-1">{errors.password}</p>
						)}
					</div>
					<button
						type="submit"
						className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
						disabled={loginStatus === 'loading'}
					>
						{loginStatus === 'loading' ? 'Logging in...' : 'Login'}
					</button>
				</form>

				<div className="mt-4 text-center">
					<a
						className="text-sm text-gray-600 hover:underline cursor-pointer"
						onClick={(e) => {
							e.preventDefault();
							navigate('/forgot-password');
						}}
					>
						Forgot Password?
					</a>
				</div>

				<div className="mt-6 flex items-center justify-between">
					<hr className="w-full border-[#000]" />
					<span className="px-2 text-gray-500 bg-white">or</span>
					<hr className="w-full border-[#000]" />
				</div>

				<button
					className="mt-4 w-full py-2 px-4 border border-gray-300 rounded-full flex items-center justify-center space-x-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
					onClick={(e) => {
						e.preventDefault();
						// navigate('/signup');
						alert('Coming Soon');
					}}
				>
					<svg className="w-5 h-5" viewBox="0 0 24 24">
						<path
							d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
							fill="#4285F4"
						/>
						<path
							d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
							fill="#34A853"
						/>
						<path
							d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
							fill="#FBBC05"
						/>
						<path
							d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
							fill="#EA4335"
						/>
					</svg>
					<span className="text-[#000] font-normal">Login with Google</span>
				</button>

				<div className="mt-6 text-center">
					<span className="text-black text-base font-normal font-['Quicksand'] leading-tight">
						Don't have an account?{' '}
					</span>
					<a
						className="text-[#345B84] hover:underline cursor-pointer"
						onClick={(e) => {
							e.preventDefault();
							navigate('/signup');
						}}
					>
						Sign up
					</a>
				</div>
			</div>
		</div>
	);
};

export default Login;
