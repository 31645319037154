import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeUserPassword } from '../features/user/userSlice';
import arrowRight from '../icons/arrowRight.svg'; // Ensure the path is correct

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.currentPassword) {
      newErrors.currentPassword = 'Current password is required';
    }
    if (!formData.newPassword) {
      newErrors.newPassword = 'New password is required';
    } else if (formData.newPassword.length < 8) {
      newErrors.newPassword = 'Password must be at least 8 characters';
    } else if (
      !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(formData.newPassword)
    ) {
      newErrors.newPassword =
        'Password must contain at least one number, one uppercase and lowercase letter, and one special character';
    }
    if (formData.newPassword !== formData.confirmNewPassword) {
      newErrors.confirmNewPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(changeUserPassword(formData))
        .unwrap()
        .then(() => {
          setSuccessMessage('Password changed successfully!');
          // Optionally, navigate to another page
          // navigate('/profile');
        })
        .catch((error) => {
          setErrors({ submit: error.message || 'Failed to change password' });
        });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-pinhous-red">Change Password</h2>
        {successMessage && (
          <p className="text-green-500 text-sm mb-4">{successMessage}</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
              Current Password
            </label>
            <input
              type="password"
              name="currentPassword"
              id="currentPassword"
              placeholder="Current Password"
              value={formData.currentPassword}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.currentPassword ? 'border-pinhous-red' : 'border-gray-300'
              } rounded-full focus:outline-none focus:border-pinhous-red`}
            />
            {errors.currentPassword && (
              <p className="text-pinhous-red text-xs mt-1">
                {errors.currentPassword}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              placeholder="New Password"
              value={formData.newPassword}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.newPassword ? 'border-pinhous-red' : 'border-gray-300'
              } rounded-full focus:outline-none focus:border-pinhous-red`}
            />
            {errors.newPassword && (
              <p className="text-pinhous-red text-xs mt-1">
                {errors.newPassword}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <input
              type="password"
              name="confirmNewPassword"
              id="confirmNewPassword"
              placeholder="Confirm New Password"
              value={formData.confirmNewPassword}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                errors.confirmNewPassword ? 'border-pinhous-red' : 'border-gray-300'
              } rounded-full focus:outline-none focus:border-pinhous-red`}
            />
            {errors.confirmNewPassword && (
              <p className="text-pinhous-red text-xs mt-1">
                {errors.confirmNewPassword}
              </p>
            )}
          </div>

          {errors.submit && (
            <p className="text-pinhous-red text-xs mt-1">{errors.submit}</p>
          )}

          <button
            type="submit"
            className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center justify-center"
          >
            Change Password
            <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 ml-2" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
