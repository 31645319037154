import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAllModals } from '../../features/active/activeSlice';
import { ReactComponent as Logo } from '../../icons/logo_pinhous.svg';

const ShareModal = () => {
	const dispatch = useDispatch();
	const currentURL = window.location.href;

	return (
		<div className="fixed inset-0 z-[110] flex items-center justify-center">
			{/* Backdrop */}
			<div
				className="absolute inset-0 bg-gray-900 opacity-50 backdrop-blur-sm"
				onClick={() => {
					dispatch(resetAllModals());
				}}
			/>

			{/* Modal Content */}
			<div className="relative z-10 bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
				<Logo className="mx-auto scale-150" />
				<h2 className="text-xl font-medium text-center my-4">Share This Home</h2>

				<div className="space-y-4">
					<div className="space-y-2">
						{/* <label className="text-sm font-light text-gray-600">
							Share URL
						</label> */}
						<input
							type="text"
							value={currentURL}
							readOnly
							className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg text-gray-600 font-light"
						/>
					</div>

					<div className="flex justify-end">
						<button
							onClick={() => dispatch(resetAllModals())}
							className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
						>
							Close
						</button>
						<button
							onClick={() => {
								navigator.clipboard.writeText(currentURL);
								// Optionally add a toast/notification here
							}}
							className="px-4 py-2 ml-2 text-sm font-medium text-white bg-pinhous-blue rounded-lg hover:bg-blue-700"
						>
							Copy Link
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShareModal;
