import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSingleListing } from '../../utils/api';
const DEBUG = process.env.REACT_APP_DEBUG === 'true';

export const fetchSingleActiveListing = createAsyncThunk(
	'active/fetchSingleActiveListing',
	async (mlsNumber) => {
		if (DEBUG) console.log('active/fetchSingleActiveListing parameter:', mlsNumber);
		try {
			if (DEBUG) console.log('FetchSingleActiveListing');
			// const response = await axios.get(
			// 	`http://localhost:18000/listings/listing/${id}`
			// );
			const response = await fetchSingleListing(mlsNumber);
			return { ...response.data };
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
);

const initialState = {
	value: 'Buy',
	listMapActive: 'List',
	singleListing: false,
	imageSearchModalToggle: false,
	boardModalToggle: false,
	shareModalToggle: false,
	filterPanelToggle: false,
	categorySearchModalToggle: false,
	categoryActive: '',
	singleListingData: {},
	listingPinActive: {},
	fullscreenGallery: false,
	fullscreenGalleryIndex: 0,
	status: 'idle',
	error: null,
	userPreferences: {
		sectionOrder: [
			'Price and Payment',
			'Property Details',
			'Open houses',
			'About this Home',
			'Home history',
			'Neighborhood & schools',
			'Home value'
		]
	}
};

const activeSlice = createSlice({
	name: 'active',
	initialState,
	reducers: {
		setActive: (state, action) => {
			state.value = action.payload;
		},
		clearActive: (state) => {
			state.value = '';
		},
		setListMap: (state, action) => {
			state.listMapActive = action.payload;
		},
		setSingleListing: (state, action) => {
			state.singleListing = action.payload;
		},
		toggleImageSearchModal: (state) => {
			state.imageSearchModalToggle = !state.imageSearchModalToggle;
		},
		toggleBoardModal: (state) => {
			state.boardModalToggle = !state.boardModalToggle;
		},
		toggleCategorySearchModal: (state) => {
			state.categorySearchModalToggle = !state.categorySearchModalToggle;
		},
		toggleShareModal: (state) => {
			state.shareModalToggle = !state.shareModalToggle;
		},
		toggleFilterPanel: (state) => {
			state.filterPanelToggle = !state.filterPanelToggle;
		},
		setCategoryActive: (state, action) => {
			state.categoryActive = action.payload;
		},
		resetCategoryActive: (state) => {
			state.categoryActive = '';
		},
		setListingPinActive: (state, action) => {
			state.listingPinActive = action.payload;
		},
		clearListingPinActive: (state) => {
			state.listingPinActive = {};
		},
		setFullscreenGallery: (state, action) => {
			state.fullscreenGallery = action.payload;
		},
		setFullscreenGalleryIndex: (state, action) => {
			state.fullscreenGalleryIndex = action.payload;
		},
		resetAllModals: (state) => {
			state.imageSearchModalToggle = false;
			state.boardModalToggle = false;
			state.categorySearchModalToggle = false;
			state.categoryActive = '';
			state.shareModalToggle = false;
		},
		resetActive: () => initialState
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSingleActiveListing.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchSingleActiveListing.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.singleListingData = action.payload;
			})
			.addCase(fetchSingleActiveListing.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	}
});

export const {
	setActive,
	clearActive,
	setListMap,
	setSingleListing,
	toggleImageSearchModal,
	toggleBoardModal,
	toggleCategorySearchModal,
	toggleShareModal,
	setCategoryActive,
	resetCategoryActive,
	setListingPinActive,
	clearListingPinActive,
	resetAllModals,
	resetActive,
	setFullscreenGallery,
	setFullscreenGalleryIndex,
	toggleFilterPanel
} = activeSlice.actions;
export const selectListMapActive = (state) => state.active.listMapActive;
export const selectSingleListing = (state) => state.active.singleListing;
export const selectSingleListingData = (state) => state.active.singleListingData;
export const selectStatus = (state) => state.active.status;
export const selectImageSearchModalToggle = (state) =>
	state.active.imageSearchModalToggle;
export const selectBoardModalToggle = (state) => state.active.boardModalToggle;
export const selectListingPinActive = (state) => state.active.listingPinActive;
export const selectCategorySearchModalToggle = (state) =>
	state.active.categorySearchModalToggle;
export const selectCategoryActive = (state) => state.active.categoryActive;
export const selectFullscreenGallery = (state) => state.active.fullscreenGallery;
export const selectFullscreenGalleryIndex = (state) =>
	state.active.fullscreenGalleryIndex;
export const selectShareModalToggle = (state) => state.active.shareModalToggle;
export const selectFilterPanelToggle = (state) => state.active.filterPanelToggle;
export default activeSlice.reducer;
