import React from 'react';
import FilterButton from './FilterButton';
import ApplyButton from './ApplyButton';
import PriceFilterButton from './PriceFilterButton';
import PropertyTypeButton from './PropertyTypeButton';
import BedsAndBathsButton from './BedsAndBathsButton';
import SquareFootageButton from './SquareFootageButton';
import SortButton from './SortButton';
import MoreOptions from './MoreOptions';

const FilterPanel = () => {
	return (
		<>
			<div className="w-full flex justify-between">
				<div className="flex flex-nowrap pt-4 gap-x-2">
					<FilterButton filterType={'Sale'} />
					{/* <FilterButton filterType={'Price'} /> */}
					<PriceFilterButton />
					<PropertyTypeButton />
					<BedsAndBathsButton />
					<SquareFootageButton />
					<MoreOptions />

					<ApplyButton />
				</div>
				<div className="flex flex-nowrap pt-4 gap-x-2">
					<SortButton />
				</div>
			</div>
		</>
	);
};

export default FilterPanel;
