import { useState } from 'react';
import { BsFacebook, BsTwitterX, BsLinkedin, BsInstagram } from 'react-icons/bs';

const Footer = () => {
	const [email, setEmail] = useState('');

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('Email submitted:', email);
		setEmail('');
	};

	return (
		<footer className="w-full bg-pinhous-black text-white mt-auto">
			<div className="container mx-auto px-4 py-8">
				<div className="flex flex-col space-y-8">
					<div className="flex space-x-4 ml-4">
						{[
							{ icon: BsFacebook, href: "https://www.facebook.com/profile.php?id=61559130153530" },
							{ icon: BsTwitterX, href: "#" },
							{ icon: BsLinkedin, href: "https://www.linkedin.com/company/pinhous/" },
							{ icon: BsInstagram, href: "https://www.instagram.com/pinhous_homes/" }
						].map((item, index) => (
							<a key={index} href={item.href} className="bg-white p-2 rounded-full">
								<item.icon className="text-pinhous-black text-xl" />
							</a>
						))}
					</div>

					<div className="flex flex-wrap gap-6 ml-4">
						{["About Us", "Careers", "Accessibility", "Feedback", "Privacy", "Terms"].map((item, index) => (
							<a key={index} href={`/${item.toLowerCase().replace(' ', '-')}`} className="hover:underline">
								{item}
							</a>
						))}
					</div>

					<div className="ml-4">
						<p className="text-sm mb-2">Join app waitlist</p>
						<form onSubmit={handleSubmit} className="flex">
							<input
								type="email"
								placeholder="Your email"
								value={email}
								onChange={handleChange}
								required
								className="px-4 py-2 rounded-l-full w-64 text-pinhous-black"
							/>
							<button type="submit" className="bg-pinhous-red text-white px-6 py-2 rounded-r-full">
								Join Waitlist
							</button>
						</form>
					</div>

					<div className="ml-4 text-sm">
						<p>&copy; 2024 <span className="underline">Pinhous Inc.</span></p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
