import React from 'react';
import { X } from 'lucide-react';
import DanielJulian from '../../images/Agents/DanielJulien.png';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';

const AgentCard2 = () => {
	const listingData = useSelector(selectSingleListingData);
	if (!listingData || !listingData.address) {
		return <div></div>;
	}
	return (
		<div className="p-8 bg-white rounded-3xl  flex flex-col items-center">
			{/* Agent Image */}
			<div className="w-32 h-32 rounded-full mb-6">
				<img
					src={DanielJulian}
					alt="Agent profile"
					className="w-full h-full object-cover"
				/>
				{/* <div class="w-full h-full rounded-full bg-blue-500"></div> */}
			</div>

			{/* Agent Name */}
			<h2 className="text-2xl font-bold text-gray-900 mb-2">Daniel Julien</h2>

			{/* Agent Title */}
			<p className="text-gray-500 mb-8">Your Pinhous Agent</p>

			{/* Property Interest Section */}
			<div className="w-full bg-gray-50 rounded-lg p-4 mb-6 relative">
				<p className="text-gray-700">
					I'd like to buy/sell something similar to
					{/* #{listingData.mlsNumber}{' '} */}
					{' ' +
						listingData.address.streetNumber +
						' ' +
						listingData.address.streetName}
					, {listingData.address.city}
				</p>
				<button className="absolute top-4 right-4 text-gray-400 hover:text-gray-600">
					<X size={15} onClick={() => alert('Coming Soon')} />
				</button>
			</div>

			{/* Contact Button */}
			<button
				className="w-full bg-red-500 text-white py-4 px-6 rounded-full text-lg font-medium hover:bg-red-600 transition-colors mb-6"
				onClick={() => alert('Coming Soon')}
			>
				Contact Agent
			</button>

			{/* Disclaimer Text */}
			<p className="text-xs text-gray-600 text-center">
				By proceeding, you consent to receive calls and texts at the number you
				provided, including marketing by autodialer and prerecorded and artificial
				voice, and email, from pinhous.com and{' '}
				<button className="text-gray-900 underline">others</button> about your
				inquiry and other home-related matters, but not as a condition of any
				purchase.
			</p>
		</div>
	);
};

export default AgentCard2;
