import React from 'react';
import ReactSlider from 'react-slider';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateDraftSearchParams,
	applySearchParams,
	resetSearchParams,
	selectDraftParams
} from '../features/searchParams/searchParamsSlice';
import {
	selectFilterPanelToggle,
	toggleFilterPanel
} from '../features/active/activeSlice';
import { ReactComponent as TownhouseIcon } from '../icons/filters-townhouse.svg';
import { ReactComponent as SemiDetachedIcon } from '../icons/filters-semi-detached.svg';
import { ReactComponent as DetachedIcon } from '../icons/filters-detached.svg';
import { ReactComponent as ApartmentIcon } from '../icons/filters-apartment.svg';
import { ReactComponent as OtherIcon } from '../icons/filters-other.svg';
import { ReactComponent as PoolIcon } from '../icons/filters-pool.svg';
import { ReactComponent as FireplaceIcon } from '../icons/filters-fireplace.svg';
import { ReactComponent as AirConditioningIcon } from '../icons/filters-air-conditioning.svg';

const Filter = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);
	const filterPanel = useSelector(selectFilterPanelToggle);

	const handleInputChange = (name, value) => {
		dispatch(updateDraftSearchParams({ [name]: value }));
	};

	const handleReset = () => {
		dispatch(resetSearchParams());
	};

	const handleApply = () => {
		dispatch(applySearchParams());
		onClose();
	};

	const formatPrice = (price) => {
		if (price >= 1000000) {
			return `$${(price / 1000000).toFixed(1)}M`;
		} else if (price >= 1000) {
			return `$${(price / 1000).toFixed(0)}K`;
		} else {
			return `$${price}`;
		}
	};

	const handleOpenHouseChange = (option) => {
		let newOpenHouse = { ...(draftParams.openHouse || {}) };
		newOpenHouse[option] = !newOpenHouse[option];

		// Calculate min and max dates based on selected options
		let minDate = null;
		let maxDate = null;

		if (newOpenHouse['Today']) minDate = calculateDate('Today');
		if (newOpenHouse['Tomorrow']) maxDate = calculateDate('Tomorrow');
		if (newOpenHouse['Within a week']) maxDate = calculateDate('Within a week');

		if (newOpenHouse['Any day'] || Object.values(newOpenHouse).every((v) => !v)) {
			minDate = null;
			maxDate = null;
		}

		handleInputChange('openHouse', newOpenHouse);
		handleInputChange('minOpenHouseDate', minDate);
		handleInputChange('maxOpenHouseDate', maxDate);
	};

	const isOpenHouseOptionSelected = (option) => {
		const minDate = draftParams.minOpenHouseDate;
		const maxDate = draftParams.maxOpenHouseDate;

		if (option === 'Any day') {
			return !minDate && !maxDate;
		}

		return minDate === calculateDate('Today') && maxDate === calculateDate(option);
	};

	const calculateDate = (option) => {
		const today = new Date();
		switch (option) {
			case 'Today':
				return today.toISOString().split('T')[0];
			case 'Tomorrow':
				const tomorrow = new Date(today);
				tomorrow.setDate(tomorrow.getDate() + 1);
				return tomorrow.toISOString().split('T')[0];
			case 'Within a week':
				const nextWeek = new Date(today);
				nextWeek.setDate(nextWeek.getDate() + 7);
				return nextWeek.toISOString().split('T')[0];
			default:
				return null;
		}
	};

	const convertOption = (option) =>
		option === 'Any' ? 0 : Number(option.replace('+', '')); // option === 'Any' ? 0 : parseInt(option.replace('+', ''), 10);

	const features = [
		{
			icon: PoolIcon,
			label: 'Swimming Pool',
			repliers: 'swimmingPool',
			repliersValue: 'Inground'
		}
		// {
		// 	icon: FireplaceIcon,
		// 	label: 'Fireplace',
		// 	repliers: 'fireplace',
		// 	repliersValue: 'Yes'
		// },
		// {
		// 	icon: AirConditioningIcon,
		// 	label: 'Air Conditioning',
		// 	repliers: 'airConditioning',
		// 	repliersValue: 'Central'
		// }
	];

	return (
		<>
			<div
				className={`fixed top-0 left-0 h-full w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-[120] ${
					filterPanel ? 'translate-x-0' : '-translate-x-full'
				}`}
			>
				<div className="p-6 space-y-6 h-full overflow-y-auto">
					{/* Price Range */}
					<div>
						<h3 className="text-lg font-semibold">Price Range</h3>
						{/* TODO: get max prices for the area */}
						<ReactSlider
							className="w-full h-1 mt-4 bg-gray-200"
							thumbClassName="w-5 h-5 bg-white border-2 border-pinhous-red rounded-full outline-none top-[-8px]"
							trackClassName="h-1"
							min={0}
							max={10000000}
							step={10000}
							value={[
								draftParams.minPrice || 250000,
								draftParams.maxPrice || 1000000
							]}
							onChange={([min, max]) => {
								handleInputChange('minPrice', min);
								handleInputChange('maxPrice', max);
							}}
							pearling
							minDistance={100000}
							renderTrack={(props, state) => (
								<div
									{...props}
									className={`h-1 ${
										state.index === 1
											? 'bg-pinhous-red'
											: 'bg-gray-200'
									}`}
								/>
							)}
						/>
						<div className="flex justify-between mt-2">
							<span>{formatPrice(draftParams.minPrice || 250000)}</span>
							<span>{formatPrice(draftParams.maxPrice || 1000000)}</span>
						</div>
					</div>

					{/* Property Types */}
					<div>
						<h3 className="text-lg font-semibold">Property Types</h3>
						<div className="grid grid-cols-3 gap-4 mt-2">
							{[
								{ icon: TownhouseIcon, label: 'Townhouse' },
								{ icon: SemiDetachedIcon, label: 'Semi-Detached' },
								{ icon: DetachedIcon, label: 'Detached' },
								{ icon: ApartmentIcon, label: 'Condo Apt' },
								{ icon: OtherIcon, label: 'Other' }
							].map(({ icon: Icon, label }) => (
								<button
									key={label}
									className={`flex flex-col items-center p-2 border rounded ${
										draftParams.propertyType &&
										draftParams.propertyType.includes(label)
											? 'border-pinhous-red text-pinhous-red'
											: 'border-gray-300 text-gray-700'
									}`}
									onClick={() => {
										const updatedTypes = draftParams.propertyType
											? draftParams.propertyType.includes(label)
												? draftParams.propertyType.filter(
														(t) => t !== label
												  )
												: [...draftParams.propertyType, label]
											: [label];
										handleInputChange('propertyType', updatedTypes);
									}}
								>
									<Icon className="w-6 h-6 mb-1" />
									<span className="text-xs">{label}</span>
								</button>
							))}
						</div>
					</div>

					{/* Open House */}
					{/* <div>
						<h3 className="text-lg font-semibold">Open House</h3>
						<div className="grid grid-cols-2 gap-2 mt-2">
							{['Today', 'Tomorrow', 'Within a week', 'Any day'].map(
								(day) => (
									<label
										key={day}
										className="flex items-center space-x-2"
									>
										<input
											type="checkbox"
											checked={
												draftParams.openHouse &&
												draftParams.openHouse[
													day.toLowerCase().replace(' ', '')
												]
											}
											onChange={() => {
												const updatedOpenHouse = {
													...draftParams.openHouse,
													[day.toLowerCase().replace(' ', '')]:
														!draftParams.openHouse?.[
															day
																.toLowerCase()
																.replace(' ', '')
														]
												};
												handleInputChange(
													'openHouse',
													updatedOpenHouse
												);
											}}
											className="accent-pinhous-red"
										/>
										<span>{day}</span>
									</label>
								)
							)}
						</div>
					</div> */}
					<div>
						<h3 className="text-lg font-semibold">Open House</h3>
						<div className="grid grid-cols-2 gap-2 mt-2">
							{['Today', 'Tomorrow', 'Within a week', 'Any day'].map(
								(option) => (
									<label
										key={option}
										className="flex items-center space-x-2"
									>
										<input
											type="checkbox"
											checked={
												draftParams.openHouse &&
												draftParams.openHouse[option]
											}
											onChange={() => handleOpenHouseChange(option)}
											className="accent-pinhous-red"
										/>
										<span>{option}</span>
									</label>
								)
							)}
						</div>
					</div>

					{/* Interior */}
					<div>
						<h3 className="text-lg font-semibold">Interior</h3>
						<div className="space-y-2 mt-2">
							<div>
								<span className="font-medium">Beds</span>
								<div className="flex space-x-2 mt-1">
									{['Any', '1+', '2+', '3+', '4+'].map((option) => (
										<button
											key={option}
											className={`px-3 py-1 rounded ${
												draftParams.minBeds ===
												convertOption(option)
													? 'bg-pinhous-red text-white'
													: 'bg-gray-200'
											}`}
											onClick={() =>
												handleInputChange(
													'minBeds',
													convertOption(option)
												)
											}
										>
											{option}
										</button>
									))}
								</div>
							</div>
							<div>
								<span className="font-medium">Baths</span>
								<div className="flex space-x-2 mt-1">
									{['Any', '1+', '2+', '3+', '4+'].map((option) => (
										<button
											key={option + '+'}
											className={`px-3 py-1 rounded ${
												draftParams.minBaths ===
												convertOption(option)
													? 'bg-pinhous-red text-white'
													: 'bg-gray-200'
											}`}
											onClick={() =>
												handleInputChange(
													'minBaths',
													convertOption(option)
												)
											}
										>
											{option}
										</button>
									))}
								</div>
							</div>
						</div>
					</div>

					{/* Square Footage */}
					<div>
						<h3 className="text-lg font-semibold">Square Footage</h3>
						<ReactSlider
							className="w-full h-1 mt-4 bg-gray-200"
							thumbClassName="w-5 h-5 bg-white border-2 border-pinhous-red rounded-full outline-none top-[-8px]"
							trackClassName="h-1"
							min={100}
							max={2000}
							step={50}
							value={[
								draftParams.minSqft || 600,
								draftParams.maxSqft || 1200
							]}
							onChange={([min, max]) => {
								handleInputChange('minSqft', min);
								handleInputChange('maxSqft', max);
							}}
							pearling
							minDistance={100}
							renderTrack={(props, state) => (
								<div
									{...props}
									className={`h-1 ${
										state.index === 1
											? 'bg-pinhous-red'
											: 'bg-gray-200'
									}`}
								/>
							)}
						/>
						<div className="flex justify-between mt-2">
							<span>{draftParams.minSqft || 600} sqft</span>
							<span>{draftParams.maxSqft || 1200} sqft</span>
						</div>
					</div>

					{/* Basement */}
					<div>
						<h3 className="text-lg font-semibold">Basement</h3>
						<div className="flex space-x-2 mt-2">
							{['Finished', 'Unfinished', 'Any'].map((option) => (
								<button
									key={option}
									className={`px-3 py-1 rounded ${
										(option === 'Any' && !draftParams.basement) ||
										draftParams.basement === option
											? 'bg-pinhous-red text-white'
											: 'bg-gray-200'
									}`}
									onClick={() =>
										handleInputChange(
											'basement',
											option === 'Any' ? null : option
										)
									}
								>
									{option}
								</button>
							))}
						</div>
					</div>

					{/* Exterior */}
					<div>
						<h3 className="text-lg font-semibold">Exterior</h3>
						<div className="mt-2">
							<span className="font-medium">Total parking spots</span>
							<div className="flex space-x-2 mt-2">
								{['Any', '1+', '2+', '3+', '4+'].map((option) => (
									<button
										key={option}
										className={`px-3 py-1 rounded ${
											draftParams.minParkingSpaces ===
											(option === 'Any' ? 0 : parseInt(option))
												? 'bg-pinhous-red text-white'
												: 'bg-gray-200'
										}`}
										onClick={() =>
											handleInputChange(
												'minParkingSpaces',
												option === 'Any' ? 0 : parseInt(option)
											)
										}
									>
										{option}
									</button>
								))}
							</div>
						</div>
						<div className="flex items-center space-x-2 mt-2">
							<input
								type="checkbox"
								checked={
									draftParams.minGarageSpaces !== undefined &&
									draftParams.minGarageSpaces > 0
								}
								onChange={(e) =>
									handleInputChange(
										'minGarageSpaces',
										e.target.checked ? 1 : 0
									)
								}
								className="accent-pinhous-red"
							/>
							<span>Must include a garage</span>
						</div>
					</div>

					{/* Lot Dimensions  TODO dimension filtering from repliers*/}
					{false && (
						<div>
							<h3 className="text-lg font-semibold">Lot Dimensions</h3>
							<div className="space-y-2 mt-2">
								<div>
									<span className="font-medium">
										Lot width (in feet)
									</span>
									<select
										value={draftParams.lotWidth}
										onChange={(e) =>
											handleInputChange('lotWidth', e.target.value)
										}
										className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
									>
										<option value="Any width">Any width</option>
										<option value="custom">Custom</option>
										{/* Add other width options */}
									</select>
									{draftParams.lotWidth === 'custom' && (
										<input
											type="number"
											value={draftParams.customLotWidth}
											onChange={(e) =>
												handleInputChange(
													'customLotWidth',
													e.target.value
												)
											}
											placeholder="Enter custom width"
											className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
										/>
									)}
								</div>
								<div>
									<span className="font-medium">
										Lot Length (in feet)
									</span>
									<select
										value={draftParams.lotLength}
										onChange={(e) =>
											handleInputChange('lotLength', e.target.value)
										}
										className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
									>
										<option value="Any length">Any length</option>
										<option value="custom">Custom</option>
										{/* Add other length options here */}
									</select>
									{draftParams.lotLength === 'custom' && (
										<input
											type="number"
											value={draftParams.customLotLength}
											onChange={(e) =>
												handleInputChange(
													'customLotLength',
													e.target.value
												)
											}
											placeholder="Enter custom length"
											className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
										/>
									)}
								</div>
							</div>
						</div>
					)}
					{/* swimmingPool */}

					{/*TODO: checking for condos Amenities */}
					{false && (
						<div>
							<h3 className="text-lg font-semibold">Features</h3>
							<div className="grid grid-cols-3 gap-4 mt-2">
								{[
									{ icon: PoolIcon, label: 'Swimming Pool' },
									{ icon: FireplaceIcon, label: 'Fireplace' },
									{
										icon: AirConditioningIcon,
										label: 'Air Conditioning'
									}
								].map(({ icon: Icon, label }) => (
									<button
										key={label}
										className={`flex flex-col items-center p-2 border rounded ${
											draftParams.amenities &&
											draftParams.amenities.includes(label)
												? 'border-pinhous-red text-pinhous-red'
												: 'border-gray-300 text-gray-700'
										}`}
										onClick={() => {
											const updatedAmenities = draftParams.amenities
												? draftParams.amenities.includes(label)
													? draftParams.amenities.filter(
															(a) => a !== label
													  )
													: [...draftParams.amenities, label]
												: [label];
											handleInputChange(
												'amenities',
												updatedAmenities
											);
										}}
									>
										<Icon className="w-6 h-6 mb-1" />
										<span className="text-xs">{label}</span>
									</button>
								))}
							</div>
						</div>
					)}
					<div>
						<h3 className="text-lg font-semibold">Features</h3>
						<div className="grid grid-cols-3 gap-4 mt-2">
							{features.map(
								({ icon: Icon, label, repliers, repliersValue }) => (
									<button
										key={label}
										className={`flex flex-col items-center p-2 border rounded ${
											draftParams[repliers] === repliersValue
												? 'border-pinhous-red text-pinhous-red'
												: 'border-gray-300 text-gray-700'
										}`}
										onClick={() => {
											const isCurrentlySelected =
												Object.keys(draftParams).includes(
													repliers
												) &&
												draftParams[repliers] !== null &&
												draftParams[repliers] !== undefined;

											if (isCurrentlySelected) {
												handleInputChange(repliers, null);
											} else {
												handleInputChange(
													repliers,
													repliersValue
												);
											}
										}}
									>
										<Icon className="w-6 h-6 mb-1" />
										<span className="text-xs">{label}</span>
									</button>
								)
							)}
						</div>
					</div>

					{/* Additional Costs */}
					<div>
						<h3 className="text-lg font-semibold">Additional Costs</h3>
						<div className="space-y-2 mt-2">
							<div>
								<span className="font-medium">Maintenance Fees</span>
								<select
									value={draftParams.maintenanceFees}
									onChange={(e) =>
										handleInputChange(
											'maintenanceFees',
											e.target.value
										)
									}
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
								>
									<option value="No limit">No limit</option>
									<option value="custom">Custom</option>
									{/* Add other{/* Add other maintenance fee options here */}
								</select>
								{draftParams.maintenanceFees === 'custom' && (
									<input
										type="number"
										value={draftParams.maxMaintenanceFee}
										onChange={(e) =>
											handleInputChange(
												'maxMaintenanceFee',
												e.target.value
											)
										}
										placeholder="Enter custom maintenance fee"
										className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
									/>
								)}
							</div>
							{false && (
								<div>
									<span className="font-medium">Property Tax</span>
									<select
										value={draftParams.propertyTax}
										onChange={(e) =>
											handleInputChange(
												'propertyTax',
												e.target.value
											)
										}
										className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
									>
										<option value="No limit">No limit</option>
										<option value="custom">Custom</option>
										{/* Add other property tax options here */}
									</select>
									{draftParams.propertyTax === 'custom' && (
										<input
											type="number"
											value={draftParams.maxTaxes}
											onChange={(e) =>
												handleInputChange(
													'maxTaxes',
													e.target.value
												)
											}
											placeholder="Enter custom property tax"
											className="block w-full mt-1 border-gray-300 rounded-md shadow-sm"
										/>
									)}
								</div>
							)}
						</div>
					</div>

					{/* Reset and Apply Buttons */}
					<div className="flex justify-between mt-6">
						<button
							className="px-6 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
							onClick={handleReset}
						>
							Reset
						</button>
						<button
							className="px-6 py-2 bg-pinhous-red text-white rounded hover:bg-red-600"
							onClick={handleApply}
						>
							Apply
						</button>
					</div>
				</div>
			</div>
			<div
				className={`fixed inset-0 bg-black transition-opacity duration-300 z-[110] ${
					filterPanel
						? 'opacity-50 pointer-events-auto'
						: 'opacity-0 pointer-events-none'
				}`}
				onClick={() => dispatch(toggleFilterPanel())}
			/>
		</>
	);
};

export default Filter;
