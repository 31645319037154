const environments = {
  development: {
    baseUrl: process.env.NODE_ENV === 'development' 
      ? 'http://localhost:18000'
      : 'http://backend.development.pinhous-webapp.local:18000',
    apiTimeout: 10000,
    allowedOrigins: ['http://localhost:3000']
  },
  production: {
    baseUrl: '//api.alpha.pinhous.com',
    apiTimeout: 30000,
    allowedOrigins: [
      'https://www.alpha.pinhous.com',
      'https://alpha.pinhous.com'
    ]
  }
};

export const getEnvironmentConfig = () => {
  return environments[process.env.NODE_ENV || 'development'];
}; 