import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	fetchBoards,
	createBoard,
	updateBoard,
	deleteBoard,
	fetchBoardsWithListings
} from '../../utils/api';

export const fetchBoardsAsync = createAsyncThunk(
	'boards/fetchBoards',
	async (_, { getState, rejectWithValue }) => {
		try {
			const accessToken = getState().user.authData.AccessToken;
			return await fetchBoards(accessToken);
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

export const fetchBoardsWithListingsAsync = createAsyncThunk(
	'boards/fetchBoardsWithListings',
	async (_, { getState, rejectWithValue }) => {
		try {
			const accessToken = getState().user.authData.AccessToken;
			return await fetchBoardsWithListings(accessToken);
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

// Async thunk for creating a board
export const createBoardAsync = createAsyncThunk(
	'boards/createBoard',
	async (boardData, { getState, rejectWithValue }) => {
		try {
			const accessToken = getState().user.authData.AccessToken;
			return await createBoard(accessToken, boardData);
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

// Async thunk for updating a board
export const updateBoardAsync = createAsyncThunk(
	'boards/updateBoard',
	async ({ boardId, boardData }, { getState, rejectWithValue }) => {
		try {
			const accessToken = getState().user.authData.AccessToken;
			return await updateBoard(accessToken, boardId, boardData);
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

// Async thunk for deleting a board
export const deleteBoardAsync = createAsyncThunk(
	'boards/deleteBoard',
	async (boardId, { getState, rejectWithValue }) => {
		try {
			const accessToken = getState().user.authData.AccessToken;
			await deleteBoard(accessToken, boardId);
			return boardId;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

const boardsSlice = createSlice({
	name: 'boards',
	initialState: {
		boards: [],
		boardListings: {},
		savedListingMlsNumbers: [],
		status: 'idle',
		error: null
	},
	reducers: {
		clearBoards: (state) => {
			state.boards = [];
			state.status = 'idle';
			state.error = null;
		},
		updateSavedListingMlsNumbers: (state) => {
			state.savedListingMlsNumbers = Object.values(state.boardListings)
				.flat()
				.map((listing) => listing.mlsNumber);
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBoardsAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchBoardsAsync.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.boards = action.payload;
			})
			.addCase(fetchBoardsAsync.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(createBoardAsync.fulfilled, (state, action) => {
				state.boards.push(action.payload);
			})
			.addCase(updateBoardAsync.fulfilled, (state, action) => {
				const index = state.boards.findIndex(
					(board) => board.id === action.payload.id
				);
				if (index !== -1) {
					state.boards[index] = action.payload;
				}
			})
			.addCase(deleteBoardAsync.fulfilled, (state, action) => {
				state.boards = state.boards.filter(
					(board) => board.id !== action.payload
				);
			})
			.addCase(fetchBoardsWithListingsAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchBoardsWithListingsAsync.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.boards = action.payload.map((board) => {
					const { savedListings, ...boardData } = board;
					return boardData;
				});
				state.boardListings = action.payload.reduce((acc, board) => {
					acc[board.id] = board.savedListings;
					return acc;
				}, {});
				console.log(state.boards);
				// Extract all mlsNumbers into a single array
				state.savedListingMlsNumbers = action.payload.reduce((acc, board) => {
					const boardMlsNumbers = board.SavedListings.map(
						(listing) => listing.mlsNumber
					);
					return [...acc, ...boardMlsNumbers];
				}, []);
				console.log(state.savedListingMlsNumbers);
			})
			.addCase(fetchBoardsWithListingsAsync.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			});
	}
});

export const { clearBoards, updateSavedListingMlsNumbers } = boardsSlice.actions;
export default boardsSlice.reducer;

// Selectors
export const selectAllBoards = (state) => state.boards.boards;
export const selectBoardsStatus = (state) => state.boards.status;
export const selectBoardsError = (state) => state.boards.error;
export const selectAllBoardsWithListings = (state) => ({
	boards: state.boards.boards,
	boardListings: state.boards.boardListings
});
export const selectBoardListings = (state, boardId) =>
	state.boards.boardListings[boardId] || [];
export const selectSavedListingMlsNumbers = (state) =>
	state.boards.savedListingMlsNumbers;
