import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateDraftSearchParams,
	applySearchParams,
	selectDraftParams
} from '../../features/searchParams/searchParamsSlice';
import { ReactComponent as TownhouseIcon } from '../../icons/filters-townhouse.svg';
import { ReactComponent as SemiDetachedIcon } from '../../icons/filters-semi-detached.svg';
import { ReactComponent as DetachedIcon } from '../../icons/filters-detached.svg';
import { ReactComponent as ApartmentIcon } from '../../icons/filters-apartment.svg';
import { ReactComponent as OtherIcon } from '../../icons/filters-other.svg';

const PropertyTypeButton = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);

	const propertyTypes = [
		{ icon: TownhouseIcon, label: 'Townhouse' },
		{ icon: SemiDetachedIcon, label: 'Semi Detached' },
		{ icon: DetachedIcon, label: 'Detached' },
		{ icon: ApartmentIcon, label: 'Apartment' },
		{ icon: OtherIcon, label: 'Other' }
	];

	const handleSelection = (type) => {
		const currentTypes = draftParams.propertyType || [];
		const updatedTypes = currentTypes.includes(type)
			? currentTypes.filter((t) => t !== type)
			: [...currentTypes, type];

		dispatch(updateDraftSearchParams({ propertyType: updatedTypes }));
	};

	const handleReset = () => {
		dispatch(updateDraftSearchParams({ propertyType: [] }));
	};

	const handleApply = () => {
		dispatch(applySearchParams());
		setIsOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	const getDisplayText = () => {
		const count = draftParams.propertyType?.length || 0;
		if (count === 0) return 'Property Type';
		if (count === 1) return draftParams.propertyType[0];
		return `${count} Types`;
	};

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className={`
          flex items-center 
          justify-between 
          px-3 py-2 
          border border-gray-300 
          rounded-md 
          shadow-sm 
          ${
				isOpen
					? 'bg-black text-white hover:bg-gray-900'
					: 'bg-white text-black hover:bg-gray-50'
			}
        `}
			>
				<span>{getDisplayText()}</span>
				<svg
					className={`w-5 h-5 ml-2 transition-transform ${
						isOpen ? 'rotate-180' : ''
					}`}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</button>

			{isOpen && (
				<div className="absolute z-[100] w-[400px] mt-1 p-4 bg-white border border-gray-300 rounded-md shadow-lg">
					<div className="flex justify-between items-center mb-4">
						<h3 className="text-lg font-semibold">Property Type</h3>
						<div className="flex gap-2">
							<button
								onClick={handleReset}
								className="px-4 py-1 bg-gray-200 rounded-full text-sm hover:bg-gray-300"
							>
								Reset
							</button>
							<button
								onClick={handleApply}
								className="px-4 py-1 bg-black text-white rounded-full text-sm hover:bg-gray-800"
							>
								Apply
							</button>
						</div>
					</div>

					<div className="grid grid-cols-3 gap-4">
						{propertyTypes.map(({ icon: Icon, label }) => (
							<button
								key={label}
								onClick={() => handleSelection(label)}
								className={`
                  flex flex-col items-center justify-center p-4
                  border rounded-lg transition-all
                  ${
						draftParams.propertyType?.includes(label)
							? 'border-red-500 text-red-500'
							: 'border-gray-300 text-gray-700 hover:border-gray-400'
					}
                `}
							>
								<Icon className="w-8 h-8 mb-2" />
								<span className="text-sm font-medium">{label}</span>
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default PropertyTypeButton;
