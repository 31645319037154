import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activeMarkerId: null,
	center: {
		lat: 43.6788527,
		lng: -79.4067778
	},
	zoom: 12,
	bounds: {
		ne: { lat: 0, lng: 0 },
		sw: { lat: 0, lng: 0 }
	},
	hoveredListingId: null // Useful for syncing hover states between list and map
};

const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setActiveMarker: (state, action) => {
			state.activeMarkerId = action.payload;
		},
		clearActiveMarker: (state) => {
			state.activeMarkerId = null;
		},
		setMapCenter: (state, action) => {
			state.center = action.payload;
		},
		setMapZoom: (state, action) => {
			state.zoom = action.payload;
		},
		setMapBounds: (state, action) => {
			state.bounds = action.payload;
		},
		setHoveredListing: (state, action) => {
			state.hoveredListingId = action.payload;
		},
		resetMapState: () => initialState
	}
});

export const {
	setActiveMarker,
	clearActiveMarker,
	setMapCenter,
	setMapZoom,
	setMapBounds,
	setHoveredListing,
	resetMapState
} = mapSlice.actions;

// Selectors
export const selectActiveMarkerId = (state) => state.map.activeMarkerId;
export const selectMapCenter = (state) => state.map.center;
export const selectMapZoom = (state) => state.map.zoom;
export const selectMapBounds = (state) => state.map.bounds;
export const selectHoveredListingId = (state) => state.map.hoveredListingId;

export default mapSlice.reducer;
