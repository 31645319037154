import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	login,
	getUserInfo,
	refreshToken,
	forgotPasswordVerficationCode,
	forgotPasswordChangePassword,
	resendVerficationCode,
	verfifyEmail,
	updateProfile,
	deleteAccount
} from '../../utils/api';
import { fetchSavedListingsAsync } from '../savedListings/savedListingsSlice';

// Async thunk for login
export const loginUser = createAsyncThunk('user/login', async (credentials, thunkAPI) => {
	try {
		const authData = await login(credentials);
		const userData = await getUserInfo(authData.AuthenticationResult.AccessToken);

		// Fetch saved listings after successful login
		// console.log(authData.AuthenticationResult.AccessToken);
		thunkAPI.dispatch(
			fetchSavedListingsAsync(authData.AuthenticationResult.AccessToken)
		);

		return { userData, authData };
	} catch (error) {
		return thunkAPI.rejectWithValue(
			error.response?.data?.error || error.message || 'An unknown error occurred'
		);
	}
});

// Async thunk for token refresh
export const refreshUserToken = createAsyncThunk(
	'user/refreshToken',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { refreshToken } = getState().user.authData;
			const newAuthData = await refreshToken(refreshToken);
			return newAuthData;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);
// Async thunk for getVerfificationCode
export const changeUserPasswordVerification = createAsyncThunk(
	'user/changeUserPasswordVerification',
	async (email, { getState, rejectWithValue }) => {
		try {
			// const { accessToken } = getState().user.authData;
			// const response = await changePassword(accessToken, previousPassword, newPassword);
			// return response;
			console.log(email);
			const response = await forgotPasswordVerficationCode(email);
			return email;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// Async thunk for getVerfificationCode
export const resendVerification = createAsyncThunk(
	'user/resendVerification',
	async (email, { getState, rejectWithValue }) => {
		try {
			// const { accessToken } = getState().user.authData;
			// const response = await changePassword(accessToken, previousPassword, newPassword);
			// return response;
			console.log(email);
			const response = await resendVerficationCode(email);
			return email;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// // Async thunk for getVerfificationCode
// export const verfifyEmailSignUp = createAsyncThunk(
// 	'user/verfifyEmail',
// 	async (credentials, { getState, rejectWithValue }) => {
// 		try {
// 			// const { accessToken } = getState().user.authData;
// 			// const response = await changePassword(accessToken, previousPassword, newPassword);
// 			// return response;
// 			console.log(credentials);
// 			const response = await verfifyEmail(credentials);
// 			return response;
// 		} catch (error) {
// 			return rejectWithValue(error.message);
// 		}
// 	}
// );

export const verfifyEmailSignUp = createAsyncThunk(
	'user/verfifyEmail',
	async (credentials, { dispatch, rejectWithValue }) => {
		try {
			const response = await verfifyEmail(credentials);

			// If verification is successful, log the user in
			// if (response.success) {
			// 	const authData = await login(credentials);
			// 	const userData = await getUserInfo(
			// 		authData.AuthenticationResult.AccessToken
			// 	);

			// 	// Fetch saved listings after successful login
			// 	dispatch(
			// 		fetchSavedListingsAsync(authData.AuthenticationResult.AccessToken)
			// 	);

			// 	return { userData, authData };
			// }

			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const changeUserPassword = createAsyncThunk(
	'user/changePassword',
	async (newCredentials, { getState, rejectWithValue }) => {
		try {
			// const { accessToken } = getState().user.authData;
			console.log(newCredentials);
			const response = await forgotPasswordChangePassword(newCredentials);
			return newCredentials;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updateUserProfile = createAsyncThunk(
	'user/updateProfile',
	async (profileData, { getState, rejectWithValue }) => {
		try {
			const { accessToken } = getState().user.authData;
			const response = await updateProfile(accessToken, profileData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response?.data?.error || error.message);
		}
	}
);

export const deleteUserAccount = createAsyncThunk(
	'user/deleteAccount',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { accessToken } = getState().user.authData;
			await deleteAccount(accessToken);
			return null;
		} catch (error) {
			return rejectWithValue(error.response?.data?.error || error.message);
		}
	}
);

const userSlice = createSlice({
	name: 'user',
	initialState: {
		userData: null,
		authData: null,
		isAuthenticated: false,
		status: 'idle',
		error: null
	},
	reducers: {
		logout: (state) => {
			state.userData = null;
			state.authData = null;
			state.isAuthenticated = false;
			state.status = 'idle';
			state.error = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		resetStatus: (state) => {
			state.status = 'idle';
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.isAuthenticated = true;
				state.userData = action.payload.userData;
				state.authData = action.payload.authData.AuthenticationResult;
				state.error = null;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(refreshUserToken.fulfilled, (state, action) => {
				state.authData = action.payload;
			})
			.addCase(refreshUserToken.rejected, (state) => {
				// If token refresh fails, log the user out
				state.userData = null;
				state.authData = null;
				state.isAuthenticated = false;
				state.status = 'idle';
			})
			.addCase(verfifyEmailSignUp.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(verfifyEmailSignUp.fulfilled, (state) => {
				state.status = 'succeeded';
			})
			.addCase(verfifyEmailSignUp.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(updateUserProfile.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(updateUserProfile.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.userData = action.payload;
			})
			.addCase(updateUserProfile.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(deleteUserAccount.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(deleteUserAccount.fulfilled, (state) => {
				state.status = 'idle';
				state.userData = null;
				state.authData = null;
				state.isAuthenticated = false;
			})
			.addCase(deleteUserAccount.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			});
	}
});

export const { logout, clearError, resetStatus } = userSlice.actions;
export default userSlice.reducer;

// Selectors
export const selectUser = (state) => state.user.userData;
export const selectAuthData = (state) => state.user.authData;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;
export const selectUserStatus = (state) => state.user.status;
export const selectUserError = (state) => state.user.error;
