import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	fetchSavedListings,
	createSavedListing,
	deleteSavedListing
} from '../../utils/api';

// Async thunk for fetching saved listings
export const fetchSavedListingsAsync = createAsyncThunk(
	'savedListings/fetchSavedListings',
	async (accessToken, { getState, rejectWithValue }) => {
		// console.log(`fetchSavedListingsAsync accessToken: ${accessToken}`);
		try {
			// console.log(`In try fetchSavedListingsAsync accessToken: ${accessToken}`);
			// const accessToken = getState().user.authData.AccessToken;

			return await fetchSavedListings(accessToken);
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

// Async thunk for creating a saved listing
export const createSavedListingAsync = createAsyncThunk(
	'savedListings/createSavedListing',
	async ({ listingData, boardId }, { getState, rejectWithValue }) => {
		try {
			const accessToken = getState().user.authData.AccessToken;
			return await createSavedListing(accessToken, listingData, boardId);
			// 	console.log(listingData);
			// 	console.log(boardId);
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

// Async thunk for deleting a saved listing
export const deleteSavedListingAsync = createAsyncThunk(
	'savedListings/deleteSavedListing',
	async ({ mlsNumber, boardId }, { getState, rejectWithValue }) => {
		try {
			console.log(`mlsNumber ${mlsNumber}`);
			const accessToken = getState().user.authData.AccessToken;
			await deleteSavedListing(accessToken, mlsNumber, boardId);
			return mlsNumber;
		} catch (error) {
			return rejectWithValue(error.response ? error.response.data : error.message);
		}
	}
);

const savedListingsSlice = createSlice({
	name: 'savedListings',
	initialState: {
		listings: [],
		status: 'idle',
		error: null
	},
	reducers: {
		clearSavedListings: (state) => {
			state.listings = [];
			state.status = 'idle';
			state.error = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSavedListingsAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchSavedListingsAsync.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.listings = action.payload;
			})
			.addCase(fetchSavedListingsAsync.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(createSavedListingAsync.fulfilled, (state, action) => {
				state.listings.push(action.payload);
			})
			.addCase(deleteSavedListingAsync.fulfilled, (state, action) => {
				console.log(state.listings);
				console.log(action.payload);
				state.listings = state.listings.filter(
					(listing) => listing.mlsNumber !== action.payload
				);
				console.log(state.listings);
			});
	}
});

export const { clearSavedListings } = savedListingsSlice.actions;
export default savedListingsSlice.reducer;

// Selectors
export const selectAllSavedListings = (state) => state.savedListings.listings;
export const selectSavedListingsStatus = (state) => state.savedListings.status;
export const selectSavedListingsError = (state) => state.savedListings.error;
