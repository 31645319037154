import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';
import {
	loginUser,
	selectUserError,
	selectUserStatus,
	clearError,
	changeUserPasswordVerification,
	changeUserPassword
} from '../../features/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';

const ForgotPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		email: '',
		verficationCode: '',
		newPassword: '',
		confirmNewPassword: ''
	});
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	// const loginError = useSelector(selectUserError);
	// const loginStatus = useSelector(selectUserStatus);

	// useEffect(() => {
	// 	if (loginStatus === 'succeeded') {
	// 		navigate('/');
	// 	}
	// }, [loginStatus, navigate]);

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(clearError());
	// 	};
	// }, [dispatch]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value
		}));
		// Clear error when user starts typing
		if (errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: ''
			}));
		}
	};

	const handleSendCode = (e) => {
		e.preventDefault();
		let newErrors = {};
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			newErrors.email = 'Email is invalid';
		}
		setErrors(newErrors);
		console.log('send the code bitch', formData.email);
		dispatch(changeUserPasswordVerification(formData.email));
	};

	const validateForm = () => {
		let newErrors = {};
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			newErrors.email = 'Email is invalid';
		}
		if (!formData.newPassword) {
			newErrors.newPassword = 'Password is required';
		} else if (formData.newPassword.length < 8) {
			newErrors.newPassword = 'Password must be at least 8 characters';
		} else if (
			!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(formData.password)
		) {
			newErrors.confirmNewPassword =
				'Password must contain at least one number, one uppercase and lowercase letter, and one special character';
		}
		if (formData.newPassword !== formData.confirmNewPassword) {
			newErrors.confirmPassword = 'Passwords do not match';
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	//TODO: dispatch send code
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
		dispatch(changeUserPassword(formData));
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
			<div className="w-full max-w-md">
				<div className="mb-8 text-center">
					<div className="flex justify-center mb-4">
						<Logo />
					</div>
					<h2 className="text-xl font-normal text-[#000]">
						The Right Way to Find Your
					</h2>
					<h2 className="text-xl font-normal text-[#000]">Dream Home</h2>
					{/* {!loginError ? (
						<p className="mt-5 text-[#000]">Forgot Your Password?</p>
					) : (
						<p className="text-pinhous-red text-sm mt-2">{loginError}</p>
					)} */}
					<p className="mt-5 text-[#000]">Forgot Your Password?</p>
				</div>

				<form className="space-y-4" onSubmit={handleSubmit}>
					<div className="flex flex-nowrap">
						<div className="w-4/6">
							<input
								type="email"
								name="email"
								placeholder="Email"
								value={formData.email}
								onChange={handleChange}
								className={`w-full px-4 py-2 border ${
									errors.email ? 'border-pinhous-red' : 'border-[#878787]'
								} rounded-full focus:outline-none focus:border-pinhous-red`}
							/>
							{errors.email && (
								<p className="text-pinhous-red text-xs mt-1">
									{errors.email}
								</p>
							)}
						</div>
						<div className="w-2/6">
							<button
								className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
								onClick={handleSendCode}
							>
								Send Code
							</button>
						</div>
					</div>
					<div>
						<input
							type="text"
							name="verficationCode"
							placeholder="Verfication Code"
							value={formData.verficationCode}
							onChange={handleChange}
							className={`w-full px-4 py-2 border ${
								errors.verficationCode
									? 'border-pinhous-red'
									: 'border-[#878787]'
							} rounded-full focus:outline-none focus:border-pinhous-red`}
						/>
						{errors.verficationCode && (
							<p className="text-pinhous-red text-xs mt-1">
								{errors.verficationCode}
							</p>
						)}
					</div>
					<div className="relative">
						<input
							type={showPassword ? 'text' : 'password'}
							name="newPassword"
							placeholder="New Password"
							value={formData.newPassword}
							onChange={handleChange}
							className={`w-full px-4 py-2 border ${
								errors.newPassword
									? 'border-pinhous-red'
									: 'border-[#878787]'
							} rounded-full focus:outline-none focus:border-pinhous-red`}
						/>
						<button
							type="button"
							onClick={togglePasswordVisibility}
							className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
						>
							{showPassword ? 'Hide' : 'Show'}
						</button>
						{errors.newPassword && (
							<p className="text-pinhous-red text-xs mt-1">
								{errors.newPassword}
							</p>
						)}
					</div>
					<div className="relative">
						<input
							type={showPassword ? 'text' : 'password'}
							name="confirmNewPassword"
							placeholder="Confirm New Password"
							value={formData.confirmNewPassword}
							onChange={handleChange}
							className={`w-full px-4 py-2 border ${
								errors.confirmNewPassword
									? 'border-pinhous-red'
									: 'border-[#878787]'
							} rounded-full focus:outline-none focus:border-pinhous-red`}
						/>
						<button
							type="button"
							onClick={togglePasswordVisibility}
							className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
						>
							{showPassword ? 'Hide' : 'Show'}
						</button>
						{errors.confirmNewPassword && (
							<p className="text-pinhous-red text-xs mt-1">
								{errors.confirmNewPassword}
							</p>
						)}
					</div>

					<button
						type="submit"
						className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
						// disabled={loginStatus === 'loading'}
					>
						Reset Password
						{/* {loginStatus === 'loading' ? 'Logging in...' : 'Email Code'} */}
					</button>
				</form>

				{/* <div className="mt-6 flex items-center justify-between">
					<hr className="w-full border-[#000]" />
					<span className="px-2 text-gray-500 bg-white">or</span>
					<hr className="w-full border-[#000]" />
				</div> */}

				<div className="mt-6 text-center">
					<span className="text-black text-base font-normal font-['Quicksand'] leading-tight">
						Don't have an account?{' '}
					</span>
					<a
						className="text-[#345B84] hover:underline cursor-pointer"
						onClick={(e) => {
							e.preventDefault();
							navigate('/signup');
						}}
					>
						Sign up
					</a>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
