import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateDraftSearchParams,
	applySearchParams,
	selectDraftParams
} from '../../features/searchParams/searchParamsSlice';

const SortButton = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);

	const sortOptions = [
		{ label: 'Newest', value: 'newest' },
		{ label: 'Ascending Price', value: 'priceAsc' },
		{ label: 'Descending Price', value: 'priceDesc' }
	];

	const handleSelection = (sortValue) => {
		dispatch(updateDraftSearchParams({ sort: sortValue }));
		// dispatch(applySearchParams());
		setIsOpen(false);
	};

	const getDisplayText = () => {
		const currentSort = draftParams.sort || 'newest';
		const option = sortOptions.find((opt) => opt.value === currentSort);
		return option ? option.label : 'Newest';
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className={`
          flex items-center 
          justify-between 
          px-3 py-2 
          bg-pinhous-red text-white
          rounded-md 
          shadow-sm 
          hover:bg-gray-800
        `}
			>
				<span>{getDisplayText()}</span>
				<svg
					className={`w-5 h-5 ml-2 transition-transform ${
						isOpen ? 'rotate-180' : ''
					}`}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</button>

			{isOpen && (
				<div className="absolute z-[100] w-48 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
					{sortOptions.map((option) => (
						<button
							key={option.value}
							className={`
                w-full px-4 py-2 text-left text-sm
                ${
					option.value === draftParams.sort
						? 'text-red-500 bg-gray-50'
						: 'text-gray-700 hover:bg-gray-100'
				}
              `}
							onClick={() => handleSelection(option.value)}
						>
							{option.label}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default SortButton;
