import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateDraftSearchParams,
	applySearchParams,
	selectDraftParams
} from '../../features/searchParams/searchParamsSlice';

const BedsAndBathsButton = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);

	const options = ['Any', '1+', '2+', '3+', '4+'];

	const handleSelection = (type, value) => {
		const numericValue = value === 'Any' ? 0 : parseInt(value);
		dispatch(updateDraftSearchParams({ [type]: numericValue }));
	};

	const handleReset = () => {
		dispatch(updateDraftSearchParams({ minBeds: 0, minBaths: 0 }));
	};

	const handleApply = () => {
		dispatch(applySearchParams());
		setIsOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	const getDisplayText = () => {
		const beds = draftParams.minBeds;
		const baths = draftParams.minBaths;

		const bedText = beds ? `${beds}+ bed` : '';
		const bathText = baths ? `${baths}+ bath` : '';

		if (bedText && bathText) return `${bedText}, ${bathText}`;
		if (bedText) return bedText;
		if (bathText) return bathText;
		return 'Beds & Baths';
	};

	const OptionGroup = ({ title, type, value }) => (
		<div className="mb-4">
			<h3 className="text-lg mb-2">{title}</h3>
			<div className="inline-flex rounded-lg border border-gray-300 p-1 w-full">
				{options.map((option) => (
					<button
						key={option}
						onClick={() => handleSelection(type, option)}
						className={`
              flex-1 px-4 py-2 text-sm rounded-md transition-all
              ${
					value === (option === 'Any' ? 0 : parseInt(option))
						? 'bg-red-500 text-white'
						: 'hover:bg-gray-100'
				}
            `}
					>
						{option}
					</button>
				))}
			</div>
		</div>
	);

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className={`
          flex items-center 
          justify-between 
          px-3 py-2 
          border border-gray-300 
          rounded-md 
          shadow-sm 
          ${
				isOpen
					? 'bg-black text-white hover:bg-gray-900'
					: 'bg-white text-black hover:bg-gray-50'
			}
        `}
			>
				<span>{getDisplayText()}</span>
				<svg
					className={`w-5 h-5 ml-2 transition-transform ${
						isOpen ? 'rotate-180' : ''
					}`}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</button>

			{isOpen && (
				<div className="absolute z-[100] w-[400px] mt-1 p-4 bg-white border border-gray-300 rounded-md shadow-lg">
					<div className="flex justify-between items-center mb-4">
						<h3 className="text-lg font-semibold">Beds & Baths</h3>
						<div className="flex gap-2">
							<button
								onClick={handleReset}
								className="px-4 py-1 bg-gray-200 rounded-full text-sm hover:bg-gray-300"
							>
								Reset
							</button>
							<button
								onClick={handleApply}
								className="px-4 py-1 bg-black text-white rounded-full text-sm hover:bg-gray-800"
							>
								Apply
							</button>
						</div>
					</div>

					<OptionGroup
						title="Beds"
						type="minBeds"
						value={draftParams.minBeds}
					/>

					<OptionGroup
						title="Baths"
						type="minBaths"
						value={draftParams.minBaths}
					/>
				</div>
			)}
		</div>
	);
};

export default BedsAndBathsButton;
