import { createSlice } from '@reduxjs/toolkit';

const screenSizeSlice = createSlice({
	name: 'screenSize',
	initialState: {
		isMobile: false
	},
	reducers: {
		setIsMobile: (state, action) => {
			state.isMobile = action.payload;
		}
	}
});

export const { setIsMobile } = screenSizeSlice.actions;
export default screenSizeSlice.reducer;
