import React, { useState } from 'react';
import { Link, useNavigate, useLocation, useMatch } from 'react-router-dom';
import { ReactComponent as Logo } from '../icons/logo_pinhous.svg';
import { ReactComponent as FilterIcon } from '../icons/filter.svg';
import { ReactComponent as AISearchIcon } from '../icons/ai_search.svg';
import { ReactComponent as SavedListingsIcon } from '../icons/saved_listings.svg';
import { ReactComponent as ProfileIcon } from '../icons/profile.svg';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { ReactComponent as ImageSearchIcon } from '../icons/imageSearch.svg';
import { ReactComponent as MapViewIcon } from '../icons/map_view.svg';
import { ReactComponent as MasonaryIcon } from '../icons/masonary.svg';
import { PlusCircle, X } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { setActive } from '../features/active/activeSlice';
import { logout } from '../features/user/userSlice';
import { clearSavedListings } from '../features/savedListings/savedListingsSlice';
import ImageSearchModal from '../components/ImageSearchModal/ImageSearchModal.jsx';
import {
	selectListMapActive,
	setListMap,
	toggleImageSearchModal,
	selectImageSearchModalToggle,
	resetAllModals,
	selectFilterPanelToggle,
	toggleFilterPanel
} from '../features/active/activeSlice';
import {
	addKeyword,
	removeKeyword,
	selectKeywords,
	applySearchParams,
	selectImageSearchUrl,
	selectImageSearchFiles,
	resetImageSearch
} from '../features/searchParams/searchParamsSlice';
import Profile from './Profile';
import Filter from './Filter';

const DEBUG = process.env.REACT_APP_DEBUG === 'true';

const Navbar = () => {
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const active = useSelector((state) => state.active.value);
	const [searchInput, setSearchInput] = useState('');
	const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
	const listMapActive = useSelector(selectListMapActive);
	const keywords = useSelector(selectKeywords);
	const imageSearchModal = useSelector(selectImageSearchModalToggle);
	const location = useLocation();
	const currentPath = location.pathname;
	const matchBoard = useMatch('/boards/:id');
	const matchBoards = useMatch('/boards');
	const imageSearchUrl = useSelector(selectImageSearchUrl);
	const imageSearchFiles = useSelector(selectImageSearchFiles);
	const filterPanel = useSelector(selectFilterPanelToggle);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = (item) => {
		dispatch(setActive(item));
		dispatch(setListMap('List'));
	};

	const handlePinnedListingClick = () => {
		navigate('/boards');
	};

	const handleLogout = () => {
		dispatch(logout());
		dispatch(clearSavedListings());
	};

	const handleProfile = () => {
		dispatch(logout());
	};

	const toggleFilter = () => {
		setIsFilterOpen(!isFilterOpen);
		dispatch(toggleFilterPanel());
	};

	const toggleProfile = () => {
		setIsProfileOpen(!isProfileOpen);
	};

	const handleButtonToggleClick = () => {
		const button = listMapActive === 'List' ? 'Map' : 'List';
		dispatch(setListMap(button));
	};

	const handleSearchInputChange = (e) => {
		setSearchInput(e.target.value);
	};

	const handleAddKeyword = () => {
		if (searchInput.trim()) {
			dispatch(addKeyword(searchInput.trim()));
			setSearchInput('');
		}
	};

	const handleRemoveKeyword = (keyword) => {
		dispatch(removeKeyword(keyword));
	};

	return (
		<>
			<header className="fixed left-0 right-0 bg-white shadow-sm py-2 px-6 z-[100] flex items-center">
				<Link
					to="/"
					className="flex-shrink-0"
					onClick={() => handleClick('Home')}
				>
					<Logo className="w-30 h-10 text-red-500" />
				</Link>
				<button
					className="ml-4 p-2 bg-pinhous-background-grey text-[#89878C] rounded-full hover:bg-gray-100 transition-colors"
					onClick={toggleFilter}
				>
					<FilterIcon className="w-5 h-5" />
				</button>
				<div className="relative flex-grow mx-4 z-[100]">
					<div
						className={`flex justify-start items-center bg-gray-100 px-4 py-2 ${
							imageSearchModal ? 'rounded-t-xl' : 'rounded-full'
						}`}
					>
						<span className="inline-block whitespace-nowrap border border-gray-400 rounded-full px-2 py-1 mr-2">
							Toronto, ON
						</span>
						{keywords.map((keyword) => (
							<span
								key={keyword}
								className="inline-flex items-center whitespace-nowrap border border-gray-400 rounded-full px-2 py-1 mr-2"
							>
								<span className="mr-1">{keyword}</span>
								<button
									className="flex items-center justify-center w-4 h-4 text-gray-500 hover:text-gray-700"
									onClick={() => handleRemoveKeyword(keyword)}
								>
									<X size={12} />
								</button>
							</span>
						))}
						<input
							type="text"
							placeholder="Search Features"
							className="bg-transparent focus:outline-none w-full text-gray-700"
							value={searchInput}
							onChange={handleSearchInputChange}
						/>
						{searchInput && (
							<button
								className="mr-4 text-xl text-gray-500 hover:text-gray-700"
								onClick={handleAddKeyword}
							>
								+
							</button>
						)}
						<button
							className={`flex items-center ${
								imageSearchUrl || imageSearchFiles.length
									? `bg-black text-white p-1 rounded-lg`
									: `bg-transparent text-gray-400`
							}  rounded-full transition-colors`}
							onClick={() => dispatch(toggleImageSearchModal())}
						>
							{(imageSearchUrl || imageSearchFiles.length > 0) && (
								<X
									className="w-5 h-5 pr-1"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										dispatch(resetAllModals());
										dispatch(resetImageSearch());
									}}
								/>
							)}
							<ImageSearchIcon className="w-5 h-5 " />
						</button>
						<span className="px-2">|</span>
						<button
							className=" bg-transparent text-gray-400 rounded-full transition-colors"
							onClick={() => {
								dispatch(applySearchParams());
								navigate('/');
							}}
						>
							<SearchIcon className="w-5 h-5 text-black mr-1 hover:cursor-pointer" />
						</button>
					</div>

					{/* Conditionally render the new area when imageSearchModal is true */}
					{imageSearchModal && (
						<div
							className="absolute left-0 right-0 bg-gray-100 h-[500px] z-[200] shadow-lg rounded-b-xl"
							style={{
								top: '100%' // This will position it right below the navbar
							}}
						>
							<ImageSearchModal />
						</div>
					)}
				</div>
				<div className="flex items-center space-x-2">
					<div
						className="flex border-solid border bg-white border-black rounded-full align-middle items-center "
						onClick={handleButtonToggleClick}
					>
						<div
							className={`flex-1 px-2 py-1 rounded-l-full ${
								listMapActive === 'List'
									? 'bg-black text-white'
									: 'bg-transparent'
							}`}
						>
							<MasonaryIcon
								className={
									listMapActive === 'List'
										? `w-4 h-4 text-white`
										: `w-4 h-4 text-[#89878C]`
								}
							/>
						</div>
						<div
							className={`flex-1 px-2 py-1 rounded-r-full ${
								listMapActive === 'Map'
									? 'bg-black text-white'
									: 'bg-transparent'
							}`}
						>
							<MapViewIcon
								className={
									listMapActive === 'Map'
										? `w-4 h-4 text-white`
										: `w-4 h-4 text-[#89878C]`
								}
							/>
						</div>
					</div>

					{isAuthenticated && (
						<button
							className={
								matchBoard || matchBoards
									? ` p-2 bg-pinhous-black text-white rounded-full hover:bg-gray-100 transition-colors `
									: ` p-2 bg-pinhous-background-grey text-black rounded-full hover:bg-gray-100 transition-colors `
							}
							onClick={handlePinnedListingClick}
						>
							<SavedListingsIcon
								className="w-5 h-5"
								style={{ fill: '#89878C' }}
							/>
						</button>
					)}
					{isAuthenticated ? (
						<button
							className="p-2 bg-pinhous-background-grey text-[#89878C] rounded-full hover:bg-gray-100 transition-colors"
							onClick={toggleProfile}
						>
							<ProfileIcon className="w-5 h-5" />
						</button>
					) : (
						<button
							className="px-4 py-2 bg-pinhous-background-grey text-[#89878C] rounded-full hover:bg-gray-100 transition-colors"
							onClick={() => navigate('/login')}
						>
							Login
						</button>
					)}
				</div>
			</header>
			{/* {filterPanel && <Filter isOpen={filterPanel} onClose={toggleFilter} />} */}
			<Filter />
			{/* <Filter isOpen={isFilterOpen} onClose={toggleFilter} /> */}
			<Profile isOpen={isProfileOpen} onClose={toggleProfile} />
		</>
	);
};

export default React.memo(Navbar);
