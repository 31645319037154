import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';
import {
	loginUser,
	selectUserError,
	selectUserStatus,
	clearError,
	changeUserPasswordVerification,
	changeUserPassword,
	resendVerification,
	verfifyEmailSignUp,
	resetStatus,
	selectIsAuthenticated
} from '../../features/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';

const ConfirmEmail = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [verficationCode, setVerficationCode] = useState('');
	const [errors, setErrors] = useState({});
	const location = useLocation();
	const [email, setEmail] = useState(location.state?.email);
	const loginError = useSelector(selectUserError);
	const verificationStatus = useSelector(selectUserStatus);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	// const email = location.state?.email;
	// const loginError = useSelector(selectUserError);
	// const loginStatus = useSelector(selectUserStatus);

	// useEffect(() => {
	// 	if (loginStatus === 'succeeded') {
	// 		navigate('/');
	// 	}
	// }, [loginStatus, navigate]);

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(clearError());
	// 	};
	// }, [dispatch]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		console.log(name, value);
		setVerficationCode(value);
		// Clear error when user starts typing
		if (errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: ''
			}));
		}
	};

	const handleSendCode = (e) => {
		e.preventDefault();
		let newErrors = {};
		if (!email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
		}
		setErrors(newErrors);
		console.log('send the code', email);
		dispatch(resendVerification(email));
	};

	const validateForm = () => {
		let newErrors = {};
		if (!email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
		}
		if (!verficationCode) {
			newErrors.verficationCode = 'Verification Code is Requires';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	//TODO: dispatch send code
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(email, verficationCode);
		dispatch(verfifyEmailSignUp({ email, verficationCode }));
	};

	useEffect(() => {
		if (verificationStatus === 'succeeded') {
			dispatch(resetStatus());
			navigate('/login');
		}
	}, [verificationStatus, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearError());
		};
	}, [dispatch]);

	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
			<div className="w-full max-w-md">
				<div className="mb-8 text-center">
					<div className="flex justify-center mb-4">
						<Logo />
					</div>
					<h2 className="text-xl font-normal text-[#000]">
						The Right Way to Find Your
					</h2>
					<h2 className="text-xl font-normal text-[#000]">Dream Home</h2>
					{/* {!loginError ? (
						<p className="mt-5 text-[#000]">Forgot Your Password?</p>
					) : (
						<p className="text-[#F93A3A] text-sm mt-2">{loginError}</p>
					)} */}
					<p className="mt-5 text-[#000]">Confirm your Email</p>
				</div>

				<form className="space-y-4" onSubmit={handleSubmit}>
					<div className="flex flex-nowrap gap-x-1">
						<div className="w-4/6">
							<input
								type="text"
								name="verficationCode"
								placeholder="Verfication Code"
								value={verficationCode}
								onChange={handleChange}
								className={`w-full px-4 py-2 border ${
									errors.verficationCode
										? 'border-pinhous-red'
										: 'border-[#878787]'
								} rounded-full focus:outline-none focus:border-pinhous-red`}
							/>
							{errors.verficationCode && (
								<p className="text-[#F93A3A] text-xs mt-1">
									{errors.verficationCode}
								</p>
							)}
						</div>
						<div className="w-2/6">
							<button
								className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
								onClick={handleSendCode}
							>
								Resend Code
							</button>
						</div>
					</div>

					<button
						type="submit"
						className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
						// disabled={loginStatus === 'loading'}
					>
						Confirm Email
						{/* {loginStatus === 'loading' ? 'Logging in...' : 'Email Code'} */}
					</button>
				</form>

				{/* <div className="mt-6 flex items-center justify-between">
					<hr className="w-full border-[#000]" />
					<span className="px-2 text-gray-500 bg-white">or</span>
					<hr className="w-full border-[#000]" />
				</div> */}

				<div className="mt-6 text-center">
					<span className="text-black text-base font-normal font-['Quicksand'] leading-tight">
						Already have an account?{' '}
					</span>
					<a
						className="text-[#345B84] hover:underline cursor-pointer"
						onClick={(e) => {
							e.preventDefault();
							navigate('/login');
						}}
					>
						Login
					</a>
				</div>
			</div>
		</div>
	);
};

export default ConfirmEmail;
