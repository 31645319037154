import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';
import { ReactComponent as AiSearchIcon } from '../../icons/ai_search.svg';

const PropertyDescription = () => {
	const [expanded, setExpanded] = useState(false);
	const listingData = useSelector(selectSingleListingData);

	if (!listingData || !listingData.details) {
		return <div>No description available</div>;
	}

	const generateSummaryPoints = () => {
		const points = [];

		if (listingData.details.propertyType) {
			points.push(
				`${listingData.details.propertyType} in ${listingData.address.neighborhood}`
			);
		}

		if (listingData.details.style) {
			points.push(listingData.details.style);
		}

		if (listingData.details.heating) {
			points.push(`${listingData.details.heating} heating`);
		}

		if (listingData.details.airConditioning) {
			points.push(listingData.details.airConditioning);
		}

		if (listingData.condominium?.ammenities) {
			points.push(...listingData.condominium.ammenities);
		}

		if (listingData.nearby?.ammenities) {
			points.push(
				...listingData.nearby.ammenities.map((amenity) => `Near ${amenity}`)
			);
		}

		if (listingData.details.extras) {
			points.push(listingData.details.extras);
		}

		return points;
	};

	const summaryPoints = generateSummaryPoints();

	return (
		<div className="p-6 bg-white rounded-lg mt-2">
			<div className="flex justify-between items-start mb-4">
				<h2 className="text-2xl font-semibold">Description</h2>
			</div>

			<div className={`relative ${expanded ? '' : 'max-h-48'} overflow-hidden`}>
				<div className="flex gap-6">
					{/* Description Column */}
					<div className="flex-1">
						<p className="text-gray-700">{listingData.details.description}</p>
					</div>

					{/* AI Summary Column */}
					<div className="w-1/3 bg-gray-200 rounded-lg p-4">
						<h3 className="flex font-medium text-gray-800 mb-3">
							<span>
								<AiSearchIcon className="w-4 h-4 mt-[2px] mr-[2px]" />
							</span>
							AI Generated Summary
						</h3>
						{summaryPoints.length > 0 && (
							<ul className="list-disc pl-4 space-y-2 text-gray-700">
								{summaryPoints.map((point, index) => (
									<li key={index} className="text-sm">
										{point}
									</li>
								))}
							</ul>
						)}
					</div>
				</div>

				{!expanded && (
					<div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent" />
				)}
			</div>

			<button
				onClick={() => setExpanded(!expanded)}
				className="mt-4 flex items-center text-blue-500 hover:text-blue-600"
			>
				<span className="mr-1">{expanded ? 'Read Less' : 'Read More'}</span>
				{expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
			</button>
		</div>
	);
};

export default PropertyDescription;
