import React, { useState } from 'react';
import kitchenImage from '../../images/Kitchens/4e0b2174838cb9600c7fdcf0e719e941.jpg';
import bathroomImage from '../../images/Bathrooms/7b41ca6fd79cf2e5d193826130a7c02c.jpg';
import livingRoomImage from '../../images/LivingRooms/1d0874f92b43d4b53feaac474a4304a7.jpg';
import exteriorImage from '../../images/Exterior/0fcb6f3398f0bf719c85bc133d691d03.jpg';
import bedroomImage from '../../images/Bedroom/0c562d5c260eefa13c64fdda1d288882.jpg';
import {
	uploadImage,
	applySearchParams,
	setImageUrl,
	selectDraftParams,
	selectImageUploadStatus,
	selectImageUploadError
} from '../../features/searchParams/searchParamsSlice';
import {
	toggleImageSearchModal,
	selectCategoryActive,
	setCategoryActive,
	resetCategoryActive,
	resetAllModals
} from '../../features/active/activeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const categories = [
	{ name: 'Kitchens', image: kitchenImage },
	{ name: 'Bathrooms', image: bathroomImage },
	{ name: 'LivingRooms', image: livingRoomImage },
	{ name: 'Exterior', image: exteriorImage },
	{ name: 'Bedrooms', image: bedroomImage }
];

const kitchenUrls = [
	'https://www.deslaurier.com/hs-fs/hubfs/Showcase/Cobalt%20Skies/Deslautier-Brenda-006-1024x683.jpg?width=1024&name=Deslautier-Brenda-006-1024x683.jpg',
	'https://www.thespruce.com/thmb/-DAaJ4cGiiDMyKGkW40TWHPo1d8=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-modern-kitchen-ideas-7110474-hero-e78cb87316b847fb87fe807ffd4a0921.jpg',
	'https://st.hzcdn.com/simgs/pictures/kitchens/kitchens-michael-alan-kaskel-img~0d511d8e0e77ab3a_14-6521-1-7aacee2.jpg',
	'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/La_cuisine_%28mus%C3%A9e_dart_nouveau%2C_Riga%29_%287563655820%29.jpg/800px-La_cuisine_%28mus%C3%A9e_dart_nouveau%2C_Riga%29_%287563655820%29.jpg',
	'https://optimise2.assets-servd.host/thick-groundhog/production/assets/blog/Modern-Kitchen-Trend.jpg?w=1000&h=600&auto=compress%2Cformat&fit=crop&dm=1655129886&s=1217c3cb45e69b1674afcafd2f6e9478',
	'https://decorcabinets.com/wp-content/uploads/2023/01/0.-Blog-Cover-2-1024x576.png'
];

// const RoomCategoryGrid = () => {
// 	// const [selectedCategory, setSelectedCategory] = useState(null);
// 	const selectedCategory = useSelector(selectCategoryActive);
// 	const [selectedImage, setSelectedImage] = useState(null);
// 	const dispatch = useDispatch();
// 	const navigate = useNavigate();
// 	const draftParams = useSelector(selectDraftParams);

// 	const handleCategoryClick = (category) => {
// 		dispatch(setCategoryActive(category));
// 		setSelectedImage(null);
// 	};

// 	const handleImageClick = (image) => {
// 		setSelectedImage(image);
// 	};

// 	return (
// 		<div className="flex flex-col bg-[#FAFAFA] w-full h-full border-pinhous-light-grey rounded-[9px] overflow-hidden">
// 			{selectedCategory ? (
// 				<>
// 					<div className="flex-1 overflow-y-auto">
// 						<h2 className="text-xl font-semibold p-4">{selectedCategory}</h2>
// 						<div className="grid grid-cols-2 gap-4 p-4 pb-4">
// 							{kitchenUrls.map((image, index) => (
// 								<div
// 									key={index}
// 									className={`cursor-pointer ${
// 										selectedImage === image
// 											? 'ring-4 ring-pinhous-blue rounded-lg'
// 											: ''
// 									}`}
// 									onClick={() => handleImageClick(image)}
// 								>
// 									<img
// 										src={image}
// 										alt={`${selectedCategory} ${index + 1}`}
// 										className="w-full h-48 object-cover rounded-lg"
// 									/>
// 								</div>
// 							))}
// 						</div>
// 						<div className="flex justify-between items-center p-4 pb-10">
// 							<button
// 								className="px-4 py-2 bg-pinhous-blue text-white rounded-lg"
// 								onClick={() => dispatch(resetCategoryActive())}
// 							>
// 								Back to Categories
// 							</button>
// 							<button
// 								className="px-4 py-2 bg-[#FFFFFF] text-pinhous-blue border border-pinhous-blue rounded-lg"
// 								onClick={() => {
// 									dispatch(setImageUrl(selectedImage));
// 									// dispatch(toggleImageSearchModal());w
// 									dispatch(resetAllModals());
// 									dispatch(applySearchParams());
// 									console.log(
// 										'Search with selected image:',
// 										selectedImage
// 									);
// 									navigate('/search');
// 								}}
// 							>
// 								Search
// 							</button>
// 						</div>
// 					</div>
// 				</>
// 			) : (
// 				<div className="flex-1 overflow-y-auto">
// 					<div className="grid grid-cols-2 gap-4 p-4">
// 						{categories.map((category) => (
// 							<div
// 								key={category.name}
// 								className={`relative cursor-pointer rounded-lg overflow-hidden ${
// 									category.name !== 'Kitchens' ? 'opacity-50' : ''
// 								}`}
// 								onClick={() => handleCategoryClick(category.name)}
// 							>
// 								<img
// 									src={category.image}
// 									alt={category.name}
// 									className="w-full h-48 object-cover"
// 								/>
// 								<div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-4">
// 									<span className="text-white text-xl font-semibold">
// 										{category.name}
// 									</span>
// 								</div>
// 							</div>
// 						))}
// 					</div>
// 				</div>
// 			)}
// 		</div>
// 	);
// };

const RoomCategoryGrid = () => {
	const selectedCategory = useSelector(selectCategoryActive);
	const [selectedImage, setSelectedImage] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleCategoryClick = (category) => {
		dispatch(setCategoryActive(category));
		setSelectedImage(null);
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	return (
		<div className="flex flex-col bg-[#FAFAFA] w-full h-full max-h-[80vh] border-pinhous-light-grey rounded-[9px] overflow-hidden">
			<div className="flex-1 overflow-y-auto">
				{selectedCategory ? (
					<>
						<h2 className="text-xl font-semibold p-4 sticky top-0 bg-[#FAFAFA] z-10">
							{selectedCategory}
						</h2>
						<div className="grid grid-cols-2 gap-4 p-4">
							{kitchenUrls.map((image, index) => (
								<div
									key={index}
									className={`cursor-pointer ${
										selectedImage === image
											? 'ring-4 ring-pinhous-blue rounded-lg'
											: ''
									}`}
									onClick={() => handleImageClick(image)}
								>
									<img
										src={image}
										alt={`${selectedCategory} ${index + 1}`}
										className="w-full h-48 object-cover rounded-lg"
									/>
								</div>
							))}
						</div>
					</>
				) : (
					<div className="grid grid-cols-2 gap-4 p-4">
						{categories.map((category) => (
							<div
								key={category.name}
								className={`relative cursor-pointer rounded-lg overflow-hidden ${
									category.name !== 'Kitchens' ? 'opacity-50' : ''
								}`}
								onClick={() => handleCategoryClick(category.name)}
							>
								<img
									src={category.image}
									alt={category.name}
									className="w-full h-48 object-cover"
								/>
								<div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-4">
									<span className="text-white text-xl font-semibold">
										{category.name}
									</span>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
			{selectedCategory && (
				<div className="sticky bottom-0 bg-[#FAFAFA] p-4 border-t border-pinhous-light-grey">
					<div className="flex justify-between items-center">
						<button
							className="px-4 py-2 bg-pinhous-blue text-white rounded-lg"
							onClick={() => dispatch(resetCategoryActive())}
						>
							Back to Categories
						</button>
						<button
							className="px-4 py-2 bg-[#FFFFFF] text-pinhous-blue border border-pinhous-blue rounded-lg"
							onClick={() => {
								dispatch(setImageUrl(selectedImage));
								dispatch(resetAllModals());
								dispatch(applySearchParams());
							}}
						>
							Search
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default RoomCategoryGrid;
