import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateDraftSearchParams,
	applySearchParams,
	selectDraftParams
} from '../../features/searchParams/searchParamsSlice';
import ReactSlider from 'react-slider';

const PriceFilterButton = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);

	const formatPrice = (price) => {
		return price?.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		});
	};

	// const handleInputChange = (field, value) => {
	// 	// Remove any non-numeric characters and convert to number
	// 	const numericValue = value ? Number(value.replace(/[^0-9]/g, '')) : 0;
	// 	dispatch(updateDraftSearchParams({ [field]: numericValue }));
	// };

	const handleReset = () => {
		dispatch(updateDraftSearchParams({ minPrice: 250000, maxPrice: 2500000 }));
	};

	const handleApply = () => {
		dispatch(applySearchParams());
		setIsOpen(false);
	};

	const handleInputChange = (name, value) => {
		dispatch(updateDraftSearchParams({ [name]: value }));
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className={`
          flex items-center 
          justify-between 
          px-3 py-2 
          border border-gray-300 
          rounded-md 
          shadow-sm 
          ${
				isOpen
					? 'bg-black text-white hover:bg-gray-900'
					: 'bg-white text-black hover:bg-gray-50'
			}
        `}
			>
				<span>Price</span>
				<svg
					className={`w-5 h-5 ml-2 transition-transform ${
						isOpen ? 'rotate-180' : ''
					}`}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</button>

			{isOpen && (
				<div className="absolute z-[100] w-[400px] mt-1 p-4 bg-white border border-gray-300 rounded-md shadow-lg">
					<div className="flex justify-between items-center mb-4">
						<h3 className="text-lg font-semibold">Price Range</h3>
						<div className="flex gap-2">
							<button
								onClick={handleReset}
								className="px-4 py-1 bg-gray-200 rounded-full text-sm hover:bg-gray-300"
							>
								Reset
							</button>
							<button
								onClick={handleApply}
								className="px-4 py-1 bg-black text-white rounded-full text-sm hover:bg-gray-800"
							>
								Apply
							</button>
						</div>
					</div>
					<div className="py-5">
						<ReactSlider
							className="w-full h-1 mt-4 bg-gray-200"
							thumbClassName="w-5 h-5 bg-white border-2 border-pinhous-red rounded-full outline-none top-[-8px]"
							trackClassName="h-1"
							min={0}
							max={50000000}
							step={10000}
							value={[
								draftParams.minPrice || 0,
								draftParams.maxPrice || 50000000
							]}
							onChange={([min, max]) => {
								handleInputChange('minPrice', min);
								handleInputChange('maxPrice', max);
							}}
							pearling
							minDistance={100000}
							renderTrack={(props, state) => (
								<div
									{...props}
									className={`h-1 ${
										state.index === 1
											? 'bg-pinhous-red'
											: 'bg-gray-200'
									}`}
								/>
							)}
						/>
					</div>

					<div className="space-y-4">
						<div className="flex justify-between items-center">
							<div className="w-[45%]">
								<label className="block text-sm font-medium text-gray-700">
									Minimum
								</label>
								<input
									type="text"
									value={formatPrice(draftParams.minPrice)}
									onChange={(e) =>
										handleInputChange('minPrice', e.target.value)
									}
									className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-black"
								/>
							</div>
							<div className="w-[45%]">
								<label className="block text-sm font-medium text-gray-700">
									Maximum
								</label>
								<input
									type="text"
									value={formatPrice(draftParams.maxPrice)}
									onChange={(e) =>
										handleInputChange('maxPrice', e.target.value)
									}
									className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-black"
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PriceFilterButton;
