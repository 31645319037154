import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const DEBUG = process.env.REACT_APP_DEBUG === 'true';

export const fetchListings = createAsyncThunk(
	'listings/fetchListings',
	async (options, { getState }) => {
		try {
			const response = await axios.request(options);
			if (DEBUG) console.log('Fetch Listings Response', response);
			// console.log(`options data page ${options.data.pageNum}`);
			return { ...response.data, requestedPage: options.data.pageNum };
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
);

const initialState = {
	items: [],
	pageData: {},
	currentPage: 1,
	hasMore: true,
	status: 'idle',
	error: null
};

const listingsSlice = createSlice({
	name: 'listings',
	initialState,
	reducers: {
		resetListings: () => initialState
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchListings.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchListings.fulfilled, (state, action) => {
				state.status = 'succeeded';
				if (DEBUG)
					console.log('Fetch Listings Payload listing slice', action.payload);
				if (action.payload.page === 1) {
					state.items = action.payload.listings;
				} else {
					state.items = [...state.items, ...action.payload.listings];
				}
				state.pageData = action.payload;
				state.currentPage = action.payload.page;
				state.hasMore = action.payload.page < action.payload.numPages;
			})
			.addCase(fetchListings.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	}
});

export const { selectCurrentPage } = (state) => state.listings.currentPage;
export const { resetListings } = listingsSlice.actions;
export default listingsSlice.reducer;
