import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const uploadImages = createAsyncThunk(
	'searchParams/uploadImages',
	async (files, { rejectWithValue }) => {
		try {
			// No need to read the file using FileReader here
			return files;
		} catch (error) {
			return rejectWithValue('Failed to read the image files');
		}
	}
);

const initialState = {
	active: {
		city: 'Toronto',
		openHouse: {
			Today: false,
			Tomorrow: false,
			'Within a week': false,
			'Any day': false
		},
		minOpenHouseDate: null,
		maxOpenHouseDate: null,
		listingType: '',
		imageSearchUrl: '',
		imageSearchFiles: [],
		keywords: []
	},
	draft: {
		city: 'Toronto',
		openHouse: {
			Today: false,
			Tomorrow: false,
			'Within a week': false,
			'Any day': false
		},
		minOpenHouseDate: null,
		maxOpenHouseDate: null,
		listingType: '',
		imageSearchUrl: '',
		imageSearchFiles: [],
		keywords: []
	},
	imageUploadStatus: 'idle',
	imageUploadError: null
};

const searchParamsSlice = createSlice({
	name: 'searchParams',
	initialState,
	reducers: {
		updateDraftSearchParams: (state, action) => {
			state.draft = { ...state.draft, ...action.payload };
		},
		applySearchParams: (state) => {
			state.active = { ...state.draft };
		},
		resetSearchParams: () => initialState,
		resetImageSearch: (state) => {
			state.draft.imageSearchUrl = '';
			state.draft.imageSearchFiles = [];
			state.active.imageSearchUrl = '';
			state.active.imageSearchFiles = [];
		},
		setImageUrl: (state, action) => {
			state.draft.imageSearchUrl = action.payload;
			state.draft.imageSearchFile = null;
		},
		addKeyword: (state, action) => {
			if (!state.draft.keywords.includes(action.payload)) {
				state.draft.keywords.push(action.payload);
			}
		},
		removeKeyword: (state, action) => {
			state.draft.keywords = state.draft.keywords.filter(
				(keyword) => keyword !== action.payload
			);
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(uploadImages.pending, (state) => {
				state.imageUploadStatus = 'loading';
			})
			.addCase(uploadImages.fulfilled, (state, action) => {
				state.imageUploadStatus = 'succeeded';
				state.draft.imageSearchFiles = [
					...state.draft.imageSearchFiles,
					...action.payload
				]; // Now an array of File objects
				state.draft.imageSearchUrl = ''; // Clear URL if files are uploaded
			})
			.addCase(uploadImages.rejected, (state, action) => {
				state.imageUploadStatus = 'failed';
				state.imageUploadError = action.payload;
			});
	}
});

export const {
	updateDraftSearchParams,
	applySearchParams,
	resetSearchParams,
	setImageUrl,
	addKeyword,
	removeKeyword,
	resetImageSearch
} = searchParamsSlice.actions;

export const selectActiveParams = (state) => state.searchParams.active;
export const selectDraftParams = (state) => state.searchParams.draft;
export const selectImageUploadStatus = (state) => state.searchParams.imageUploadStatus;
export const selectImageUploadError = (state) => state.searchParams.imageUploadError;
export const selectKeywords = (state) => state.searchParams.draft.keywords;
export const selectImageSearchUrl = (state) => state.searchParams.draft.imageSearchUrl;
export const selectImageSearchFiles = (state) =>
	state.searchParams.draft.imageSearchFiles;
export const selectDraftListingType = (state) => state.searchParams.draft.listingType;

export default searchParamsSlice.reducer;
