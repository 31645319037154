import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store/store';
import { APIProvider } from '@vis.gl/react-google-maps';
import { PostHogProvider } from 'posthog-js/react'
import './index.css';

const posthogOptions = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
	ui_host: 'https://us.posthog.com',
	autocapture: true,
	capture_pageview: true,
	capture_pageleave: true,
	xhr_headers: {
		'Content-Type': 'application/json'
	},
	bootstrap: {
		distinctID: 'anonymous-user',
		isIdentifiedID: false
	},
	api_method: 'POST',
	request_timeout: 1000,
	ingest_endpoint: '/ingest',
	persistence: 'localStorage',
	cross_subdomain_cookie: false
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
						<PostHogProvider
							apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
							options={posthogOptions}
						>
							<App />
						</PostHogProvider>
					</APIProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);
