import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as ArrowDown } from '../../icons/arrowDown.svg';
import { ReactComponent as Hotspot } from '../../icons/hotspot.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateDraftSearchParams,
  applySearchParams,
  resetSearchParams
} from '../../features/searchParams/searchParamsSlice';
import { resetListings } from '../../features/listings/listingsSlice';

const FilterButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const { minPrice, maxPrice } = useSelector((state) => state.searchParams);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleMinChange = (event) => {
    dispatch(updateDraftSearchParams({ minPrice: event.target.value }));
  };

  const handleMaxChange = (event) => {
    dispatch(updateDraftSearchParams({ maxPrice: event.target.value }));
  };

  const handlePriceMinChange = (event) => {
    dispatch(updateDraftSearchParams({ minPrice: event.target.value }));
  };

  const handlePriceMaxChange = (event) => {
    dispatch(updateDraftSearchParams({ maxPrice: event.target.value }));
  };

  const handleRoomMinChange = (event) => {
    dispatch(updateDraftSearchParams({ minRooms: event.target.value }));
  };

  const handleRoomMaxChange = (event) => {
    dispatch(updateDraftSearchParams({ maxRooms: event.target.value }));
  };

  const handleBathMinChange = (event) => {
    dispatch(updateDraftSearchParams({ minBaths: event.target.value }));
  };

  const handleBathMaxChange = (event) => {
    dispatch(updateDraftSearchParams({ maxBaths: event.target.value }));
  };

  const handleApply = () => {
    setIsOpen(false);
    dispatch(resetListings());
    dispatch(applySearchParams());
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={handleButtonClick}
      >
        {props.filterType}
        <ArrowDown className="w-5 h-5 ml-2" />
        {props.hot === 'True' && (
          <Hotspot className={`w-5 h-5 ml-2 text-red-500`} />
        )}
      </button>
      {isOpen && props.filterType === 'Price' && (
        <div className="absolute z-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-4">
            <div className="flex justify-between mb-2">
              <span className="text-sm font-medium text-gray-700">Minimum</span>
              <span className="text-sm font-medium text-gray-700">Maximum</span>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                placeholder="No Min"
                onChange={handlePriceMinChange}
                value={minPrice}
              />
              <span className="mx-2">-</span>
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                placeholder="No Max"
                onChange={handlePriceMaxChange}
                value={maxPrice}
              />
            </div>
            <button
              className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </div>
      )}
      {isOpen && props.filterType === 'Property Type' && (
        <div className="absolute z-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-4">
            <p className="text-sm text-gray-500">Coming Soon</p>
          </div>
        </div>
      )}
      {isOpen && props.filterType === 'Rooms' && (
        <div className="absolute z-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2 text-center">Rooms</h3>
            <div className="flex justify-between mb-2">
              <span className="text-sm font-medium text-gray-700">Minimum</span>
              <span className="text-sm font-medium text-gray-700">Maximum</span>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                placeholder="No Min"
                onChange={handleRoomMinChange}
                value={minPrice}
              />
              <span className="mx-2">-</span>
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                placeholder="No Max"
                onChange={handleRoomMaxChange}
                value={maxPrice}
              />
            </div>
            <h3 className="text-sm font-medium text-gray-700 mb-2 text-center">Baths</h3>
            <div className="flex justify-between mb-2">
              <span className="text-sm font-medium text-gray-700">Minimum</span>
              <span className="text-sm font-medium text-gray-700">Maximum</span>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                placeholder="No Min"
                onChange={handleBathMinChange}
                value={minPrice}
              />
              <span className="mx-2">-</span>
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                placeholder="No Max"
                onChange={handleBathMaxChange}
                value={maxPrice}
              />
            </div>
            <button
              className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </div>
      )}
      {isOpen && props.filterType === 'Listing Status' && (
        <div className="absolute z-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-4">
            <p className="text-sm text-gray-500">Coming Soon</p>
          </div>
        </div>
      )}
      {isOpen && props.filterType === 'More' && (
        <div className="absolute z-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-4">
            <p className="text-sm text-gray-500">Coming Soon</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterButton;
