import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	selectListMapActive,
	selectImageSearchModalToggle,
	selectBoardModalToggle
} from '../../features/active/activeSlice';
import {
	selectAllBoardsWithListings,
	fetchBoardsWithListingsAsync
} from '../../features/boards/boardsSlice';
import MapComponent from '../../components/MapComponent/MapComponent.jsx';
import ImageSearchModal from '../../components/ImageSearchModal/ImageSearchModal.jsx';
import Card2 from '../../components/Card/Card2.jsx';
import BoardsModal from '../../components/BoardsModal.jsx';

export default function Board() {
	const { boardId } = useParams();
	const dispatch = useDispatch();
	const { boards, boardListings } = useSelector(selectAllBoardsWithListings);
	const listMapActive = useSelector(selectListMapActive);
	const imageSearchModalActive = useSelector(selectImageSearchModalToggle);
	const boardModalActive = useSelector(selectBoardModalToggle);

	const [localSavedListings, setLocalSavedListings] = useState([]);

	useEffect(() => {
		dispatch(fetchBoardsWithListingsAsync());
	}, [dispatch]);

	useEffect(() => {
		const currentBoard = boards.find((board) => board.id === parseInt(boardId));
		setLocalSavedListings(currentBoard ? currentBoard.SavedListings : []);
	}, [boards, boardId]);

	const handleDeleteListing = useCallback((deletedMlsNumber) => {
		setLocalSavedListings((prevListings) =>
			prevListings.filter(
				(listing) => listing.listingData.mlsNumber !== deletedMlsNumber
			)
		);
	}, []);

	return (
		<div className="flex flex-col h-screen px-6">
			<div className="flex justify-center">
				<h1 className="text-2xl font-bold pt-6 pb-3">
					{boards.find((board) => board.id === parseInt(boardId))?.name ||
						'Board'}
				</h1>
			</div>
			{imageSearchModalActive && <ImageSearchModal />}
			{boardModalActive && <BoardsModal />}
			<div className="flex-grow overflow-y-auto h-full">
				{listMapActive === 'List' && (
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 p-5 w-full">
						{localSavedListings.map((listing, index) => (
							<Card2
								key={listing.listingData.mlsNumber || index}
								data={listing.listingData}
								index={index}
								isMap={false}
								onDelete={handleDeleteListing}
							/>
						))}
						{localSavedListings.length === 0 && (
							<div>No saved listings in this board.</div>
						)}
					</div>
				)}
				{listMapActive === 'Map' && (
					<div className="flex w-full h-[calc(100vh-80px)]">
						<div className="relative w-[70%] h-full">
							<MapComponent
								props={{
									map: {
										latitude: 43.6788527,
										longitude: -79.4067778,
										point: 'POINT (-79.4067778 43.6788527)'
									},
									size: 'full'
								}}
							/>
						</div>
						<div className="w-[30%] h-full overflow-y-auto p-2.5 box-border">
							<div className="grid grid-cols-1 gap-5">
								{localSavedListings.map((listing, index) => (
									<Card2
										key={listing.listingData.mlsNumber || index}
										data={listing.listingData}
										index={index}
										isMapList={true}
										onDelete={handleDeleteListing}
									/>
								))}
								{localSavedListings.length === 0 && (
									<div>No saved listings in this board.</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { fetchListings } from '../../features/listings/listingsSlice.js';
// import { useSelector, useDispatch } from 'react-redux';
// import {
// 	selectListMapActive,
// 	selectImageSearchModalToggle,
// 	selectBoardModalToggle
// } from '../../features/active/activeSlice';
// import {
// 	selectAllBoards,
// 	selectBoardsStatus,
// 	selectBoardsError,
// 	fetchBoardsAsync,
// 	createBoardAsync,
// 	selectAllBoardsWithListings,
// 	fetchBoardsWithListingsAsync
// } from '../../features/boards/boardsSlice';

// import { useParams } from 'react-router-dom';
// import MapComponent from '../../components/MapComponent/MapComponent.jsx';
// import ImageSearchModal from '../../components/ImageSearchModal/ImageSearchModal.jsx';
// import Card2 from '../../components/Card/Card2.jsx';
// import BoardsModal from '../../components/BoardsModal.jsx';
// const DEBUG = process.env.REACT_APP_DEBUG === 'true';

// export default function Board() {
// 	//TODO: change items to be the saved items
// 	const { items, pageData, currentPage, hasMore, status, error } = useSelector(
// 		(state) => state.listings
// 	);
// 	const { boardId } = useParams();
// 	const { boards, boardListings } = useSelector(selectAllBoardsWithListings);
// 	const listMapActive = useSelector(selectListMapActive);
// 	const imageSearchModalActive = useSelector(selectImageSearchModalToggle);
// 	const boardModalActive = useSelector(selectBoardModalToggle);

// 	const dispatch = useDispatch();

// 	// Find the specific board
// 	const currentBoard = boards.find((board) => board.id === parseInt(boardId));

// 	// Get the SavedListings for the current board
// 	const savedListings = currentBoard ? currentBoard.SavedListings : [];

// 	useEffect(() => {
// 		dispatch(fetchBoardsWithListingsAsync());
// 	}, []);

// 	return (
// 		<div className="flex flex-col h-screen px-6">
// 			<div className="flex justify-center">
// 				<h1 className="text-2xl font-bold pt-6 pb-3">
// 					{' '}
// 					{currentBoard ? currentBoard.name : 'Board'}
// 				</h1>
// 			</div>
// 			{imageSearchModalActive && <ImageSearchModal />}
// 			{boardModalActive && <BoardsModal />}
// 			<div className="flex-grow overflow-y-auto h-full">
// 				{listMapActive === 'List' && (
// 					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 p-5 w-full">
// 						{savedListings.map((listing, index) => (
// 							<Card2
// 								key={listing.mlsNumber || index}
// 								data={listing.listingData}
// 								index={index}
// 								isMap={false}
// 							/>
// 						))}
// 						{savedListings.length === 0 && (
// 							<div>No saved listings in this board.</div>
// 						)}
// 					</div>
// 				)}
// 				{listMapActive === 'Map' && (
// 					<div className="flex w-full h-[calc(100vh-80px)]">
// 						<div className="relative w-[70%] h-full">
// 							<MapComponent
// 								props={{
// 									map: {
// 										latitude: 43.6788527,
// 										longitude: -79.4067778,
// 										point: 'POINT (-79.4067778 43.6788527)'
// 									},
// 									size: 'full'
// 								}}
// 							/>
// 						</div>
// 						<div className="w-[30%] h-full overflow-y-auto p-2.5 box-border">
// 							<div className="grid grid-cols-1 gap-5">
// 								{savedListings.map((listing, index) => (
// 									<Card2
// 										key={listing.mlsNumber || index}
// 										data={listing.listingData}
// 										index={index}
// 										isMapList={true}
// 									/>
// 								))}
// 								{savedListings.length === 0 && (
// 									<div>No saved listings in this board.</div>
// 								)}
// 							</div>
// 						</div>
// 					</div>
// 				)}
// 			</div>
// 		</div>
// 	);
// }
