import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';

import Search from './pages/search/Search.jsx';
// import Listings from './pages/listings/Listings.jsx';
import Listings2 from './pages/listings/Listings2.jsx';
// import Header from './components/Header2.jsx';
import Header from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';
import Partner from './pages/partner';
import Boards from './pages/Boards/Boards.jsx';
import Board from './pages/Boards/Board.jsx';
import SplashScreen from './pages/onboarding/SplashScreen.jsx';
import Login from './pages/login/LoginForm.jsx';
import ForgotPassword from './pages/login/ForgotPassword.jsx';
import SignUpForm from './pages/onboarding/SignUpForm.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobile } from './features/screenSize/screenSizeSlice.js';
import Onboarding from './pages/onboarding/OnboardingForm.jsx';
import ConfirmEmail from './pages/onboarding/ConfirmEmail.jsx';
import ProtectedRoute from './components/ProtectedRoute';
import { selectIsAuthenticated } from './features/user/userSlice';
import ChangePassword from './components/ChangePassword.jsx';
import EditProfile from './pages/profile/EditProfile.jsx';
import { setMapLoaded, setMapError } from './features/map/mapSlice';

import Hero from './pages/hero/Hero.jsx';
import { selectFullscreenGallery } from './features/active/activeSlice';

// const libraries = ['places'];
// const mapOptions = {
// 	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
// 	libraries,
// 	// Remove the callback
// 	// Add version
// 	version: 'weekly'
// };

export default function App() {
	const location = useLocation();
	const dispatch = useDispatch();
	const isMobile = useSelector((state) => state.screenSize.isMobile);
	const fullscreenGallery = useSelector(selectFullscreenGallery);
	const isAuthenticated = useSelector(selectIsAuthenticated);

	// const { isLoaded, loadError } = useLoadScript(mapOptions);

	// useEffect(() => {
	// 	if (loadError) {
	// 		console.error('Error loading maps:', loadError);
	// 	}
	// 	dispatch(setMapLoaded(isLoaded));
	// 	if (loadError) {
	// 		dispatch(setMapError(loadError));
	// 	}
	// }, [isLoaded, loadError, dispatch]);

	// useEffect(() => {
	// 	if (loadError) {
	// 		console.error('Error loading maps:', loadError);
	// 	}
	// 	dispatch(setMapLoaded(isLoaded));
	// 	if (loadError) {
	// 		dispatch(setMapError(loadError));
	// 	}
	// }, [isLoaded, loadError, dispatch]);

	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth <= 768));
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [dispatch]);

	// useEffect(() => {
	// 	dispatch(setMapLoaded(isLoaded));
	// 	if (loadError) {
	// 		dispatch(setMapError(loadError));
	// 	}
	// }, [isLoaded, loadError, dispatch]);

	return (
		<div className="flex flex-col h-screen">
			<div className="flex-shrink-0">
				{!isMobile && isAuthenticated && !fullscreenGallery && <Header />}
			</div>
			{/* <div className="flex-grow mt-20"> */}
			<div className="flex-grow mt-[58px]">
				{' '}
				{/* Adjust mt-20 as needed */}
				<Routes>
					<Route path="/welcome" element={<SplashScreen />} />
					<Route path="/signup" element={<SignUpForm />} />
					<Route path="/login" element={<Login />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/confirm-email" element={<ConfirmEmail />} />

					{/* Protected routes */}
					<Route element={<ProtectedRoute />}>
						{/* <Route path="/" element={<Hero />} /> */}
						<Route path="/" element={<Search />} />
						{/* <Route path="/listings/:id" element={<Listings />} /> */}
						<Route path="/listings/:id" element={<Listings2 />} />
						<Route path="/partner" element={<Partner />} />
						<Route path="/boards" element={<Boards />} />
						<Route path="/boards/:boardId" element={<Board />} />
						<Route path="/change-password" element={<ChangePassword />} />
						<Route path="/edit-profile" element={<EditProfile />} />
						<Route path="/onboarding" element={<Onboarding />} />
					</Route>
				</Routes>
			</div>
			{location.pathname !== '/search' ||
				(location.pathname !== '/search' && !isMobile && <Footer />)}
		</div>
	);
}
