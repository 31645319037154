import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';
import {
	Home,
	Search,
	ClipboardList,
	LayoutDashboard,
	Car,
	Wrench,
	Building2,
	FileText
} from 'lucide-react';
import AccordionSection from './AccordionSection'; // Adjust the import path as needed
import RoomDisplay from './RoomDisplay';
import TaxDisplay from './TaxDisplay';

const ListingAccordion = () => {
	const listingData = useSelector(selectSingleListingData);
	const [activeTab, setActiveTab] = useState('Property Details');
	const [expandedSections, setExpandedSections] = useState({
		keyFeatures: false,
		propertyDetails: false,
		listingDetails: false,
		interiorDetails: false,
		condoDetails: false,
		parkingArea: false,
		utilities: false,
		otherDetails: false
	});

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const toggleSection = (section) => {
		setExpandedSections((prev) => ({
			...prev,
			[section]: !prev[section]
		}));
	};

	const tabs = ['Property Details', 'Room Details', 'Tax History'];

	if (!listingData || !listingData.address) {
		return null;
	}

	const renderKeyFeatures = () => (
		<div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
			<div>
				<p className="text-gray-500">Property Type</p>
				<p className="font-medium">Condo Apt</p>
			</div>
			<div>
				<p className="text-gray-500">Tax</p>
				<p className="font-medium">$2,801/2024</p>
			</div>
			<div>
				<p className="text-gray-500">Maintenance Fee</p>
				<p className="font-medium">$401.14</p>
			</div>
			<div>
				<p className="text-gray-500">Size</p>
				<p className="font-medium">500-599 sqft</p>
			</div>
			<div>
				<p className="text-gray-500">Basement Info</p>
				<p className="font-medium">None</p>
			</div>
			<div>
				<p className="text-gray-500">Total Parking Spaces</p>
				<p className="font-medium">1 (0 Garage)</p>
			</div>
		</div>
	);

	return (
		<div className="flex flex-col gap-y-2 p-6 bg-white rounded-lg">
			<h1 className="text-2xl font-semibold">Features & Details</h1>
			<p className="text-md text-gray-400">
				Property details of{' '}
				{`${listingData.address.streetNumber} ${listingData.address.streetName} - ${listingData.address.city}`}
			</p>

			<div className="flex justify-between border-b border-gray-300 mt-4">
				<div className="flex">
					{tabs.map((tab) => (
						<button
							key={tab}
							onClick={() => handleTabClick(tab)}
							className={`
                px-6 py-2 
                mx-1
                -mb-[2px]
                text-md 
                font-light 
                transition-colors 
                duration-200
                border-b-2
                ${
					activeTab === tab
						? 'text-gray-700 bg-gray-100 border-red-500'
						: 'text-gray-400 hover:text-gray-700 border-transparent'
				}
              `}
						>
							{tab}
						</button>
					))}
				</div>
			</div>

			<div className="flex flex-col gap-y-2 mt-4">
				{activeTab === 'Property Details' && (
					<>
						<AccordionSection
							title="Key features"
							isExpanded={expandedSections.keyFeatures}
							onToggle={() => toggleSection('keyFeatures')}
							icon={Home}
						>
							{renderKeyFeatures()}
						</AccordionSection>

						<AccordionSection
							title="Property Details"
							isExpanded={expandedSections.propertyDetails}
							onToggle={() => toggleSection('propertyDetails')}
							icon={Search}
						/>

						<AccordionSection
							title="Listing Details"
							isExpanded={expandedSections.listingDetails}
							onToggle={() => toggleSection('listingDetails')}
							icon={ClipboardList}
						/>

						<AccordionSection
							title="Interior Details"
							isExpanded={expandedSections.interiorDetails}
							onToggle={() => toggleSection('interiorDetails')}
							icon={LayoutDashboard}
						/>

						<AccordionSection
							title="Condo Details"
							isExpanded={expandedSections.condoDetails}
							onToggle={() => toggleSection('condoDetails')}
							icon={Building2}
						/>

						<AccordionSection
							title="Parking Area"
							isExpanded={expandedSections.parkingArea}
							onToggle={() => toggleSection('parkingArea')}
							icon={Car}
						/>

						<AccordionSection
							title="Utilities"
							isExpanded={expandedSections.utilities}
							onToggle={() => toggleSection('utilities')}
							icon={Wrench}
						/>

						<AccordionSection
							title="Other Details"
							isExpanded={expandedSections.otherDetails}
							onToggle={() => toggleSection('otherDetails')}
							icon={FileText}
						/>
					</>
				)}
				{activeTab === 'Room Details' && (
					<>
						<div className="w-full">
							<RoomDisplay />
						</div>
					</>
				)}
				{activeTab === 'Tax History' && (
					<>
						<div className="w-full">
							<TaxDisplay />
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ListingAccordion;
