import axios from 'axios';
import { getEnvironmentConfig } from '../config/environments';

const envConfig = getEnvironmentConfig();
const DEBUG = process.env.NODE_ENV !== 'production';

const api = axios.create({
	baseURL: envConfig.baseUrl,
	timeout: envConfig.apiTimeout,
	headers: {
		'Content-Type': 'application/json'
	}
});

// Add error handling interceptor
api.interceptors.response.use(
	response => response,
	error => {
		if (DEBUG) {
			console.error('API Error:', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		return Promise.reject(error);
	}
);

// Add request interceptor for debugging
api.interceptors.request.use(request => {
	if (DEBUG) {
		console.log('API Request:', {
			url: request.url,
			baseURL: request.baseURL,
			method: request.method,
			headers: request.headers
		});
	}
	return request;
});

// export const login = async (credentials) => {
// 	const response = await api.post('/auth/login', credentials);
// 	return response.data.cognitoResponse;
// };

export const login = async (credentials) => {
	if (DEBUG) console.log('Login attempt initiated', { credentials });
	try {
		if (DEBUG) console.time('loginDuration');
		const response = await api.post('/auth/login', credentials);
		if (DEBUG) console.timeEnd('loginDuration');

		if (DEBUG) {
			console.log('Login successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data.cognitoResponse) {
			if (DEBUG) {
				console.log('Cognito response received', response.data, {
					IdToken: response.data.cognitoResponse.AuthenticationResult.IdToken
						? 'Present'
						: 'Missing',
					AccessToken: response.data.cognitoResponse.AuthenticationResult
						.AccessToken
						? 'Present'
						: 'Missing',
					RefreshToken: response.data.cognitoResponse.AuthenticationResult
						.RefreshToken
						? 'Present'
						: 'Missing'
				});
			}
		} else {
			if (DEBUG) console.warn('Cognito response missing from login data');
		}

		return response.data.cognitoResponse;
	} catch (error) {
		if (DEBUG) {
			console.error('Login failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

// export const getUserInfo = async (accessToken) => {
// 	const response = await api.get(`/auth/${accessToken}`);
// 	return response.data;
// };

export const getUserInfo = async (AccessToken) => {
	if (DEBUG)
		console.log(`getUserInfo attempt initiated ${envConfig.baseUrl}/auth/${AccessToken}`, {
			AccessToken: AccessToken ? 'Present' : 'Missing'
		});
	try {
		if (DEBUG) console.time('getUserInfoDuration');
		const response = await api.get(`/auth/${AccessToken}`);
		if (DEBUG) console.timeEnd('getUserInfoDuration');

		if (DEBUG) {
			console.log('getUserInfo successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('User info received', response.data);
			}
		} else {
			if (DEBUG) console.warn('User info missing from response data');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('getUserInfo failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const createClient = async (clientData) => {
	const response = await api.post('/auth/signup', clientData);
	console.log(response);
	return response.data;
};

export const refreshToken = async (refreshToken) => {
	const response = await api.post('/auth/refresh', { refreshToken });
	return response.data;
};

// export const fetchSavedListings = async (accessToken) => {
// 	const response = await api.get('/savedListings', {
// 		headers: { Authorization: `Bearer ${accessToken}` }
// 	});
// 	if (DEBUG) console.log('Response of FetchSavedListing', response);
// 	return response.data;
// };

export const fetchSavedListings = async (accessToken) => {
	if (DEBUG) {
		console.log(`fetchSavedListings attempt initiated ${envConfig.baseUrl}/savedListings`, {
			accessToken: accessToken ? 'Present' : 'Missing'
		});
	}

	try {
		if (DEBUG) console.time('fetchSavedListingsDuration');

		const response = await api.get('/savedListings', {
			headers: { Authorization: `Bearer ${accessToken}` }
		});

		if (DEBUG) console.timeEnd('fetchSavedListingsDuration');

		if (DEBUG) {
			console.log('fetchSavedListings API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('Saved listings received', {
					dataLength: response.data.length,
					firstFewListings: response.data.slice(0, 3)
				});
			}
		} else {
			if (DEBUG) console.warn('Saved listings missing from response data');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('fetchSavedListings failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const createSavedListing = async (accessToken, listingData, boardId) => {
	if (DEBUG) {
		console.log(
			`createSavedListing attempt initiated ${envConfig.baseUrl}/savedListings/${boardId}`,
			{
				accessToken: accessToken ? 'Present' : 'Missing',
				boardId: boardId,
				listingDataKeys: Object.keys(listingData)
			}
		);
	}

	try {
		if (DEBUG) console.time('createSavedListingDuration');

		const response = await api.post(`/savedListings/${boardId}`, listingData, {
			headers: { Authorization: `Bearer ${accessToken}` }
		});

		if (DEBUG) console.timeEnd('createSavedListingDuration');

		if (DEBUG) {
			console.log('createSavedListing API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('Saved listing created', {
					responseData: response.data
				});
			}
		} else {
			if (DEBUG) console.warn('Created listing data missing from response');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('createSavedListing failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const deleteSavedListing = async (accessToken, mlsNumber, boardId) => {
	await api.delete(`/savedListings/${mlsNumber}&${boardId}`, {
		headers: { Authorization: `Bearer ${accessToken}` }
	});
	return mlsNumber;
};

export const repliersUrl = (params = {}) => {
	const defaultParams = {
		listings: true,
		class: ['residential', 'condo'],
		operator: 'AND',
		resultsPerPage: 15,
		sortBy: 'listPriceDesc',
		status: 'A'
	};

	// Destructure and filter out image-related params from the incoming params
	const {
		imageSearchUrl,
		imageSearchFiles,
		// propertyTypes,
		classParam, // Rename to avoid conflict with reserved word
		keywords,
		...filteredParams
	} = params;

	const mergedParams = { ...defaultParams, ...filteredParams };

	// Filter out undefined, null, or empty string values
	const finalParams = Object.entries(mergedParams).reduce((acc, [key, value]) => {
		if (value !== undefined && value !== null && value !== '') {
			acc[key] = value;
		}
		return acc;
	}, {});

	// Create FormData object and append JSON stringified parameters
	let formData = new FormData();
	formData.append('params', JSON.stringify(finalParams));

	// Debugging block
	if (DEBUG) {
		console.log('finalParams:', finalParams);
		console.log('stringify finalParams:', JSON.stringify(finalParams));
		console.log('form data entries:');
		for (let pair of formData.entries()) {
			console.log(pair[0] + ': ' + pair[1]);
		}
	}

	// Append additional form data if provided
	if (imageSearchUrl) {
		formData.append('imageSearchUrl', imageSearchUrl);
	}

	if (imageSearchFiles && imageSearchFiles.length > 0) {
		imageSearchFiles.forEach((file) => {
			formData.append('imageSearchFiles', file, file.name);
		});
	}
	// Append keywords
	if (keywords && keywords.length > 0) {
		const keywordItems = keywords.map((keyword) => ({
			type: 'text',
			value: keyword,
			boost: 1
		}));
		formData.append('keywords', JSON.stringify(keywordItems));
	}

	// Configure the request options
	const options = {
		method: 'POST',
		url: envConfig.baseUrl + '/listings/all',
		data: formData
	};

	console.log(options);

	return options;
};

export const forgotPasswordVerficationCode = async (email) => {
	if (DEBUG) {
		console.log(
			`forgotPasswordVerficationCode attempt initiated ${envConfig.baseUrl}/auth/forgotPassword`
		);
	}
	try {
		if (DEBUG) console.time('forgotPasswordVerficationCodeDuration');
		const response = await api.post('/auth/forgotPassword', { email });

		if (DEBUG) console.timeEnd('forgotPasswordVerficationCodeDuration');

		if (DEBUG) {
			console.log('forgotPasswordVerficationCode API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('forgotPasswordVerficationCode Response', response.data);
			}
		} else {
			if (DEBUG) console.warn('forgotPasswordVerficationCode No Response data');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('forgotPasswordVerficationCode failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const resendVerficationCode = async (email) => {
	if (DEBUG) {
		console.log(
			`resendVerficationCode attempt initiated ${envConfig.baseUrl}/auth/resendVerification`
		);
	}
	try {
		if (DEBUG) console.time('resendVerficationCodeDuration');
		const response = await api.post('/auth/resendVerification', { email });

		if (DEBUG) console.timeEnd('resendVerficationCodeDuration');

		if (DEBUG) {
			console.log('resendVerficationCode API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('resendVerficationCode Response', response.data);
			}
		} else {
			if (DEBUG) console.warn('resendVerficationCode No Response data');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('resendVerficationCode failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

//auth/confirmSignup
export const verfifyEmail = async (credentials) => {
	if (DEBUG) {
		console.log(`verfifyEmail attempt initiated ${envConfig.baseUrl}/auth/confirmSignup`);
		console.log('Parameters:', credentials);
	}
	try {
		if (DEBUG) console.time('verfifyEmailDuration');
		const { email, verficationCode } = credentials;
		const response = await api.post('/auth/confirmSignup', {
			email,
			confirmationCode: verficationCode
		});

		if (DEBUG) console.timeEnd('verfifyEmailDuration');

		if (DEBUG) {
			console.log('verfifyEmail API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('verfifyEmail Response', response.data);
			}
		} else {
			if (DEBUG) console.warn('verfifyEmail No Response data');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('verfifyEmail failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const forgotPasswordChangePassword = async (newCredentials) => {
	if (DEBUG) {
		console.log(
			`forgotPasswordChangePassword attempt initiated ${envConfig.baseUrl}/auth/confirmForgotPassword`
		);
	}
	const { email, verficationCode, newPassword } = newCredentials;
	try {
		if (DEBUG) console.time('forgotPasswordChangePasswordDuration');
		if (DEBUG) console.log({ email, newPassword, confirmationCode: verficationCode });
		const response = await api.patch('/auth/confirmForgotPassword', {
			email,
			newPassword,
			confirmationCode: verficationCode
		});

		if (DEBUG) console.timeEnd('forgotPasswordChangePasswordDuration');

		if (DEBUG) {
			console.log(
				'forgotPasswordChangePassword API call successful',
				{
					status: response.status,
					statusText: response.statusText,
					headers: response.headers
				},
				response
			);
		}
	} catch (error) {
		if (DEBUG) {
			console.error('forgotPasswordChangePassword failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const fetchSingleListing = async (mlsNumber) => {
	if (DEBUG) console.log('MLS Number', mlsNumber);
	try {
		const response = await api.get(`/listings/listing/${mlsNumber}`);
		if (DEBUG) console.log('fetchSingleListing Response', response);
		return response;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const updateProfile = async (accessToken, profileData) => {
	// Dummy implementation
	console.log('Updating profile with token:', accessToken);
	console.log('Profile data:', profileData);
	return {
		data: {
			...profileData,
			updatedAt: new Date().toISOString()
		}
	};
};

export const deleteAccount = async (accessToken) => {
	// Dummy implementation
	console.log('Deleting account with token:', accessToken);
	return { success: true, message: 'Account deleted successfully' };
};

export const fetchBoards = async (accessToken) => {
	if (DEBUG) {
		console.log(`fetchBoards attempt initiated ${envConfig.baseUrl}/boards`, {
			accessToken: accessToken ? 'Present:' + accessToken : 'Missing'
		});
	}

	try {
		if (DEBUG) console.time('fetchBoardsDuration');

		const response = await api.get('/boards', {
			headers: { Authorization: `Bearer ${accessToken}` }
		});

		if (DEBUG) console.timeEnd('fetchBoardsDuration');

		if (DEBUG) {
			console.log('fetchBoards API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('Boards received', {
					dataLength: response.data.length,
					firstFewBoards: response.data.slice(0, 3)
				});
			}
		} else {
			if (DEBUG) console.warn('Boards missing from response data');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('fetchBoards failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const fetchBoardsWithListings = async (accessToken) => {
	const response = await axios.get(`${envConfig.baseUrl}/boards/with-listings`, {
		headers: { Authorization: `Bearer ${accessToken}` }
	});
	return response.data;
};

export const createBoard = async (accessToken, boardData) => {
	if (DEBUG) {
		console.log(`createBoard attempt initiated ${envConfig.baseUrl}/boards`, {
			accessToken: accessToken ? 'Present' : 'Missing',
			boardData
		});
	}

	try {
		if (DEBUG) console.time('createBoardDuration');

		const response = await api.post('/boards', boardData, {
			headers: { Authorization: `Bearer ${accessToken}` }
		});

		if (DEBUG) console.timeEnd('createBoardDuration');

		if (DEBUG) {
			console.log('createBoard API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('New board created', response.data);
			}
		} else {
			if (DEBUG) console.warn('Created board data missing from response');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('createBoard failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const updateBoard = async (accessToken, boardId, boardData) => {
	if (DEBUG) {
		console.log(`updateBoard attempt initiated ${envConfig.baseUrl}/boards/${boardId}`, {
			accessToken: accessToken ? 'Present' : 'Missing',
			boardId,
			boardData
		});
	}

	try {
		if (DEBUG) console.time('updateBoardDuration');

		const response = await api.put(`/boards/${boardId}`, boardData, {
			headers: { Authorization: `Bearer ${accessToken}` }
		});

		if (DEBUG) console.timeEnd('updateBoardDuration');

		if (DEBUG) {
			console.log('updateBoard API call successful', {
				status: response.status,
				statusText: response.statusText,
				headers: response.headers
			});
		}

		if (response.data) {
			if (DEBUG) {
				console.log('Board updated', response.data);
			}
		} else {
			if (DEBUG) console.warn('Updated board data missing from response');
		}

		return response.data;
	} catch (error) {
		if (DEBUG) {
			console.error('updateBoard failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

export const deleteBoard = async (accessToken, boardId) => {
	if (DEBUG) {
		console.log(`deleteBoard attempt initiated ${envConfig.baseUrl}/boards/${boardId}`, {
			accessToken: accessToken ? 'Present' : 'Missing',
			boardId
		});
	}

	try {
		if (DEBUG) console.time('deleteBoardDuration');

		await api.delete(`/boards/${boardId}`, {
			headers: { Authorization: `Bearer ${accessToken}` }
		});

		if (DEBUG) console.timeEnd('deleteBoardDuration');

		if (DEBUG) {
			console.log('deleteBoard API call successful');
		}

		return boardId;
	} catch (error) {
		if (DEBUG) {
			console.error('deleteBoard failed', {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data
			});
		}
		throw error;
	}
};

