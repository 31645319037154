import React, { useState, useEffect, useCallback, useRef } from 'react';
import SearchAndFilterPanel from '../../components/SearchAndFilterPanel.jsx';
import ResultsHeader from '../../components/ResultsHeader.jsx';
import Card from '../../components/Card/CardOld.jsx';
import { fetchListings } from '../../features/listings/listingsSlice.js';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectListMapActive,
	selectImageSearchModalToggle,
	selectBoardModalToggle
} from '../../features/active/activeSlice';
import {
	selectBoardsStatus,
	fetchBoardsWithListingsAsync
} from '../../features/boards/boardsSlice';
import { repliersUrl } from '../../utils/api.js';
import MapComponent from '../../components/MapComponent/MapComponent.jsx';
import ImageSearchModal from '../../components/ImageSearchModal/ImageSearchModal.jsx';
import Card2 from '../../components/Card/Card2.jsx';
import BoardsModal from '../../components/BoardsModal.jsx';
import FilterPanel from '../../components/Filter/FilterPanel.jsx';
const DEBUG = process.env.REACT_APP_DEBUG === 'true';

export default function Search() {
	const { items, pageData, currentPage, hasMore, status, error } = useSelector(
		(state) => state.listings
	);
	const searchParams = useSelector((state) => state.searchParams.active);
	const listMapActive = useSelector(selectListMapActive);
	const imageSearchModalActive = useSelector(selectImageSearchModalToggle);
	const boardModalActive = useSelector(selectBoardModalToggle);
	const searchContentRef = useRef(null);
	const boardsStatus = useSelector(selectBoardsStatus);
	const dispatch = useDispatch();

	const loadMoreListings = useCallback(() => {
		if (DEBUG)
			console.log(
				'loadMoreListings called. hasMore:',
				hasMore,
				'status:',
				status,
				'currentPage:',
				currentPage
			);
		if (hasMore && status !== 'loading') {
			if (DEBUG)
				console.log(
					'Fetching more listings. Current Page:',
					currentPage,
					'Next Page:',
					currentPage + 1
				);
			const options = repliersUrl({ ...searchParams, pageNum: currentPage + 1 });
			if (DEBUG) console.log('Fetch Options:', options);
			dispatch(fetchListings(options));
		}
	}, [hasMore, status, currentPage, searchParams, dispatch]);

	useEffect(() => {
		if (DEBUG) console.log('Initial fetch effect. searchParams:', searchParams);
		const options = repliersUrl({ ...searchParams, pageNum: 1 });
		if (DEBUG) console.log('Initial Fetch Options:', options);
		dispatch(fetchListings(options));
	}, [searchParams, dispatch]);

	useEffect(() => {
		if (boardsStatus === 'idle') {
			// dispatch(fetchBoardsAsync());
			dispatch(fetchBoardsWithListingsAsync());
		}
	}, [boardsStatus, dispatch]);

	useEffect(() => {
		const handleScroll = () => {
			if (searchContentRef.current) {
				const { scrollTop, scrollHeight, clientHeight } =
					searchContentRef.current;
				if (scrollHeight - scrollTop <= clientHeight + 1) {
					if (DEBUG)
						console.log(
							'Scroll threshold reached. Calling loadMoreListings.'
						);
					loadMoreListings();
				}
			}
		};

		const currentSearchContent = searchContentRef.current;
		if (currentSearchContent) {
			if (DEBUG) console.log('Adding scroll event listener');
			currentSearchContent.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (currentSearchContent) {
				if (DEBUG) console.log('Removing scroll event listener');
				currentSearchContent.removeEventListener('scroll', handleScroll);
			}
		};
	}, [loadMoreListings]);

	useEffect(() => {
		dispatch(fetchBoardsWithListingsAsync());
	}, []);

	if (status === 'loading' && items.length === 0) {
		return <div>Loading...</div>;
	}

	if (status === 'failed') {
		return <div>Error: {error}</div>;
	}

	return (
		<div className="flex flex-col h-screen z-40">
			{/* {imageSearchModalActive && <ImageSearchModal />} */}
			{/* {boardModalActive && <div className="w-9 h-9 bg-green-500">Hello</div>} */}
			{boardModalActive && <BoardsModal />}
			<div className="flex-grow overflow-y-auto h-full" ref={searchContentRef}>
				{/* <FilterPanel /> */}

				{listMapActive === 'List' && (
					// <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 py-5 px-52 w-full">
					// <div className="grid grid-cols-[repeat(auto-fill,minmax(450px,1fr))] gap-5 py-5 px-52 w-full">
					// <div className="flex justify-center mx-auto">
					// 	<div className="grid grid-cols-[repeat(auto-fit,450px)] gap-5 py-5 w-full">
					// 		{items.map((element, index) => (
					// 			<Card2
					// 				key={element.mlsNumber || index}
					// 				data={element}
					// 				index={index}
					// 				isMap={false}
					// 			/>
					// 		))}
					// 		{status === 'loading' && <div>Loading more...</div>}
					// 		{!hasMore && <div>No more listings to load.</div>}
					// 	</div>
					// </div>
					<div className="px-24 flex justify-center">
						<div className="w-full ">
							<div className="flex">
								<FilterPanel />
							</div>
							{/* <div className="grid grid-cols-[repeat(auto-fit,450px)] gap-5 py-5 justify-center"> */}
							<div className="grid grid-cols-[repeat(auto-fit,minmax(min(400px,100%),1fr))] gap-5 py-5 justify-center">
								{/* <div className="grid grid-cols-[repeat(auto-fit,minmax(400px,450px))] gap-5 py-5 justify-center"> */}
								{items.map((element, index) => (
									<Card2
										key={element.mlsNumber || index}
										data={element}
										index={index}
										isMap={false}
									/>
								))}
								{status === 'loading' && <div>Loading more...</div>}
								{!hasMore && <div>No more listings to load.</div>}
							</div>
						</div>
					</div>
				)}
				{listMapActive === 'Map' && (
					<div className="flex w-full h-[calc(100vh-80px)]">
						<div className="relative w-[70%] h-full">
							<MapComponent
								props={{
									map: {
										latitude: 43.6788527,
										longitude: -79.4067778,
										point: 'POINT (-79.4067778 43.6788527)'
									},
									size: 'full'
								}}
							/>
						</div>
						<div className="w-[30%] h-full overflow-y-auto p-2.5 box-border">
							<div className="grid grid-cols-1 gap-5">
								{items.map((element, index) => (
									<Card2
										key={element.mlsNumber || index}
										data={element}
										index={index}
										isMapList={true}
									/>
								))}
								{status === 'loading' && <div>Loading more...</div>}
								{!hasMore && <div>No more listings to load.</div>}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
