import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from '@vis.gl/react-google-maps';
import MapMarker from './MapMarker';
import {
	setMapCenter,
	setMapZoom,
	setMapBounds,
	selectMapCenter,
	selectMapZoom
} from '../../features/map/mapSlice';

import {
	selectSingleListing,
	selectSingleListingData
} from '../../features/active/activeSlice';

const MapComponent = ({ props }) => {
	const dispatch = useDispatch();
	const listings = useSelector((state) => state.listings.items);
	const center = useSelector(selectMapCenter);
	const zoom = useSelector(selectMapZoom);
	const isSingleListing = useSelector(selectSingleListing);
	const singleListingData = useSelector(selectSingleListingData);

	const mapOptions = {
		disableDefaultUI: false,
		gestureHandling: 'cooperative',
		streetViewControl: false,
		mapTypeControl: false,
		zoomControl: true,
		fullscreenControl: false,
		clickableIcons: false,
		mapId: '8f348c95eff19862', // Temporary development MapID
		styles: [
			{
				featureType: 'poi',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }]
			}
		]
	};

	const onBoundsChanged = useCallback(
		(evt) => {
			if (evt.detail && evt.detail.bounds) {
				const { north, south, east, west } = evt.detail.bounds;
				dispatch(
					setMapBounds({
						ne: { lat: north, lng: east },
						sw: { lat: south, lng: west }
					})
				);
			}
		},
		[dispatch]
	);

	const onCenterChanged = useCallback(
		(evt) => {
			if (evt.detail && evt.detail.center) {
				const { lat, lng } = evt.detail.center;
				dispatch(setMapCenter({ lat, lng }));
			}
		},
		[dispatch]
	);

	const onZoomChanged = useCallback(
		(evt) => {
			if (evt.detail) {
				dispatch(setMapZoom(evt.detail.zoom));
			}
		},
		[dispatch]
	);

	const containerStyle = props.size === 'full' ? 'absolute inset-0' : 'h-full w-full';

	// Filter out listings with invalid coordinates
	let validListings;
	if (!isSingleListing) {
		validListings = listings?.filter((listing) => {
			const lat = parseFloat(listing.map.latitude);
			const lng = parseFloat(listing.map.longitude);
			// console.log('lat', lat, 'lng', lng, listing);
			// console.log(!isNaN(lat) && !isNaN(lng));
			return !isNaN(lat) && !isNaN(lng);
		});
	}

	if (isSingleListing) {
		validListings = [singleListingData];
	}

	return (
		<div className={containerStyle}>
			<Map
				className="w-full h-full rounded-lg"
				defaultCenter={{
					lat: parseFloat(props.map.latitude) || 43.6788527,
					lng: parseFloat(props.map.longitude) || -79.4067778
				}}
				defaultZoom={12}
				options={mapOptions}
				onBoundsChanged={onBoundsChanged}
				onCenterChanged={onCenterChanged}
				onZoomChanged={onZoomChanged}
			>
				{validListings?.map((listing) => (
					<MapMarker key={listing.mlsNumber} listing={listing} />
				))}
			</Map>
		</div>
	);
};

export default MapComponent;
// import React from 'react';
// import { Map } from '@vis.gl/react-google-maps';

// const MapComponent = ({ props }) => {
// 	const mapOptions = {
// 		disableDefaultUI: false,
// 		gestureHandling: 'cooperative',
// 		streetViewControl: false,
// 		mapTypeControl: false,
// 		zoomControl: true,
// 		fullscreenControl: false,
// 		clickableIcons: false,
// 		styles: [
// 			{
// 				featureType: 'poi',
// 				elementType: 'labels',
// 				stylers: [{ visibility: 'off' }]
// 			}
// 		]
// 	};

// 	const containerStyle = props.size === 'full' ? 'absolute inset-0' : 'h-full w-full';

// 	return (
// 		<div className={containerStyle}>
// 			{props.map && (
// 				<Map
// 					className="w-full h-full rounded-lg"
// 					defaultCenter={{
// 						lat: parseFloat(props.map.latitude),
// 						lng: parseFloat(props.map.longitude)
// 					}}
// 					defaultZoom={12}
// 					options={mapOptions}
// 					// mapId="YOUR_MAP_ID" // Optional: Add if you have a custom map style
// 				/>
// 			)}
// 		</div>
// 	);
// };

// export default MapComponent;
