import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	toggleImageSearchModal,
	selectCategoryActive,
	resetAllModals
} from '../../features/active/activeSlice';
import {
	uploadImages,
	applySearchParams,
	setImageUrl,
	selectDraftParams,
	selectImageUploadStatus,
	selectImageUploadError
} from '../../features/searchParams/searchParamsSlice';
import { ReactComponent as Sparkle } from '../../icons/sparkle.svg';
import { ReactComponent as FileImport } from '../../icons/fileImport.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as ExportIcon } from '../../icons/export.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import RoomCategoryGrid from '../RoomsGrid/RoomsGrid';

const ImageSearchModal = () => {
	const [previewUrl, setPreviewUrl] = useState(null);
	const [previewUrls, setPreviewUrls] = useState([]);

	const fileInputRef = useRef(null);
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);
	const uploadStatus = useSelector(selectImageUploadStatus);
	const uploadError = useSelector(selectImageUploadError);
	const catergoryActive = useSelector(selectCategoryActive);

	const handleImageChange = (event) => {
		const files = Array.from(event.target.files);
		const imageFiles = files.filter((file) => file.type.startsWith('image/'));

		if (imageFiles.length > 0) {
			// Dispatch the array of File objects to Redux
			dispatch(uploadImages(imageFiles));

			// Generate previews for each image
			const readers = imageFiles.map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(file);
				});
			});

			Promise.all(readers)
				.then((results) => {
					setPreviewUrls((prevUrls) => [...prevUrls, ...results]); // Update state with all preview URLs
				})
				.catch((error) => {
					console.error('Error reading files:', error);
				});
		}
	};

	const handleUrlSubmit = (event) => {
		event.preventDefault();
		if (draftParams.imageSearchUrl.trim()) {
			dispatch(setImageUrl(draftParams.imageSearchUrl.trim()));
			dispatch(applySearchParams());
			dispatch(toggleImageSearchModal());
		} else {
			dispatch(applySearchParams());
			dispatch(toggleImageSearchModal());
		}
	};

	const handleImageUploadSubmit = (event) => {
		event.preventDefault();
		dispatch(toggleImageSearchModal());
		dispatch(applySearchParams());
	};

	const closeModal = () => {
		dispatch(toggleImageSearchModal());
		setPreviewUrls([]);
	};

	return (
		// <div className="fixed inset-0 z-50 flex items-center justify-center">
		// 	<div
		// 		className="absolute inset-0 bg-gray-900 opacity-50 backdrop-blur-sm"
		// 		onClick={closeModal}
		// 	></div>
		<div className="flex flex-col justify-center items-center bg-[#F6F6F6] rounded-[36px] p-8 z-50  max-h-[90vh] overflow-y-auto">
			{/* <div>
					<div className="flex flex-nowrap justify-center">
						<p className="text-[60px] mb-4 font-medium text-pinhous-grey">
							AI Image Search
						</p>
						{/* <Cancel onClick={() => dispatch(toggleImageSearchModal())} /> */}
			{/*<Sparkle />
					</div>
					<div className="flex justify-center">
						<p className="text-pinhous-light-grey text-[35px]">
							Upload or Select an Image to Search
						</p>
					</div>
				</div> */}

			<div className="flex flex-nowrap w-4/6 h-full justify-between gap-x-4 mt-9 overflow-y-auto">
				<div className=" flex flex-col justify-start items-center  w-full h-full   gap-8 ">
					{!catergoryActive && (
						<div className="flex flex-col w-full h-full justify-center items-center bg-[#FAFAFA]  border-pinhous-light-grey rounded-[9px] border-[1.5px] pb-9">
							<div className="flex justify-center items-center ">
								{previewUrls.length > 0 && (
									<div className="image-previews grid grid-cols-2 gap-4">
										{previewUrls.map((url, index) => (
											<img
												key={index}
												src={url}
												alt={`Selected ${index + 1}`}
												className="max-w-full max-h-[150px] object-contain rounded"
											/>
										))}
									</div>
								)}

								{draftParams.imageSearchFile ||
								draftParams.imageSearchUrl ? (
									<div className="flex flex-col items-center p-4">
										<img
											src={
												draftParams.imageSearchFile ||
												draftParams.imageSearchUrl
											}
											alt="Selected image"
											className="max-w-full max-h-[200px] object-contain rounded"
										/>
										<button
											className="mt-4 px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
											onClick={() => {
												dispatch(setImageUrl(''));
												// Add any additional reset actions needed
											}}
										>
											Remove Image
										</button>
									</div>
								) : (
									<>
										<input
											type="file"
											accept="image/*"
											multiple
											onChange={handleImageChange}
											className="hidden"
											ref={fileInputRef}
										/>
										<div
											className="flex pt-9 justify-center align-middle"
											onClick={() => fileInputRef.current.click()}
										>
											<FileImport />
											<div className="flex flex-col pt-2 pl-4">
												<p className="text-wrap">
													Drag and Drop{' '}
												</p>
												<p>
													or{' '}
													<span className="underline">
														chose a file
													</span>
												</p>
											</div>
										</div>
									</>
								)}
							</div>
							{/* <p>or</p> */}
							<div className="flex w-full flex-col items-center mt-8">
								<div className="flex items-center  justify-center border rounded-full bg-white">
									<span className="px-3">
										<LinkIcon className="w-5 h-5" />
									</span>

									<input
										type="url"
										value={draftParams.imageSearchUrl}
										onChange={(e) =>
											dispatch(setImageUrl(e.target.value))
										}
										placeholder="Image URL"
										className="flex-1 pl-1 py-2 border-none outline-none bg-transparent" // Removed rounded-full, added bg-transparent
									/>
									<span
										className="px-3"
										onClick={(e) => {
											handleUrlSubmit(e);
											dispatch(resetAllModals());
										}}
									>
										<SearchIcon className="w-5 h-5" />
									</span>
								</div>

								{/* <div className="flex justify-between w-[90%] gap-x-2 mt-2">
								<button className="w-full flex items-center justify-center bg-[#FFFFFF] text-black px-4 py-2 rounded-lg hover:bg-pinhous-light-grey">
									<ExportIcon className="w-5 h-5 mr-2" />
									My Uploads
								</button>
								<button className="w-full flex items-center justify-center bg-[#FFFFFF] text-black px-4 py-2 rounded-lg hover:bg-pinhous-light-grey">
									<AiOutlineHeart className="w-5 h-5 mr-2" />
									Saved Images
								</button>
							</div> */}
								{/* <div className="flex justify-between w-[90%] gap-x-2 mt-10">
								<button
									className="w-full flex items-center justify-center bg-[#FFFFFF] text-black px-4 py-2 rounded-lg hover:bg-pinhous-red"
									onClick={handleUrlSubmit}
								>
									<SearchIcon className="w-5 h-5 mr-2" />
									Search
								</button>
							</div> */}
							</div>
						</div>
					)}
					<div className="flex max-h-full bg-[#FAFAFA] w-full h-full border-pinhous-light-grey rounded-[9px]">
						<RoomCategoryGrid />
					</div>
				</div>
			</div>
			{/* </div> */}
		</div>
	);
};

export default ImageSearchModal;

// import React, { useState, useRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { toggleImageSearchModal } from '../../features/active/activeSlice';
// import {
// 	updateDraftSearchParams,
// 	applySearchParams,
// 	resetSearchParams,
// 	selectActiveParams,
// 	selectDraftParams
// } from '../../features/searchParams/searchParamsSlice';
// import { ReactComponent as Cancel } from '../../icons/cancel.svg';

// const ImageSearchModal = () => {
// 	const [selectedImage, setSelectedImage] = useState(null);
// 	const fileInputRef = useRef(null);
// 	const [imageUrl, setImageUrl] = useState('');
// 	const dispatch = useDispatch();

// 	const handleImageChange = (event) => {
// 		const file = event.target.files[0];
// 		if (file && file.type.substr(0, 5) === 'image') {
// 			const reader = new FileReader();
// 			reader.onloadend = () => {
// 				setSelectedImage(reader.result);
// 				dispatch(
// 					updateDraftSearchParams({
// 						...selectDraftParams,
// 						imageSearchFile: reader.result
// 					})
// 				);
// 			};
// 			reader.readAsDataURL(file);
// 		} else {
// 			setSelectedImage(null);
// 		}
// 	};

// 	const handleUrlSubmit = (event) => {
// 		event.preventDefault();
// 		if (imageUrl.trim()) {
// 			setSelectedImage(imageUrl);
// 			dispatch(
// 				updateDraftSearchParams({
// 					...selectDraftParams,
// 					imageSearchUrl: imageUrl
// 				})
// 			);
// 		}
// 	};

// 	const handleImageUploadSubmit = (event) => {
// 		event.preventDefault();
// 		dispatch(toggleImageSearchModal());
// 		dispatch(applySearchParams());
// 	};

// 	return (
// 		<div className="fixed inset-0 z-50 flex items-center justify-center">
// 			<div
// 				className="absolute inset-0 bg-gray-900 opacity-50 backdrop-blur-sm"
// 				onClick={() => dispatch(toggleImageSearchModal())}
// 			></div>
// 			<div className="bg-white rounded-lg p-8 z-50 max-w-md w-full">
// 				<div className="flex flex-nowrap justify-between">
// 					<h2 className="text-2xl font-bold mb-4">Select Image</h2>
// 					<Cancel onClick={() => dispatch(toggleImageSearchModal())} />
// 				</div>
// 				<div className="mb-4">
// 					<form onSubmit={handleUrlSubmit} className="flex">
// 						<input
// 							type="url"
// 							value={imageUrl}
// 							onChange={(e) => setImageUrl(e.target.value)}
// 							placeholder="Enter image URL"
// 							className="flex-grow border rounded-l px-4 py-2"
// 						/>
// 						<button
// 							type="submit"
// 							className="bg-pinhous-blue hover:bg-pinhous-blue/80 text-white font-bold py-2 px-4 rounded-r"
// 						>
// 							Set URL
// 						</button>
// 					</form>
// 				</div>
// 				<div className="mt-6 flex items-center justify-between">
// 					<hr className="w-full border-[#000]" />
// 					<span className="px-2 text-gray-500 bg-white">or</span>
// 					<hr className="w-full border-[#000]" />
// 				</div>
// 				<div className="w-full mt-6 flex flex-col align-bottom  ">
// 					{selectedImage && (
// 						<div className="flex flex-col space-y-2">
// 							<img
// 								src={selectedImage}
// 								alt="Selected"
// 								className="max-w-full h-auto rounded"
// 							/>
// 							<button
// 								onClick={handleImageUploadSubmit}
// 								className="bg-pinhous-blue hover:bg-pinhous-blue/80 text-white font-bold py-2 px-4 rounded"
// 							>
// 								Search
// 							</button>
// 						</div>
// 					)}

// 					<input
// 						type="file"
// 						accept="image/*"
// 						onChange={handleImageChange}
// 						className="hidden"
// 						ref={fileInputRef}
// 					/>
// 					{!selectedImage && (
// 						<button
// 							onClick={() => fileInputRef.current.click()}
// 							className="bg-pinhous-blue hover:bg-pinhous-blue/80 text-white font-bold py-2 px-4 rounded mb-4"
// 						>
// 							Upload Image
// 						</button>
// 					)}
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default ImageSearchModal;
