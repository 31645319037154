import React, { useState } from 'react';
import { MapPinned } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as AiSearchIcon } from '../../icons/ai_search.svg';
import { ReactComponent as MapViewIcon } from '../../icons/map_view.svg';
import ImageSlider from '../../components/Slider/ImageSlider.jsx';

import {
	selectSingleListingData,
	setFullscreenGallery,
	setFullscreenGalleryIndex,
	setListMap
} from '../../features/active/activeSlice';

const ListingImages = () => {
	const dispatch = useDispatch();
	const { singleListingData } = useSelector((state) => state.active);
	const listingData = useSelector(selectSingleListingData);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const handleAiSearch = (e) => {
		e.stopPropagation();
		// if (singleListingData?.images?.[currentImageIndex]) {
		// 	const imageUrl = `https://cdn.repliers.io/${singleListingData.images[currentImageIndex]}`;
		// 	// Handle AI search functionality here
		// }
		alert('coming soon');
	};

	if (!listingData || !listingData.address) {
		return <div>loading...</div>;
	}
	return (
		<>
			<div className="flex px-16 mt-4 gap-2 h-[700px] mb-4">
				<div className="w-[55%] relative">
					{/* For Sale Label */}
					<div className="absolute top-4 left-4 z-20">
						<div className="bg-white/50 rounded-full px-4 py-1 flex items-center justify-center border border-black">
							<span className="text-sm font-medium">For Sale</span>
						</div>
					</div>

					{/* AI Search Button */}
					<div className="absolute top-4 right-4 z-20">
						<button
							onClick={handleAiSearch}
							className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
						>
							<AiSearchIcon className="w-6 h-6" />
						</button>
					</div>

					{/* Street View and Map View Buttons */}
					<div className="absolute bottom-4 left-4 z-20 flex gap-2">
						<button
							className="bg-black/50 text-white rounded-full px-4 py-2 flex items-center gap-2 hover:bg-black/60 transition-colors"
							onClick={() => alert('coming soon')}
						>
							<span className="text-sm flex items-center gap-1 whitespace-nowrap">
								<MapPinned
									className="w-4 h-4"
									onClick={() => alert('Coming Soon')}
								/>{' '}
								Street View
							</span>
						</button>
						<button
							className="bg-black/50 text-white rounded-full px-4 py-2 flex items-center gap-2 hover:bg-black/60 transition-colors"
							onClick={() => dispatch(setListMap('Map'))}
						>
							<span className="text-sm flex items-center gap-1 whitespace-nowrap">
								<MapViewIcon className="w-4 h-4" /> Map View
							</span>
						</button>
					</div>

					<ImageSlider
						images={singleListingData.images}
						currentIndex={currentImageIndex}
						setCurrentIndex={setCurrentImageIndex}
						showControls={true}
					/>
				</div>
				<div className="flex flex-col w-[45%] gap-2">
					<div className="flex flex-row h-1/2 gap-2">
						<div className="relative w-1/2">
							{/* For Sale Label */}
							{/* <div className="absolute top-4 left-4 z-20">
								<div className="bg-white/50 rounded-full px-4 py-1 flex items-center justify-center border border-black">
									<span className="text-sm font-medium">For Sale</span>
								</div>
							</div> */}

							{/* AI Search Button */}
							<div className="absolute top-4 right-4 z-20">
								<button
									onClick={handleAiSearch}
									className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
								>
									<AiSearchIcon className="w-6 h-6" />
								</button>
							</div>

							<ImageSlider
								images={singleListingData.images}
								currentIndex={18}
								setCurrentIndex={setCurrentImageIndex}
								showControls={false}
							/>
						</div>
						<div className="relative w-1/2">
							{/* AI Search Button */}
							<div className="absolute top-4 right-4 z-20">
								<button
									onClick={handleAiSearch}
									className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
								>
									<AiSearchIcon className="w-6 h-6" />
								</button>
							</div>

							<ImageSlider
								images={singleListingData.images}
								currentIndex={14}
								setCurrentIndex={setCurrentImageIndex}
								showControls={false}
							/>
						</div>
					</div>
					<div className="flex flex-row h-1/2 gap-2">
						<div className="relative w-1/2">
							{/* AI Search Button */}
							<div className="absolute top-4 right-4 z-20">
								<button
									onClick={handleAiSearch}
									className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
								>
									<AiSearchIcon className="w-6 h-6" />
								</button>
							</div>

							<ImageSlider
								images={singleListingData.images}
								currentIndex={6}
								setCurrentIndex={setCurrentImageIndex}
								showControls={false}
							/>
						</div>
						<div className="relative w-1/2">
							{/* AI Search Button */}
							<div className="absolute top-4 right-4 z-20">
								<button
									onClick={handleAiSearch}
									className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
								>
									<AiSearchIcon className="w-6 h-6" />
								</button>
							</div>
							<div className="absolute bottom-4 right-4 z-20">
								<button
									className="bg-black/50 text-white rounded-full px-4 py-2 flex items-center gap-2 hover:bg-black/60 transition-colors"
									onClick={() => {
										dispatch(setFullscreenGallery(true));
										dispatch(setFullscreenGalleryIndex(0));
									}}
									type="button"
								>
									<span className="text-sm flex items-center gap-1 whitespace-nowrap">
										See all {singleListingData.images.length} Photos
									</span>
								</button>
							</div>

							<ImageSlider
								images={singleListingData.images}
								currentIndex={10}
								setCurrentIndex={setCurrentImageIndex}
								showControls={false}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ListingImages;
