import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateDraftSearchParams,
	applySearchParams,
	selectDraftParams
} from '../../features/searchParams/searchParamsSlice';

const FilterButton = ({ filterType }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const dispatch = useDispatch();
	const draftParams = useSelector(selectDraftParams);

	const getDisplayText = () => {
		if (filterType === 'Sale') {
			return draftParams.listingType === 'Lease' ? 'For Lease' : 'For Sale';
		} else if (filterType === 'Price') {
			return '$ Price';
		} else {
			return 'Huh?!';
		}
	};

	const handleSelection = (listingType) => {
		dispatch(updateDraftSearchParams({ listingType }));
		setIsOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className={`
                    flex items-center 
                    justify-between 
                    px-3 py-2 
                    border border-gray-300 
                    rounded-md 
                    shadow-sm 
                    ${
						isOpen
							? 'bg-black text-white hover:bg-gray-900'
							: 'bg-white text-black hover:bg-gray-50'
					}
                `}
			>
				<span>{getDisplayText()}</span>
				<svg
					className={`w-5 h-5 ml-2 transition-transform ${
						isOpen ? 'rotate-180' : ''
					}`}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</button>

			{isOpen && (
				<div className="absolute z-[100] w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
					<div className="py-1">
						<button
							className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:outline-none"
							onClick={() => handleSelection('Sale')}
						>
							For Sale
						</button>
						<button
							className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:outline-none"
							onClick={() => handleSelection('Lease')}
						>
							For Lease
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default FilterButton;
