import React from 'react';
import {
	MapPin,
	Clock,
	Bed,
	Bath,
	Building2,
	Car,
	Compass,
	Coins,
	Wallet
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';
import { getDaysAgo, formatPrice } from '../../utils/date';

const ListingDetails = () => {
	const listingData = useSelector(selectSingleListingData);
	console.log(listingData);

	if (!listingData || !listingData.address) {
		return <div>No listing items yet</div>;
	}
	return (
		<div className="p-6 bg-white rounded-lg">
			{/* Address and Property Type */}
			<div className="flex justify-between items-start mb-4">
				<div className="flex flex-col">
					<div className="flex items-start justify-start">
						<MapPin className="flex w-6 h-6 mt-1 mx-1 self-baseline justify-self-center" />
						<div>
							<h1 className="text-2xl font-semibold">
								{listingData.address.streetNumber +
									' ' +
									listingData.address.streetName}
							</h1>
						</div>
					</div>
					<div className="flex justify-start items-center text-gray-600 mt-2">
						<div className="flex justify-start items-center">
							<div className="w-8">
								<Clock className="w-4 h-4 mx-auto" />
							</div>
							<span className="mr-1">
								Listed on site {getDaysAgo(listingData.listDate)}
							</span>
						</div>
						<span>
							- {listingData.address.neighborhood} {' - '}
							{listingData.address.city}, {listingData.address.state}{' '}
						</span>
					</div>
				</div>
				<div className="text-right">
					<div className="text-pinhous-red text-2xl">
						{formatPrice(listingData.listPrice)}
					</div>
					<div className="flex items-baseline gap-2">
						<div className="text-lg">{listingData.details.propertyType}</div>
						<div className="text-gray-600">{listingData.details.style}</div>
					</div>
					<div className="text-gray-600">MLS® #{listingData.mlsNumber}</div>
				</div>
			</div>

			{/* Property Details Grid */}
			<div className="grid grid-cols-3 gap-6 mt-8">
				{/* Bedrooms */}
				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<Bed className="w-6 h-6 mr-4" />
					<div>
						<div className="text-gray-600 text-sm">Bedrooms</div>
						<div className="font-medium">
							{parseInt(listingData.details.numBedrooms || 0) +
								parseInt(listingData.details.numBedroomsPlus || 0)}{' '}
							Beds
						</div>
					</div>
				</div>
				{/* Bathrooms */}
				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<Bath className="w-6 h-6 mr-4" />
					<div>
						<div className="text-gray-600 text-sm">Bathrooms</div>
						<div className="font-medium">
							{parseInt(listingData.details.numBathrooms || 0) +
								parseInt(listingData.details.numBathroomsPlus || 0)}
							{listingData.details.numBathroomsHalf &&
							parseInt(listingData.details.numBathroomsHalf) > 0
								? ` Full, ${listingData.details.numBathroomsHalf} Half`
								: ' Baths'}
						</div>
					</div>
				</div>

				{/* Size */}
				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<Building2 className="w-6 h-6 mr-4" />
					<div>
						<div className="text-gray-600 text-sm">Size</div>
						<div className="font-medium">
							{listingData.details.sqft
								? `${listingData.details.sqft} sqft`
								: listingData.details.sqftRange
								? listingData.details.sqftRange
								: 'Not Available'}
						</div>
					</div>
				</div>

				{/* Parking */}
				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<Car className="w-6 h-6 mr-4" />
					<div>
						<div className="text-gray-600 text-sm">Garage Spaces</div>
						<div className="flex items-baseline gap-2">
							<div className="font-medium">
								{parseInt(listingData.details.numGarageSpaces || 0)} Cars
							</div>
							<div className="text-sm text-gray-500">
								{listingData.details.garage}
							</div>
						</div>
					</div>
				</div>

				{/* View/Exposure */}
				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<Compass className="w-6 h-6 mr-4" />
					<div>
						<div className="text-gray-600 text-sm">View</div>
						<div className="font-medium">
							{listingData.details.viewType ||
								listingData.condominium.exposure ||
								'Not Available'}
						</div>
					</div>
				</div>

				{/* Property Taxes */}
				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<Coins className="w-6 h-6 mr-4" />
					<div>
						<div className="text-gray-600 text-sm">Annual Taxes</div>
						<div className="font-medium">
							${parseFloat(listingData.taxes.annualAmount).toLocaleString()}
							{listingData.taxes.assessmentYear &&
								` (${listingData.taxes.assessmentYear})`}
						</div>
					</div>
				</div>

				{/* Maintenance Fees - Only show if they exist */}
				{listingData.condominium.fees.maintenance && (
					<div className="flex items-center p-4 bg-gray-50 rounded-lg">
						<Wallet className="w-6 h-6 mr-4" />
						<div>
							<div className="text-gray-600 text-sm">
								Monthly Maintenance
							</div>
							<div className="font-medium">
								${listingData.condominium.fees.maintenance}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ListingDetails;
