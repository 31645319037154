import React from 'react';
import {
	BedDouble as Bed,
	Sofa,
	UtensilsCrossed,
	Users,
	Refrigerator as KitchenIcon,
	TreePine,
	Building,
	BookOpen,
	Dumbbell,
	Book
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';

const formatRoomName = (description) => {
	const lowercaseDesc = description.toLowerCase().trim();

	// Handle bedroom variations
	if (lowercaseDesc === 'prim bdrm') {
		return 'Primary Bedroom';
	}

	// Handle numbered bedrooms (2nd br, 3rd br, etc.)
	const bedroomMatch = lowercaseDesc.match(/^(\d+)(?:st|nd|rd|th)?\s*br(?:m)?$/i);
	if (bedroomMatch) {
		const number = bedroomMatch[1];
		const suffix = getNumberSuffix(parseInt(number));
		return `${number}${suffix} Bedroom`;
	}

	// Handle other specific cases
	const roomMappings = {
		liv: 'Living Room',
		living: 'Living Room',
		din: 'Dining Room',
		dining: 'Dining Room',
		fam: 'Family Room',
		family: 'Family Room',
		kit: 'Kitchen',
		br: 'Bedroom',
		bdrm: 'Bedroom',
		bath: 'Bathroom',
		rec: 'Recreation Room',
		recreation: 'Recreation Room',
		exercise: 'Exercise Room',
		gym: 'Exercise Room',
		lib: 'Library',
		den: 'Den',
		ofc: 'Office',
		wr: 'Washroom',
		laund: 'Laundry Room',
		sunroom: 'Sun Room',
		mud: 'Mud Room',
		storage: 'Storage Room',
		util: 'Utility Room',
		wrk: 'Workshop',
		pdr: 'Powder Room'
	};

	// Check if the room type exists in our mappings
	for (const [shortForm, fullName] of Object.entries(roomMappings)) {
		if (lowercaseDesc.includes(shortForm)) {
			return fullName;
		}
	}

	// If no specific mapping found, capitalize each word
	return description
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
};

// Helper function to get the proper suffix for numbers
const getNumberSuffix = (number) => {
	if (number >= 11 && number <= 13) {
		return 'th';
	}

	const lastDigit = number % 10;
	switch (lastDigit) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};

const RoomCard = ({ icon: Icon, name, level, dimensions, features }) => (
	<div className="p-4 flex items-center gap-4 bg-white shadow-lg rounded-md border">
		<div className="text-gray-500">
			<Icon size={28} />
		</div>
		<div className="flex-1">
			<div className="flex justify-between items-start">
				<h3 className="font-medium text-gray-900">{formatRoomName(name)}</h3>
				<div className="text-sm text-gray-500">
					{level} | {dimensions}
				</div>
			</div>
			<p className="mt-1 text-sm text-gray-500">{features}</p>
		</div>
	</div>
);

const getIcon = (description) => {
	const lowercaseDesc = description.toLowerCase();

	// Check for any variation of bedroom descriptions
	if (
		lowercaseDesc === 'bedroom' ||
		lowercaseDesc === 'prim bdrm' ||
		/^\d+(?:st|nd|rd|th)?\s*br(?:m)?$/i.test(lowercaseDesc) ||
		/^primary\s+bedroom$/i.test(lowercaseDesc) ||
		/^master\s+bedroom$/i.test(lowercaseDesc)
	) {
		return Bed;
	}

	// Other room types
	switch (lowercaseDesc) {
		case 'living':
		case 'living room':
			return Sofa;
		case 'dining':
		case 'dining room':
			return UtensilsCrossed;
		case 'family':
		case 'family room':
			return Users;
		case 'kitchen':
			return KitchenIcon;
		case 'recreation':
		case 'recreation room':
			return TreePine;
		case 'library':
			return BookOpen;
		case 'exercise':
		case 'exercise room':
		case 'gym':
			return Dumbbell;
		case 'study':
			return Book;
		default:
			return Building;
	}
};

const RoomDisplay = () => {
	const listingData = useSelector(selectSingleListingData);

	if (!listingData || !listingData.rooms) {
		return <div>No room information available</div>;
	}

	const roomsArray = Object.values(listingData.rooms)
		.filter((room) => room.description && room.level)
		.map((room) => ({
			name: room.description,
			level: room.level,
			dimensions: `${room.length} x ${room.width} m`,
			features: [room.features, room.features2, room.features3]
				.filter(Boolean)
				.join(', '),
			type: room.description
		}))
		.sort((a, b) => {
			const levelOrder = { Main: 1, '2nd': 2, '3rd': 3, Bsmt: 4 };
			return (levelOrder[a.level] || 99) - (levelOrder[b.level] || 99);
		});

	return (
		<div className="max-w-7xl p-6 bg-gray-50">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
				{roomsArray.map((room, index) => (
					<RoomCard
						key={index}
						icon={getIcon(room.type)}
						name={room.name}
						level={room.level}
						dimensions={room.dimensions}
						features={room.features}
					/>
				))}
			</div>
		</div>
	);
};

export default RoomDisplay;
