import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle, X } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import {
	clearListingPinActive,
	selectBoardModalToggle,
	toggleBoardModal,
	selectListingPinActive,
	selectSingleListing,
	selectSingleListingData
} from '../features/active/activeSlice';
import {
	selectAllBoards,
	selectBoardsStatus,
	selectBoardsError,
	fetchBoardsAsync,
	createBoardAsync,
	selectAllBoardsWithListings,
	fetchBoardsWithListingsAsync
} from '../features/boards/boardsSlice';
import { createSavedListingAsync } from '../features/savedListings/savedListingsSlice';

const BoardsModal = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(selectBoardModalToggle);
	// const boards = useSelector(selectAllBoards);
	const { boards, boardListings } = useSelector(selectAllBoardsWithListings);
	const boardsStatus = useSelector(selectBoardsStatus);
	const boardsError = useSelector(selectBoardsError);
	const listingToPin = useSelector(selectListingPinActive);
	const isSingleListing = useSelector(selectSingleListing);
	const singleListingData = useSelector(selectSingleListingData);

	useEffect(() => {
		if (isOpen && boardsStatus === 'idle') {
			// dispatch(fetchBoardsAsync());
			dispatch(fetchBoardsWithListingsAsync());
		}
	}, [isOpen, boardsStatus, dispatch]);

	// const handleBoardClick = (boardId) => {
	// 	console.log(`Board clicked: ${boardId}`);
	// 	console.log('Listing Pin', listingToPin);
	// 	dispatch(createSavedListingAsync({ listingData: listingToPin, boardId }));
	// };
	const handleBoardClick = (boardId) => {
		isSingleListing
			? dispatch(
					createSavedListingAsync({ listingData: singleListingData, boardId })
			  ).then(() => {
					dispatch(fetchBoardsWithListingsAsync());
			  })
			: dispatch(
					createSavedListingAsync({ listingData: listingToPin, boardId })
			  ).then(() => {
					dispatch(fetchBoardsWithListingsAsync());
			  });
	};

	const handleCreateNewBoard = () => {
		const newBoardName = prompt('Enter a name for the new board:');
		if (newBoardName) {
			dispatch(createBoardAsync({ name: newBoardName }));
		}
	};

	const handleClose = () => {
		dispatch(toggleBoardModal());
		dispatch(clearListingPinActive());
	};

	let content;

	if (boardsStatus === 'loading') {
		content = <div>Loading boards...</div>;
	} else if (boardsStatus === 'failed') {
		content = (
			<div>Error: {boardsError ? boardsError.toString() : 'Unknown error'}</div>
		);
	} else if (boards.length === 0) {
		content = <div>No boards found. Create a new one!</div>;
	} else {
		content = (
			<div className="grid grid-cols-2 gap-4 overflow-y-auto">
				{boards.map((board) => (
					<div key={board.id} className="no-underline">
						<div
							className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
							onClick={() => handleBoardClick(board.id)}
						>
							<div
								className="h-24 w-full bg-pinhous-red"
								// style={{ backgroundColor: board.color || '' }}
							></div>
							<div className="p-2">
								<h3 className="font-semibold text-sm">{board.name}</h3>
								<p className="text-xs text-gray-500">
									{board.SavedListings?.length || 0} items
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<>
			<div
				className={`fixed top-0 right-0 h-full w-1/3 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-[110] ${
					isOpen ? 'translate-x-0' : 'translate-x-full'
				}`}
			>
				<div className="flex flex-col h-full p-6">
					<div className="flex justify-between items-center mb-6">
						<h2 className="text-2xl font-bold">Pin to</h2>
						<button
							onClick={handleClose}
							className="text-gray-500 hover:text-gray-700"
						>
							<X size={24} />
						</button>
					</div>

					{content}

					<div
						className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer mt-4"
						onClick={handleCreateNewBoard}
					>
						<div className="text-center p-4">
							<PlusCircle className="mx-auto mb-2" size={24} />
							<p className="text-sm font-semibold">Create new board</p>
						</div>
					</div>
				</div>
			</div>

			{isOpen && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-[105]"
					onClick={handleClose}
				/>
			)}
		</>
	);
};

export default BoardsModal;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { PlusCircle, X } from 'lucide-react';
// import { useSelector, useDispatch } from 'react-redux';
// import { selectBoardModalToggle } from '../features/active/activeSlice';
// import { toggleBoardModal } from '../features/active/activeSlice'; // Assuming this action exists

// const BoardsModal = () => {
// 	const dispatch = useDispatch();
// 	const isOpen = useSelector(selectBoardModalToggle);

// 	// This will be replaced with data from Redux store
// 	const boards = [
// 		{ id: 1, name: 'Modern Style', itemCount: 10, color: '#FFD700' },
// 		{ id: 2, name: 'Bedrooms', itemCount: 12, color: '#98FB98' },
// 		{ id: 3, name: 'Staircases', itemCount: 15, color: '#87CEFA' }
// 	];

// 	const handleBoardClick = (boardId) => {
// 		// Placeholder for board click handler
// 		console.log(`Board clicked: ${boardId}`);
// 	};

// 	const handleCreateNewBoard = () => {
// 		// Placeholder for creating a new board
// 		console.log('Create new board clicked');
// 	};

// 	const handleClose = () => {
// 		dispatch(toggleBoardModal());
// 	};

// 	return (
// 		<>
// 			{/* Pin to Board Side Panel */}
// 			<div
// 				className={`fixed top-0 right-0 h-full w-1/3 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
// 					isOpen ? 'translate-x-0' : 'translate-x-full'
// 				}`}
// 			>
// 				<div className="flex flex-col h-full p-6">
// 					<div className="flex justify-between items-center mb-6">
// 						<h2 className="text-2xl font-bold">Pin to</h2>
// 						<button
// 							onClick={handleClose}
// 							className="text-gray-500 hover:text-gray-700"
// 						>
// 							<X size={24} />
// 						</button>
// 					</div>

// 					<div className="grid grid-cols-2 gap-4 overflow-y-auto">
// 						{boards.map((board) => (
// 							<Link
// 								key={board.id}
// 								to={`/board/${board.id}`}
// 								className="no-underline"
// 							>
// 								<div
// 									className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
// 									onClick={() => handleBoardClick(board.id)}
// 								>
// 									<div
// 										className="h-24 w-full"
// 										style={{ backgroundColor: board.color }}
// 									></div>
// 									<div className="p-2">
// 										<h3 className="font-semibold text-sm">
// 											{board.name}
// 										</h3>
// 										<p className="text-xs text-gray-500">
// 											{board.itemCount} items
// 										</p>
// 									</div>
// 								</div>
// 							</Link>
// 						))}
// 						<div
// 							className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer flex items-center justify-center h-full"
// 							onClick={handleCreateNewBoard}
// 						>
// 							<div className="text-center">
// 								<PlusCircle className="mx-auto mb-2" size={24} />
// 								<p className="text-sm font-semibold">Create new board</p>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 			{/* Overlay */}
// 			{isOpen && (
// 				<div
// 					className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-40"
// 					onClick={handleClose}
// 				/>
// 			)}
// 		</>
// 	);
// };

// export default BoardsModal;
