import React from 'react';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';
import { Coins, CalendarClock, Receipt, Building2 } from 'lucide-react';

const TaxCard = ({ icon: Icon, title, value, subtitle }) => (
	<div className="p-4 flex items-center gap-4 bg-white shadow-lg rounded-md border">
		<div className="text-gray-500 flex-shrink-0">
			<Icon size={24} />
		</div>
		<div className="flex-1">
			<div className="text-sm text-gray-500">{title}</div>
			<div className="font-medium text-gray-900">{value}</div>
			{subtitle && <div className="text-sm text-gray-500 mt-1">{subtitle}</div>}
		</div>
	</div>
);

const TaxDisplay = () => {
	const listingData = useSelector(selectSingleListingData);

	if (!listingData || !listingData.taxes) {
		return <div>No tax information available</div>;
	}

	const {
		taxes: { annualAmount, assessmentYear },
		condominium: { fees },
		details: { propertyType },
		listPrice
	} = listingData;

	// Calculate monthly tax amount
	const monthlyTaxes = (parseFloat(annualAmount) / 12).toFixed(2);

	// Calculate tax rate (annual taxes / list price)
	const taxRate = ((parseFloat(annualAmount) / parseFloat(listPrice)) * 100).toFixed(2);

	// Format currency values
	const formatCurrency = (value) => {
		return parseFloat(value).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		});
	};

	return (
		<div className="max-w-7xl p-6 bg-gray-50">
			<h2 className="text-xl font-semibold mb-4">Property Tax Information</h2>
			<div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
				<TaxCard
					icon={Coins}
					title="Annual Property Taxes"
					value={formatCurrency(annualAmount)}
					subtitle={`Monthly: ${formatCurrency(
						monthlyTaxes
					)} (${taxRate}% of list price)`}
				/>

				<TaxCard
					icon={CalendarClock}
					title="Assessment Year"
					value={assessmentYear || 'Not Available'}
					subtitle="Last tax assessment period"
				/>

				{fees?.maintenance && (
					<TaxCard
						icon={Receipt}
						title="Monthly Maintenance Fees"
						value={formatCurrency(fees.maintenance)}
						subtitle={`${propertyType} maintenance fees`}
					/>
				)}

				<TaxCard
					icon={Building2}
					title="Property Type"
					value={propertyType || 'Not Available'}
					subtitle={`Tax classification type`}
				/>

				{/* Include tax inclusions for condos */}
				{(fees?.taxesIncl || fees?.maintenance) && (
					<div className="md:col-span-2 p-4 bg-white shadow-lg rounded-md border">
						<h3 className="font-medium text-gray-900 mb-2">
							Maintenance Fees Include:
						</h3>
						<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
							{Object.entries({
								'Property Taxes': fees?.taxesIncl === 'Y',
								Heat: fees?.heatIncl === 'Y',
								Water: fees?.waterIncl === 'Y',
								Hydro: fees?.hydroIncl === 'Y',
								Cable: fees?.cableInlc === 'Y',
								Parking: fees?.parkingIncl === 'Y'
							}).map(
								([item, included], index) =>
									included && (
										<div
											key={index}
											className="text-sm text-gray-600"
										>
											✓ {item}
										</div>
									)
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TaxDisplay;
