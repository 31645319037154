import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectBoardModalToggle,
	fetchSingleActiveListing,
	setSingleListing,
	selectFullscreenGallery,
	selectShareModalToggle,
	selectListMapActive
} from '../../features/active/activeSlice';
import ListingNavBar from '../../components/ListingComponents/ListingNavBar.jsx';
import ListingImages from '../../components/ListingComponents/ListingImages.jsx';
import ListingMap from '../../components/ListingComponents/ListingMap.jsx';
import ListingDetails from '../../components/ListingComponents/ListingDetails.jsx';
import FullscreenGallery from '../../components/ListingComponents/FullScreenGallery.jsx';
import PropertyDescription from '../../components/ListingComponents/PropertyDescription.jsx';
import AgentCard2 from '../../components/AgentCard/AgentCard2.jsx';
import ListingAccordian from '../../components/ListingComponents/ListingAccordion.jsx';
import MortageCalculator from '../../components/ListingComponents/MortageCalculator.jsx';
import BoardsModal from '../../components/BoardsModal.jsx';
import ShareModal from '../../components/ListingComponents/ShareModal.jsx';

const Listings2 = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { singleListingData } = useSelector((state) => state.active);
	const boardModal = useSelector(selectBoardModalToggle);
	const fullscreenGallery = useSelector(selectFullscreenGallery);
	const shareModal = useSelector(selectShareModalToggle);
	const isMapOrListView = useSelector(selectListMapActive);

	useEffect(() => {
		dispatch(setSingleListing(true));

		const fetchData = async () => {
			try {
				await dispatch(fetchSingleActiveListing(id)).unwrap();
			} catch (error) {
				console.error('Failed to fetch listing:', error);
			}
		};

		fetchData();

		return () => {
			dispatch(setSingleListing(false));
		};
	}, [dispatch, id]);

	if (Object.keys(singleListingData).length === 0) {
		return <div>No listing data available</div>;
	}

	return (
		<div className="min-h-screen grid grid-rows-[auto,1fr]">
			{!fullscreenGallery && <ListingNavBar />}
			<div className="w-full">
				{isMapOrListView === 'List' && <ListingImages />}
				{isMapOrListView === 'Map' && <ListingMap />}
			</div>

			<div className="w-full px-16 py-8 bg-gray-100">
				<div className="grid grid-cols-4 gap-8">
					<div className="col-span-3 space-y-6">
						<div id="overview" className="bg-white rounded-lg shadow-sm p-6">
							<ListingDetails />
						</div>
						<div
							id="description"
							className="bg-white rounded-lg shadow-sm p-6"
						>
							<PropertyDescription />
						</div>
						<div
							id="features-&-details"
							className="bg-white rounded-lg shadow-sm p-6"
						>
							<ListingAccordian />
						</div>
						<div
							id="mortage-calculator"
							className="bg-white rounded-lg shadow-sm p-6"
						>
							<MortageCalculator />
						</div>
					</div>

					<div className="col-span-1 space-y-6">
						<div className="bg-white rounded-lg shadow-sm p-6">
							<AgentCard2 />
						</div>
					</div>
				</div>
			</div>

			{fullscreenGallery && <FullscreenGallery />}
			{boardModal && <BoardsModal />}
			{shareModal && <ShareModal />}
		</div>
	);
};

export default Listings2;
