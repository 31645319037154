import { configureStore } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userReducer from '../features/user/userSlice.js';
import activeReducer from '../features/active/activeSlice.js';
import listingsReducer from '../features/listings/listingsSlice.js';
import savedListingsReducer from '../features/savedListings/savedListingsSlice';
import searchParamsReducer from '../features/searchParams/searchParamsSlice.js';
import screenSizeReducer from '../features/screenSize/screenSizeSlice.js';
import boardsReducer from '../features/boards/boardsSlice.js';
import mapReducer from '../features/map/mapSlice';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user']
};

const rootReducer = combineReducers({
	user: userReducer,
	active: activeReducer,
	listings: listingsReducer,
	savedListings: savedListingsReducer,
	searchParams: searchParamsReducer,
	screenSize: screenSizeReducer,
	boards: boardsReducer,
	map: mapReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
});

export const persistor = persistStore(store);

export default store;
// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import { combineReducers } from 'redux';
// import userReducer from '../features/user/userSlice.js';
// import activeReducer from '../features/active/activeSlice.js';
// import listingsReducer from '../features/listings/listingsSlice.js'; // Import the new slice

// const persistConfig = {
// 	key: 'root',
// 	storage,
// 	whitelist: ['user'] // only user will be persisted
// };

// const rootReducer = combineReducers({
// 	user: userReducer,
// 	active: activeReducer,
// 	listings: listingsReducer
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const loggerMiddleware = (store) => (next) => (action) => {
// 	console.log('Dispatching', action);
// 	let result = next(action);
// 	console.log('Next State', store.getState());
// 	return result;
// };

// const store = configureStore({
// 	reducer: persistedReducer,
// 	middleware: (getDefaultMiddleware) =>
// 		getDefaultMiddleware({
// 			serializableCheck: {
// 				ignoredActions: ['persist/PERSIST']
// 			}
// 		})
// 	// .concat(loggerMiddleware)
// });

// export const persistor = persistStore(store);

// export default store;
