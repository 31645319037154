// Convert ISO date string to "X days ago" format
export const getDaysAgo = (dateString) => {
	if (!dateString) return '';

	const date = new Date(dateString);
	const now = new Date();

	// Get time difference in milliseconds
	const diffTime = Math.abs(now - date);

	// Convert to days
	const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

	if (diffDays === 0) {
		return 'Today';
	} else if (diffDays === 1) {
		return 'Yesterday';
	} else {
		return `${diffDays} days ago`;
	}
};

// Format specific for listing date
export const formatListingDate = (dateString) => {
	if (!dateString) return '';

	const date = new Date(dateString);
	const now = new Date();
	const diffTime = Math.abs(now - date);
	const diffHours = Math.floor(diffTime / (1000 * 60 * 60));

	if (diffHours < 24) {
		return `${diffHours} hours ago`;
	} else {
		return `${Math.floor(diffHours / 24)} days ago`;
	}
};

export const formatPrice = (price) => {
	const numericPrice =
		typeof price === 'string' ? parseFloat(price.replace(/[^\d.-]/g, '')) : price;

	if (typeof numericPrice !== 'number' || isNaN(numericPrice)) {
		return '$0';
	}

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	});

	return formatter.format(numericPrice);
};
