import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../features/user/userSlice';
import { useDispatch } from 'react-redux';
import { clearSavedListings } from '../features/savedListings/savedListingsSlice';
import arrowRight from '../icons/arrowRight.svg'; // Imported arrowRight.svg

const Profile = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState({
    app: false,
    email: false,
    phoneCalls: false,
    textMessages: false,
  });

  const handleToggle = (type) => {
    setNotifications((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearSavedListings());
    onClose();
    navigate('/login');
  };

  return (
    <>
      {/* Profile Side Panel */}
      <div
        className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full p-6">
          <h2 className="text-2xl font-bold mb-6">Account</h2>
          
          {/* Notifications Section */}
          <div className="space-y-4 mb-6">
            {Object.keys(notifications).map((key) => (
              <div key={key} className="flex items-center justify-between">
                <span className="capitalize">{key.replace(/([A-Z])/g, ' $1')}</span>
                <button
                  className={`w-10 h-6 rounded-full flex items-center transition-all duration-300 ease-in-out ${
                    notifications[key] ? 'bg-pinhous-red' : 'bg-gray-300'
                  }`}
                  onClick={() => handleToggle(key)}
                >
                  <span 
                    className={`w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out transform ${
                      notifications[key] ? 'translate-x-4' : 'translate-x-0.5'
                    }`}
                  ></span>
                </button>
              </div>
            ))}
          </div>

          {/* Account Actions */}
          <div className="space-y-4 mb-6">
            <button
              className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
              onClick={() => {
                navigate('/edit-profile')
                onClose();
              }}
            >
              <span>Edit Profile</span>
              <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
            </button>
            <button
              className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
              onClick={() => {
                navigate('/change-password');
                onClose();
              }}
            >
              <span>Change Password</span>
              <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
            </button>
            <button
              className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
              onClick={() => navigate('/beta-tester')}
            >
              <span>Be a beta tester</span>
              <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
            </button>
            <button
              className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
              onClick={handleLogout}
            >
              <span>Log out</span>
              <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
            </button>
          </div>

          {/* Support Section */}
          <div className="mt-auto">
            <h3 className="font-semibold mb-4">Support</h3>
            <div className="space-y-4">
              <button
                className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
                onClick={() => window.open('/contact', '_blank')}
              >
                <span>Contact Us</span>
                <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
              </button>
              <button
                className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
                onClick={() => window.open('/terms', '_blank')}
              >
                <span>See terms of service</span>
                <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
              </button>
              <button
                className="w-full text-left flex items-center justify-between py-2 hover:text-pinhous-red transition-colors duration-200"
                onClick={() => window.open('/privacy', '_blank')}
              >
                <span>See privacy policy</span>
                <img src={arrowRight} alt="Arrow Right" className="w-4 h-4 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-40"
          onClick={onClose}
        />
      )}
    </>
  );
};

export default Profile;