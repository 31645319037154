import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const baseURL = 'https://cdn.repliers.io/';

const ImageSlider = ({
	images,
	isCard,
	currentIndex: controlledIndex,
	setCurrentIndex: setControlledIndex,
	showControls = true
}) => {
	// Internal state for uncontrolled usage
	const [internalIndex, setInternalIndex] = useState(0);

	// Use controlled index if provided, otherwise use internal state
	const currentIndex = controlledIndex ?? internalIndex;
	const setCurrentIndex = setControlledIndex ?? setInternalIndex;
	const goToPrevious = (event) => {
		event.stopPropagation();
		const isFirstSlide = currentIndex === 0;
		const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
	};

	const goToNext = (event) => {
		event.stopPropagation();
		const isLastSlide = currentIndex === images.length - 1;
		const newIndex = isLastSlide ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
	};

	// const goToSlide = (index, event) => {
	// 	event.stopPropagation();
	// 	setCurrentIndex(index);
	// };

	return (
		<>
			{images && (
				<div className="w-full h-full relative">
					<div className="relative w-full h-full">
						{images.map((value, index) => (
							<img
								src={`${baseURL}${value}`}
								alt={`Slide ${index + 1}`}
								className={`
                  absolute top-0 left-0 w-full h-full
                  ${isCard ? 'object-cover' : 'object-cover'}
                  bg-[rgba(0,0,0,0.3)]
                  transition-opacity duration-200 ease-in-out
                  ${currentIndex === index ? 'opacity-100 z-10' : 'opacity-0 z-0'}
                `}
								key={value}
							/>
						))}

						{showControls && (
							<>
								<button
									onClick={goToPrevious}
									className="absolute top-1/2 left-4 -translate-y-1/2 bg-white/50 w-12 h-12 flex justify-center items-center rounded-full border-none cursor-pointer z-20"
								>
									<ChevronLeft className="h-5 w-5" />
								</button>
								<button
									onClick={goToNext}
									className="absolute top-1/2 right-4 -translate-y-1/2 bg-white/50 w-12 h-12 flex justify-center items-center rounded-full border-none cursor-pointer z-20"
								>
									<ChevronRight className="h-5 w-5" />
								</button>
								<div className="absolute bottom-4 right-4 bg-black/50 px-3 py-1 rounded-full border-none cursor-pointer z-20 text-white">
									{`${currentIndex + 1}/${images.length}`}
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default ImageSlider;
