import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	AdvancedMarker,
	InfoWindow,
	useAdvancedMarkerRef,
	useMapsLibrary
} from '@vis.gl/react-google-maps';
import {
	setActiveMarker,
	clearActiveMarker,
	selectActiveMarkerId,
	setHoveredListing
} from '../../features/map/mapSlice';
import Card2 from '../Card/Card2';

const CustomPin = ({ isActive, price }) => {
	return (
		<div className="relative cursor-pointer transform-gpu transition-transform duration-200 hover:scale-110">
			<svg
				width="24"
				height="36"
				viewBox="0 0 24 36"
				className={`${isActive ? 'drop-shadow-lg scale-110' : 'drop-shadow'}`}
			>
				<path
					d="M12 0C5.383 0 0 5.383 0 12c0 9 12 24 12 24s12-15 12-24c0-6.617-5.383-12-12-12z"
					className={`${
						isActive ? 'fill-blue-600' : 'fill-blue-500'
					} transition-colors duration-200`}
				/>
				<circle cx="12" cy="12" r="6" className="fill-white" />
			</svg>
			<div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-white px-2 py-1 rounded-full shadow-md text-xs font-semibold whitespace-nowrap">
				${price?.toLocaleString()}
			</div>
		</div>
	);
};

const MapMarker = ({ listing }) => {
	const dispatch = useDispatch();
	const activeMarkerId = useSelector(selectActiveMarkerId);
	const [markerRef, marker] = useAdvancedMarkerRef();
	const core = useMapsLibrary('core');

	const isOpen = activeMarkerId === listing.mlsNumber;

	const lat = parseFloat(listing.map.latitude);
	const lng = parseFloat(listing.map.longitude);

	if (isNaN(lat) || isNaN(lng)) {
		console.warn(`Invalid coordinates for listing ${listing.mlsNumber}`);
		return null;
	}

	const handleMarkerClick = () => {
		if (isOpen) {
			dispatch(clearActiveMarker());
		} else {
			dispatch(setActiveMarker(listing.mlsNumber));
		}
	};

	return (
		<>
			<AdvancedMarker
				ref={markerRef}
				onClick={handleMarkerClick}
				onMouseOver={() => dispatch(setHoveredListing(listing.mlsNumber))}
				onMouseOut={() => dispatch(setHoveredListing(null))}
				position={{ lat, lng }}
			>
				<CustomPin isActive={isOpen} price={listing.listPrice} />
			</AdvancedMarker>
			{isOpen && marker && core && (
				<InfoWindow
					anchor={marker}
					onCloseClick={() => dispatch(clearActiveMarker())}
					options={{
						pixelOffset: { width: 0, height: -40 }
					}}
				>
					<div className="w-[450px] h-[350px] ">
						<div className="w-[97%] h-full ">
							<Card2 data={listing} isMap={true} isMapList={false} />
						</div>
					</div>
				</InfoWindow>
			)}
		</>
	);
};

export default MapMarker;
// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
// 	AdvancedMarker,
// 	InfoWindow,
// 	useAdvancedMarkerRef,
// 	useMapsLibrary
// } from '@vis.gl/react-google-maps';
// import {
// 	setActiveMarker,
// 	clearActiveMarker,
// 	selectActiveMarkerId,
// 	setHoveredListing
// } from '../../features/map/mapSlice';
// import Card2 from '../Card/Card2'; // Adjust the import path as needed

// const CustomPin = ({ isActive, price }) => {
// 	return (
// 		<div className="relative cursor-pointer transform-gpu transition-transform duration-200 hover:scale-110">
// 			<svg
// 				width="24"
// 				height="36"
// 				viewBox="0 0 24 36"
// 				className={`${isActive ? 'drop-shadow-lg scale-110' : 'drop-shadow'}`}
// 			>
// 				<path
// 					d="M12 0C5.383 0 0 5.383 0 12c0 9 12 24 12 24s12-15 12-24c0-6.617-5.383-12-12-12z"
// 					className={`${
// 						isActive ? 'fill-blue-600' : 'fill-blue-500'
// 					} transition-colors duration-200`}
// 				/>
// 				<circle cx="12" cy="12" r="6" className="fill-white" />
// 			</svg>
// 			<div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-white px-2 py-1 rounded-full shadow-md text-xs font-semibold whitespace-nowrap">
// 				${price?.toLocaleString()}
// 			</div>
// 		</div>
// 	);
// };

// const MapMarker = ({ listing }) => {
// 	const dispatch = useDispatch();
// 	const activeMarkerId = useSelector(selectActiveMarkerId);
// 	const [markerRef, marker] = useAdvancedMarkerRef();

// 	// Get the core Maps library
// 	const core = useMapsLibrary('core');

// 	const isOpen = activeMarkerId === listing.mlsNumber;

// 	// Validate coordinates
// 	const lat = parseFloat(listing.map.latitude);
// 	const lng = parseFloat(listing.map.longitude);

// 	// Only render marker if we have valid coordinates
// 	if (isNaN(lat) || isNaN(lng)) {
// 		console.warn(`Invalid coordinates for listing ${listing.mlsNumber}`);
// 		return null;
// 	}

// 	const handleMarkerClick = () => {
// 		if (isOpen) {
// 			dispatch(clearActiveMarker());
// 		} else {
// 			dispatch(setActiveMarker(listing.mlsNumber));
// 		}
// 	};

// 	return (
// 		<>
// 			<AdvancedMarker
// 				ref={markerRef}
// 				onClick={handleMarkerClick}
// 				onMouseOver={() => dispatch(setHoveredListing(listing.mlsNumber))}
// 				onMouseOut={() => dispatch(setHoveredListing(null))}
// 				position={{ lat, lng }}
// 			>
// 				<CustomPin isActive={isOpen} price={listing.listPrice} />
// 			</AdvancedMarker>
// 			{isOpen && marker && core && (
// 				<InfoWindow
// 					anchor={marker}
// 					onCloseClick={() => dispatch(clearActiveMarker())}
// 					options={{
// 						maxWidth: 400, // Match card width
// 						minWidth: 400, // Keep consistent size
// 						padding: 0,
// 						offset: { x: 0, y: -40 }, // Offset up by marker height
// 						disableAutoPan: false, // Auto-pan to show full card
// 						shadowStyle: 1 // Subtle shadow
// 					}}
// 				>
// 					<div className="w-[400px]">
// 						{' '}
// 						{/* Fixed width container */}
// 						<Card2 data={listing} isMap={true} isMapList={false} />
// 					</div>
// 				</InfoWindow>
// 			)}
// 		</>
// 	);
// };

// export default MapMarker;
