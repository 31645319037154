import React, { useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setFullscreenGallery,
	setFullscreenGalleryIndex,
	selectFullscreenGalleryIndex,
	selectSingleListingData
} from '../../features/active/activeSlice';

const FullScreenGallery = () => {
	const dispatch = useDispatch();
	const currentIndex = useSelector(selectFullscreenGalleryIndex);
	const singleListingData = useSelector(selectSingleListingData);

	// Refs to track key states
	const keyStates = useRef({
		ArrowLeft: false,
		ArrowRight: false
	});

	// Ref for the animation frame
	const animationFrameRef = useRef(null);

	// Ref for tracking the last navigation time
	const lastNavTimeRef = useRef(Date.now());

	const handleNavigation = () => {
		const now = Date.now();
		// Throttle navigation to every 100ms
		if (now - lastNavTimeRef.current < 100) return;

		if (keyStates.current.ArrowLeft && !keyStates.current.ArrowRight) {
			handlePrevious();
			lastNavTimeRef.current = now;
		} else if (keyStates.current.ArrowRight && !keyStates.current.ArrowLeft) {
			handleNext();
			lastNavTimeRef.current = now;
		}

		// Request next frame if keys are still pressed
		if (keyStates.current.ArrowLeft || keyStates.current.ArrowRight) {
			animationFrameRef.current = requestAnimationFrame(handleNavigation);
		}
	};

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'Escape') {
				dispatch(setFullscreenGallery(false));
			} else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
				if (!keyStates.current[e.key]) {
					keyStates.current[e.key] = true;
					// Start animation frame loop if it's not running
					if (!animationFrameRef.current) {
						animationFrameRef.current =
							requestAnimationFrame(handleNavigation);
					}
				}
			}
		};

		const handleKeyUp = (e) => {
			if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
				keyStates.current[e.key] = false;
				// If both keys are up, cancel the animation frame
				if (!keyStates.current.ArrowLeft && !keyStates.current.ArrowRight) {
					cancelAnimationFrame(animationFrameRef.current);
					animationFrameRef.current = null;
				}
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
			// Cleanup animation frame on unmount
			if (animationFrameRef.current) {
				cancelAnimationFrame(animationFrameRef.current);
			}
		};
	}, [dispatch, currentIndex]);

	const handleClose = () => {
		dispatch(setFullscreenGallery(false));
	};

	const handlePrevious = () => {
		dispatch(
			setFullscreenGalleryIndex(
				currentIndex === 0
					? singleListingData.images.length - 1
					: currentIndex - 1
			)
		);
	};

	const handleNext = () => {
		dispatch(
			setFullscreenGalleryIndex(
				currentIndex === singleListingData.images.length - 1
					? 0
					: currentIndex + 1
			)
		);
	};

	// Calculate visible dots range
	const getVisibleDots = () => {
		const totalDots = singleListingData.images.length;
		const maxVisibleDots = totalDots;

		if (totalDots <= maxVisibleDots) {
			return Array.from({ length: totalDots }, (_, i) => i);
		}

		let start = currentIndex - Math.floor(maxVisibleDots / 2);
		let end = currentIndex + Math.floor(maxVisibleDots / 2);

		if (start < 0) {
			start = 0;
			end = maxVisibleDots - 1;
		} else if (end >= totalDots) {
			end = totalDots - 1;
			start = totalDots - maxVisibleDots;
		}

		return Array.from({ length: end - start + 1 }, (_, i) => start + i);
	};

	return (
		<div className="fixed inset-0 bg-black z-50 flex flex-col">
			{/* Header */}
			<div className="flex justify-between items-center px-6 py-4 bg-black/60">
				<div className="text-white">
					{currentIndex + 1} of {singleListingData.images.length}
				</div>
				<button
					onClick={handleClose}
					className="text-white hover:text-gray-300 transition-colors"
					type="button"
				>
					<X className="w-6 h-6 text-white" />
				</button>
			</div>

			{/* Main Image Area */}
			<div className="flex-1 flex items-center justify-center relative">
				<button
					onClick={handlePrevious}
					className="absolute left-4 text-white hover:text-gray-300 transition-colors"
					type="button"
				>
					<ChevronLeft className="w-8 h-8" />
				</button>

				<img
					src={`https://cdn.repliers.io/${singleListingData.images[currentIndex]}`}
					alt={`Property ${currentIndex + 1}`}
					className="max-h-[calc(100vh-6rem)] max-w-[90vw] object-contain"
				/>

				<button
					onClick={handleNext}
					className="absolute right-4 text-white hover:text-gray-300 transition-colors"
					type="button"
				>
					<ChevronRight className="w-8 h-8" />
				</button>
			</div>

			{/* Dots Navigation */}
			<div className="h-16 bg-black/60 flex items-center justify-center gap-2 px-4">
				{getVisibleDots().map((index) => (
					<button
						key={index}
						onClick={() => dispatch(setFullscreenGalleryIndex(index))}
						className={`w-2 h-2 rounded-full transition-all duration-200 ${
							currentIndex === index
								? 'bg-white w-4'
								: 'bg-gray-500 hover:bg-gray-400'
						}`}
						type="button"
						aria-label={`Go to image ${index + 1}`}
					/>
				))}
			</div>
		</div>
	);
};

export default FullScreenGallery;
