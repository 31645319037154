import React from 'react';
import MapComponent from '../../components/MapComponent/MapComponent.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { selectSingleListingData, setListMap } from '../../features/active/activeSlice';
const ListingMap = () => {
	const listingData = useSelector(selectSingleListingData);
	const dispatch = useDispatch();

	return (
		<>
			<div className="flex px-16 mt-4 gap-2 h-[700px] mb-4">
				<div className="relative w-[100%] h-full">
					<div className="absolute bottom-4 left-4 z-20 flex gap-2">
						<button
							className="bg-black/75 text-white rounded-full px-4 py-2 flex items-center gap-2 hover:bg-black/100 transition-colors"
							onClick={() => dispatch(setListMap('List'))}
						>
							See Images
						</button>
					</div>
					<MapComponent
						props={{
							map: {
								latitude: listingData?.map?.latitude,
								longitude: listingData?.map?.longitude,
								point: listingData?.map?.point
							},
							size: 'full'
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default ListingMap;
