// ListingNavBar.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as SavedListingsIcon } from '../../icons/saved_listings.svg';
import { Share2 } from 'lucide-react';
import { toggleBoardModal, toggleShareModal } from '../../features/active/activeSlice';

const ListingNavBar = () => {
	const [activeTab, setActiveTab] = useState('Overview');
	const dispatch = useDispatch();

	const tabs = ['Overview', 'Description', 'Features & Details', 'Mortage Calculator'];

	// Function to get section positions and update active tab
	const updateActiveSection = () => {
		// Get all sections
		const sectionPositions = tabs
			.map((tab) => {
				const element = document.getElementById(
					tab.toLowerCase().replace(/\s+/g, '-')
				);
				if (element) {
					return {
						tab,
						position: element.offsetTop - 120 // Subtract header offset
					};
				}
				return null;
			})
			.filter(Boolean);

		// Get current scroll position
		const scrollPosition = window.scrollY + 40; // Add offset to account for sticky header

		// Find the last section that we've scrolled past
		for (let i = sectionPositions.length - 1; i >= 0; i--) {
			if (scrollPosition >= sectionPositions[i].position) {
				setActiveTab(sectionPositions[i].tab);
				break;
			}
		}
	};

	// Debounce function to limit scroll event firing
	const debounce = (func, wait) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(this, args), wait);
		};
	};

	// Set up scroll listener with debouncing
	useEffect(() => {
		const debouncedUpdateActiveSection = debounce(updateActiveSection, 10);

		window.addEventListener('scroll', debouncedUpdateActiveSection);

		// Initial check for active section
		updateActiveSection();

		return () => {
			window.removeEventListener('scroll', debouncedUpdateActiveSection);
		};
	}, []);

	const handleTabClick = (tab) => {
		setActiveTab(tab);

		// Get the element ID based on the tab name
		const elementId = tab.toLowerCase().replace(/\s+/g, '-');
		const element = document.getElementById(elementId);

		if (element) {
			// Offset for the sticky header
			const offset = 120;
			const elementPosition = element.getBoundingClientRect().top;
			const offsetPosition = elementPosition + window.pageYOffset - offset;

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			});
		}
	};

	return (
		<div className="sticky top-[66px] bg-white z-[40]">
			<div className="flex px-16 justify-between border-b border-gray-300 pt-2 shadow-lg">
				<div className="flex gap-8">
					{tabs.map((tab) => (
						<button
							key={tab}
							onClick={() => handleTabClick(tab)}
							className={`relative pb-2 px-1 text-md font-medium transition-colors duration-200 
                ${
					activeTab === tab
						? 'text-pinhous-red'
						: 'text-gray-600 hover:text-gray-900'
				}`}
						>
							{tab}
							{activeTab === tab && (
								<div className="absolute bottom-[-2px] left-[-5px] right-[-5px] h-[4px] bg-pinhous-red" />
							)}
						</button>
					))}
				</div>
				<div className="flex gap-4 items-start">
					<button
						className="flex items-center border rounded-full px-2 py-1 gap-2 border-pinhous-grey hover:text-gray-900 hover:border-black"
						onClick={() => dispatch(toggleShareModal())}
					>
						<Share2 className="w-4 h-4" />
						<span className="text-sm">Share</span>
					</button>
					<button
						className="flex items-center gap-2 border rounded-full px-2 py-1 border-pinhous-grey text-black hover:text-gray-900 hover:border-black"
						onClick={() => dispatch(toggleBoardModal())}
					>
						<SavedListingsIcon
							className="w-5 h-5"
							style={{ fill: 'black' }}
						/>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ListingNavBar;
// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { ReactComponent as SavedListingsIcon } from '../../icons/saved_listings.svg';
// import { Share2 } from 'lucide-react';
// import { toggleBoardModal, toggleShareModal } from '../../features/active/activeSlice';

// const ListingNavBar = () => {
// 	const [activeTab, setActiveTab] = useState('Overview');
// 	const dispatch = useDispatch();

// 	const handleTabClick = (tab) => {
// 		setActiveTab(tab);
// 	};

// 	const tabs = ['Overview', 'Description', 'Features & Details', 'Mortage Calculator'];

// 	return (
// 		<div className="sticky top-[66px] bg-white z-[40]">
// 			<div className="flex px-16 justify-between border-b border-gray-300 pt-2 shadow-lg">
// 				<div className="flex gap-8">
// 					{tabs.map((tab) => (
// 						<button
// 							key={tab}
// 							onClick={() => handleTabClick(tab)}
// 							className={`relative pb-2 px-1 text-md font-medium transition-colors duration-200 ${
// 								activeTab === tab
// 									? 'text-pinhous-red'
// 									: 'text-gray-600 hover:text-gray-900'
// 							}`}
// 						>
// 							{tab}
// 							{activeTab === tab && (
// 								<div className="absolute bottom-[-2px] left-[-5px] right-[-5px] h-[4px] bg-pinhous-red" />
// 							)}
// 						</button>
// 					))}
// 				</div>
// 				<div className="flex gap-4 items-start">
// 					<button
// 						className="flex items-center border rounded-full px-2 py-1 gap-2 border-pinhous-grey hover:text-gray-900 hover:border-black"
// 						onClick={() => dispatch(toggleShareModal())}
// 					>
// 						<Share2 className="w-4 h-4" />
// 						<span className="text-sm">Share</span>
// 					</button>
// 					<button
// 						className="flex items-center gap-2 border rounded-full px-2 py-1 border-pinhous-grey text-black hover:text-gray-900 hover:border-black"
// 						onClick={() => {
// 							dispatch(toggleBoardModal());
// 						}}
// 					>
// 						<SavedListingsIcon
// 							className="w-5 h-5"
// 							style={{ fill: 'black' }}
// 						/>
// 					</button>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default ListingNavBar;
