import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSingleListingData } from '../../features/active/activeSlice';
import ReactSlider from 'react-slider';

const MortgageCalculator = () => {
	const listingData = useSelector(selectSingleListingData);
	const initialPrice = parseFloat(listingData?.listPrice) || 638000;

	const [propertyPrice, setPropertyPrice] = useState(initialPrice);
	const [amortization, setAmortization] = useState(25);
	const [interestRate, setInterestRate] = useState(5);
	const [downPayment, setDownPayment] = useState(initialPrice * 0.2);
	const [monthlyPayment, setMonthlyPayment] = useState(0);
	const [principalAmount, setPrincipalAmount] = useState(0);
	const [totalInterest, setTotalInterest] = useState(0);
	const [totalPayable, setTotalPayable] = useState(0);

	const calculateMortgage = () => {
		const principal = propertyPrice - downPayment;
		const monthlyRate = interestRate / 100 / 12;
		const numberOfPayments = amortization * 12;

		// Monthly payment calculation using the mortgage payment formula
		const monthly =
			(principal * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
			(Math.pow(1 + monthlyRate, numberOfPayments) - 1);

		const totalAmount = monthly * numberOfPayments;

		setMonthlyPayment(monthly);
		setPrincipalAmount(principal);
		setTotalInterest(totalAmount - principal);
		setTotalPayable(totalAmount);
	};

	useEffect(() => {
		if (listingData?.listPrice) {
			const price = parseFloat(listingData.listPrice);
			setPropertyPrice(price);
			setDownPayment(price * 0.2);
		}
	}, [listingData]);

	useEffect(() => {
		calculateMortgage();
	}, [propertyPrice, amortization, interestRate, downPayment]);

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

	return (
		<div className="p-6 font-sans">
			<h2 className="text-2xl font-medium mb-6">Mortgage Calculator</h2>

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
				{/* Left Column - Inputs */}
				<div className="space-y-6">
					{/* Property Price Input */}
					<div className="space-y-2">
						<label className="text-sm font-light text-gray-600 block">
							Purchase Price
						</label>
						<input
							type="text"
							value={formatCurrency(propertyPrice).replace('$', '$ ')}
							onChange={(e) => {
								const value = parseFloat(
									e.target.value.replace(/[^0-9]/g, '')
								);
								if (!isNaN(value)) {
									setPropertyPrice(value);
									setDownPayment(value * 0.2);
								}
							}}
							className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pinhous-blue text-lg font-normal"
						/>
					</div>

					<div className="grid grid-cols-2 gap-4">
						{/* Amortization Select */}
						<div className="space-y-2">
							<label className="text-sm font-light text-gray-600 block">
								Amortization
							</label>
							<select
								value={amortization}
								onChange={(e) => setAmortization(Number(e.target.value))}
								className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pinhous-blue bg-white font-normal"
							>
								{[15, 20, 25, 30].map((year) => (
									<option key={year} value={year}>
										{year} Years
									</option>
								))}
							</select>
						</div>

						{/* Interest Rate Input */}
						<div className="space-y-2">
							<label className="text-sm font-light text-gray-600 block">
								Rate of Interest
							</label>
							<div className="relative">
								<input
									type="number"
									value={interestRate}
									onChange={(e) =>
										setInterestRate(Number(e.target.value))
									}
									className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pinhous-blue pr-8 font-normal"
									min={0}
									max={100}
									step={0.1}
								/>
								<span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 font-light">
									%
								</span>
							</div>
						</div>
					</div>

					{/* Down Payment Section */}
					<div className="space-y-4">
						<label className="text-sm font-light text-gray-600 block">
							Down Payment (
							{Math.trunc((downPayment / propertyPrice) * 1000) / 10}%)
						</label>
						<input
							type="text"
							value={formatCurrency(downPayment).replace('$', '$ ')}
							onChange={(e) => {
								const value = parseFloat(
									e.target.value.replace(/[^0-9]/g, '')
								);
								if (!isNaN(value)) {
									setDownPayment(value);
								}
							}}
							className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pinhous-blue text-lg font-normal"
						/>

						<div className="pt-7">
							<ReactSlider
								className="h-3 bg-gray-200 rounded-full group"
								thumbClassName="w-8 h-8 bg-white shadow-lg border-4 border-pinhous-blue rounded-full cursor-pointer 
                   -mt-2.5 focus:outline-none"
								value={downPayment}
								min={propertyPrice * 0.0}
								max={propertyPrice}
								step={1000}
								onChange={(value) => setDownPayment(value)}
								renderTrack={(props, state) => {
									const trackStyle = {
										height: '12px',
										borderRadius: '9999px',
										background:
											state.index === 0 ? '#345B84' : '#e5e7eb'
									};

									return (
										<div
											{...props}
											style={{
												...props.style,
												...trackStyle
											}}
										/>
									);
								}}
							/>
						</div>
					</div>
				</div>

				{/* Right Column - Results */}
				<div className="bg-white rounded-lg border border-gray-200 p-6 space-y-6">
					<div className="space-y-1">
						<h3 className="text-gray-500 font-light">Mortgage Payment</h3>
						<div className="flex items-baseline">
							<span className="text-4xl font-light">
								{formatCurrency(monthlyPayment)}
							</span>
							<span className="text-gray-500 ml-2 font-light">
								/monthly
							</span>
						</div>
					</div>

					<hr />

					<div className="grid grid-cols-2 gap-2">
						<div className="space-y-1 border p-3 rounded-md">
							<h4 className="text-gray-500 font-light">Principal Amount</h4>
							<p className="text-xl font-normal">
								{formatCurrency(principalAmount)}
							</p>
						</div>
						<div className="space-y-1 border p-3 rounded-md">
							<h4 className="text-gray-500 font-light">Interest</h4>
							<p className="text-xl font-normal">
								{formatCurrency(totalInterest)}
							</p>
						</div>
						<div className="space-y-1 border p-3 rounded-md">
							<h4 className="text-gray-500 font-light">Total Payable</h4>
							<p className="text-xl font-normal">
								{formatCurrency(totalPayable)}
							</p>
						</div>
						<div className="space-y-1 border p-3 rounded-md">
							<h4 className="text-gray-500 font-light">Amortization</h4>
							<p className="text-xl font-normal">{amortization} years</p>
						</div>
					</div>

					<hr />

					<div className="flex items-start gap-2 text-gray-500 text-sm mt-4">
						<svg
							className="w-4 h-4 mt-0.5 flex-shrink-0"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						<p className="font-light">
							This data is for informational purposes only. Please consult a
							mortgage professional for the most accurate calculations.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MortgageCalculator;
