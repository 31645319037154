import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';
import { createClient } from '../../utils/api';
import { loginUser } from '../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserStatus, selectUserError } from '../../features/user/userSlice';

const inviteCode = process.env.REACT_APP_INVITE_CODE;

// console.log('Environment Variables Debug:');
// console.log('REACT_APP_INVITE_CODE:', inviteCode);
// console.log('All env variables:', {
// 	REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
// 	REACT_APP_PUBLIC_POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
// 	REACT_APP_PUBLIC_POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
// 	REACT_APP_INVITE_CODE: process.env.REACT_APP_INVITE_CODE,
// 	REACT_APP_BACKEND_API_URL: process.env.REACT_APP_BACKEND_API_URL,
// 	NODE_ENV: process.env.NODE_ENV
// });

const SignUpForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userStatus = useSelector(selectUserStatus);
	const userError = useSelector(selectUserError);
	const [inviteCodeInput, setInviteCodeInput] = useState('');
	const [isInviteCodeValid, setIsInviteCodeValid] = useState(false);

	const [formData, setFormData] = useState({
		fname: '',
		lname: '',
		email: '',
		password: '',
		confirmPassword: ''
	});
	const [errors, setErrors] = useState({});
	const [showPassword, setShowPassword] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value
		}));
		if (errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: ''
			}));
		}
	};

	const validateForm = () => {
		let newErrors = {};
		if (!formData.fname) newErrors.fname = 'First Name is required';
		if (!formData.lname) newErrors.lname = 'Last Name is required';
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			newErrors.email = 'Email is invalid';
		}
		if (!formData.password) {
			newErrors.password = 'Password is required';
		} else if (formData.password.length < 8) {
			newErrors.password = 'Password must be at least 8 characters';
		} else if (
			!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(formData.password)
		) {
			newErrors.password =
				'Password must contain at least one number, one uppercase and lowercase letter, and one special character';
		}
		if (formData.password !== formData.confirmPassword) {
			newErrors.confirmPassword = 'Passwords do not match';
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			try {
				// Create new client
				await createClient(formData);
	
				navigate('/confirm-email', { state: { email: formData.email } });
			} catch (error) {
				setErrors({
					submit: error.message || 'Failed to create account. Please try again.',
					details: error.details || 'No additional information available.'
				});
			}
		}
	};
	
	// In the return statement, update the error display section
	{errors.submit && (
		<p className="text-pinhous-red text-xs mt-1">
			{errors.submit}
		</p>
	)}
	{errors.details && (
		<p className="text-pinhous-red text-xs mt-1">
			{errors.details}
		</p>
	)}

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleInviteCodeChange = (e) => {
		setInviteCodeInput(e.target.value);
	};

	const validateInviteCode = () => {
		if (inviteCodeInput === inviteCode) {
			setIsInviteCodeValid(true);
			setErrors((prevErrors) => ({
				...prevErrors,
				inviteCode: ''
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				inviteCode: 'Invalid invite code'
			}));
		}
	};

	// Redirect to dashboard if login is successful
	if (userStatus === 'succeeded') {
		navigate('/dashboard');
	}

	return (
		<div className="flex flex-col items-center justify-start min-h-screen overflow-y-auto bg-white p-4">
			<div className="w-full max-w-md flex-grow flex flex-col justify-center">
				<div className="mb-8 text-center">
					<div className="flex justify-center mb-4">
						<Logo />
					</div>
					<h2 className="text-xl font-normal text-[#000]">
						The Right Way to Find Your Dream Home
					</h2>
					<p className="mt-5 text-[#000]">Create your account</p>
				</div>

				{!isInviteCodeValid ? (
					<div className="space-y-4">
						<div className="flex">
							<input
								type="text"
								value={inviteCodeInput}
								onChange={handleInviteCodeChange}
								placeholder="Enter Invite Code"
								className="flex-grow px-4 py-2 border border-[#878787] rounded-l-full focus:outline-none focus:border-pinhous-red"
							/>
							<button
								onClick={validateInviteCode}
								className="px-4 py-2 bg-pinhous-red text-white rounded-r-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
							>
								Submit
							</button>
						</div>
						{errors.inviteCode && (
							<p className="text-pinhous-red text-xs mt-1">
								{errors.inviteCode}
							</p>
						)}
					</div>
				) : (
					<>
						<form className="space-y-4" onSubmit={handleSubmit}>
							<div>
								<input
									type="text"
									name="fname"
									placeholder="First Name"
									value={formData.fname}
									onChange={handleChange}
									className={`w-full px-4 py-2 border ${
										errors.fname
											? 'border-pinhous-red'
											: 'border-[#878787]'
									} rounded-full focus:outline-none focus:border-pinhous-red`}
								/>
								{errors.fname && (
									<p className="text-pinhous-red text-xs mt-1">
										{errors.fname}
									</p>
								)}
							</div>
							<div>
								<input
									type="text"
									name="lname"
									placeholder="Last Name"
									value={formData.lname}
									onChange={handleChange}
									className={`w-full px-4 py-2 border ${
										errors.lname
											? 'border-pinhous-red'
											: 'border-[#878787]'
									} rounded-full focus:outline-none focus:border-pinhous-red`}
								/>
								{errors.lname && (
									<p className="text-pinhous-red text-xs mt-1">
										{errors.lname}
									</p>
								)}
							</div>
							<div>
								<input
									type="email"
									name="email"
									placeholder="Email"
									value={formData.email}
									onChange={handleChange}
									className={`w-full px-4 py-2 border ${
										errors.email
											? 'border-pinhous-red'
											: 'border-[#878787]'
									} rounded-full focus:outline-none focus:border-pinhous-red`}
								/>
								{errors.email && (
									<p className="text-pinhous-red text-xs mt-1">
										{errors.email}
									</p>
								)}
							</div>
							<div className="relative">
								<input
									type={showPassword ? 'text' : 'password'}
									name="password"
									placeholder="Password"
									value={formData.password}
									onChange={handleChange}
									className={`w-full px-4 py-2 border ${
										errors.password
											? 'border-pinhous-red'
											: 'border-[#878787]'
									} rounded-full focus:outline-none focus:border-pinhous-red`}
								/>
								<button
									type="button"
									onClick={togglePasswordVisibility}
									className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
								>
									{showPassword ? 'Hide' : 'Show'}
								</button>
								{errors.password && (
									<p className="text-pinhous-red text-xs mt-1">
										{errors.password}
									</p>
								)}
							</div>
							<div className="relative">
								<input
									type={showPassword ? 'text' : 'password'}
									name="confirmPassword"
									placeholder="Confirm Password"
									value={formData.confirmPassword}
									onChange={handleChange}
									className={`w-full px-4 py-2 border ${
										errors.confirmPassword
											? 'border-pinhous-red'
											: 'border-[#878787]'
									} rounded-full focus:outline-none focus:border-pinhous-red`}
								/>
								<button
									type="button"
									onClick={togglePasswordVisibility}
									className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
								>
									{showPassword ? 'Hide' : 'Show'}
								</button>
								{errors.confirmPassword && (
									<p className="text-pinhous-red text-xs mt-1">
										{errors.confirmPassword}
									</p>
								)}
							</div>
							{errors.submit && (
								<p className="text-pinhous-red text-xs mt-1">
									{errors.submit}
								</p>
							)}
							{userError && (
								<p className="text-pinhous-red text-xs mt-1">
									{userError}
								</p>
							)}
							<button
								type="submit"
								className="w-full py-2 px-4 bg-pinhous-red text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
								disabled={userStatus === 'loading'}
							>
								{userStatus === 'loading' ? 'Signing Up...' : 'Sign Up'}
							</button>
						</form>

						<div className="mt-4 text-center">
							<a
								className="text-sm text-gray-600 hover:underline cursor-pointer"
								onClick={(e) => {
									e.preventDefault();
									alert('Link to terms and conditions when available');
									// navigate('/forgot-password');
								}}
							>
								By signing up you agree to Pinhous Terms of Use and
								Privacy Policy.
							</a>
						</div>
					</>
				)}

				<div className="mt-6 flex items-center justify-between">
					<hr className="w-full border-[#000]" />
					<span className="px-2 text-gray-500 bg-white">or</span>
					<hr className="w-full border-[#000]" />
				</div>

				<button
					className="mt-4 w-full py-2 px-4 border border-gray-300 rounded-full flex items-center justify-center space-x-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
					onClick={(e) => {
						e.preventDefault();
						// navigate('/signup');
						alert('Coming Soon');
					}}
				>
					<svg className="w-5 h-5" viewBox="0 0 24 24">
						<path
							d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
							fill="#4285F4"
						/>
						<path
							d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
							fill="#34A853"
						/>
						<path
							d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
							fill="#FBBC05"
						/>
						<path
							d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
							fill="#EA4335"
						/>
					</svg>
					<span className="text-[#000] font-normal">Login with Google</span>
				</button>

				<div className="mt-6 text-center">
					<span className="text-black text-base font-normal font-['Quicksand'] leading-tight">
						Already have an account?{' '}
					</span>
					<a
						className="text-[#345B84] hover:underline cursor-pointer"
						onClick={(e) => {
							e.preventDefault();
							navigate('/login');
						}}
					>
						Login
					</a>
				</div>
			</div>
		</div>
	);
};

export default SignUpForm;
