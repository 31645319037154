import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';
import { ReactComponent as Copy } from '../../icons/copyOutline.svg';
import agentInviteCard from '../../images/agent_invite_card.png';

const Onboarding = () => {
	const navigate = useNavigate();
	const [activeIndex, setActiveIndex] = useState('why');

	const toggleSection = (index) => {
		// setActiveIndex(activeIndex === index ? null : index);
		setActiveIndex(index);
	};
	const [selectedOption, setSelectedOption] = useState({
		why: null,
		mortage: null,
		agent: null,
		communication: []
	});
	const options = [
		'Looking for my first home',
		'Homeowner looking to move',
		'Buying a second property',
		'Real estate investor',
		'Just browsing'
	];
	const mortageOptions = ['Yes', 'No'];
	const agentOptions = ['Yes', 'No'];
	const communicationOptions = ['Phone', 'Text', 'Email'];
	const indexArr = ['why', 'mortage', 'agent', 'communication'];
	return (
		<div className="w-[100vw] min-h-screen overflow-y-auto flex flex-col justify-center items-center">
			<div className="w-4/6 h-full flex flex-col justify-start items-center gap-3">
				<div className="flex-col justify-start items-center gap-3 flex pt-6">
					<Logo />
				</div>
				<div
					className="w-full max-w-md gap-[10px] px-[20px] py-[30px] flex flex-col rounded-[20px] bg-white shadow-[4px_4px_16px_0px_rgba(0,0,0,0.15)]"
					onClick={() => setActiveIndex('why')}
				>
					<h1>Why are you using Pinhous?</h1>
					{activeIndex == 'why' &&
						options.map((option, index) => (
							<button
								key={index}
								className={`w-full flex h-12 px-5 items-center gap-2.5 border-2 border-pinhous-grey text-black rounded-full text-center font-quicksand text-[15px] font-normal leading-[20px] tracking-[-0.4px] ${
									selectedOption.why === index
										? 'border-pinhous-red text-pinhous-red'
										: 'border-gray-300 text-black'
								}`}
								onClick={() => {
									setSelectedOption((prev) => ({
										...prev,
										why: index
									}));
								}}
							>
								{option}
							</button>
						))}
					{activeIndex != 'why' && selectedOption.why != null && (
						<h1 className="text-pinhous-red">
							{options[selectedOption.why]}
						</h1>
					)}
				</div>
				<div
					className="w-full max-w-md gap-[10px] px-[20px] py-[30px] flex flex-col rounded-[20px] bg-white shadow-[4px_4px_16px_0px_rgba(0,0,0,0.15)]"
					onClick={() => setActiveIndex('mortage')}
				>
					<h1>Preapproved for a mortage?</h1>
					{activeIndex == 'mortage' &&
						mortageOptions.map((option, index) => (
							<button
								key={index}
								className={`w-full flex h-12 px-5 items-center gap-2.5 border-2 border-pinhous-grey text-black rounded-full text-center font-quicksand text-[15px] font-normal leading-[20px] tracking-[-0.4px] ${
									selectedOption.mortage === index
										? 'border-pinhous-red text-pinhous-red'
										: 'border-gray-300 text-black'
								}`}
								onClick={() => {
									setSelectedOption((prev) => ({
										...prev,
										mortage: index
									}));
								}}
							>
								{option}
							</button>
						))}
					{activeIndex === 'mortage' &&
						mortageOptions[selectedOption.mortage] === 'No' && (
							<>
								<hr />
								<span className="text-pinhous-red font-['Quicksand'] text-sm font-semibold tracking-[-0.4px] underline">
									Get pre-approved
								</span>
							</>
						)}
					{activeIndex !== 'mortage' && selectedOption.mortage !== null && (
						<h1 className="text-pinhous-red">
							{mortageOptions[selectedOption.mortage]}
						</h1>
					)}
				</div>
				<div
					className="w-full max-w-md gap-[10px] px-[20px] py-[30px] flex flex-col rounded-[20px] bg-white shadow-[4px_4px_16px_0px_rgba(0,0,0,0.15)]"
					onClick={() => setActiveIndex('agent')}
				>
					<h1>Do you have an Agent?</h1>
					{activeIndex === 'agent' &&
						agentOptions.map((option, index) => (
							<button
								key={index}
								className={`w-full flex h-12 px-5 items-center gap-2.5 border-2 border-pinhous-grey text-black rounded-full text-center font-quicksand text-[15px] font-normal leading-[20px] tracking-[-0.4px] ${
									selectedOption.agent === index
										? 'border-pinhous-red text-pinhous-red'
										: 'border-gray-300 text-black'
								}`}
								onClick={() => {
									setSelectedOption((prev) => ({
										...prev,
										agent: index
									}));
								}}
							>
								{option}
							</button>
						))}
					{activeIndex == 'agent' &&
						mortageOptions[selectedOption.agent] == 'Yes' && (
							<div className="flex flex-col gap-[10px] justify-center">
								<hr />
								<span className="text-black font-['Quicksand'] text-[21px] font-normal tracking-[-0.4px]">
									Invite your agent to collaborate
								</span>
								<span className="text-[#3F3F3F] font-['Quicksand'] text-sm font-normal">
									Create and share boards with your agent and contact
									them when you find the right home
								</span>
								<div className="rounded-[10px] self-center bg-white shadow-[4px_4px_14px_0px_rgba(0,0,0,0.15)] w-[243px] h-[213px]">
									<img
										// className="shadow-[4px_4px_14px_0px_rgba(0,0,0,0.15)]"
										src={agentInviteCard}
									/>
								</div>
								<div className="flex justify-center gap-[6px] items-center">
									<span className="text-pinhous-red font-['Quicksand'] text-sm font-semibold tracking-[-0.4px] underline">
										{`Agent Invite Link`}
									</span>
									<Copy />
								</div>
							</div>
						)}
					{activeIndex != 'agent' && selectedOption.agent != null && (
						<h1 className="text-pinhous-red">
							{agentOptions[selectedOption.agent]}
						</h1>
					)}
				</div>
				<div
					className="w-full max-w-md gap-[10px] px-[20px] py-[30px] flex flex-col rounded-[20px] bg-white shadow-[4px_4px_16px_0px_rgba(0,0,0,0.15)]"
					onClick={() => setActiveIndex('communication')}
				>
					<h1>Communication preferences</h1>
					{activeIndex == 'communication' &&
						communicationOptions.map((option, index) => (
							<button
								key={index}
								className={`w-full flex h-12 px-5 items-center gap-2.5 border-2 border-pinhous-grey text-black rounded-full text-center font-quicksand text-[15px] font-normal leading-[20px] tracking-[-0.4px] ${
									selectedOption.communication.includes(index)
										? 'border-pinhous-red text-pinhous-red'
										: 'border-gray-300 text-black'
								}`}
								onClick={() => {
									setSelectedOption((prev) => ({
										...prev,
										communication: prev.communication.includes(index)
											? prev.communication.filter(
													(item) => item !== index
											  )
											: [...prev.communication, index]
									}));
								}}
							>
								{option}
							</button>
						))}
					{activeIndex !== 'communication' &&
						selectedOption.communication.length !== 0 &&
						selectedOption.communication.map((opt, index) => (
							<h1 className="text-pinhous-red" key={index}>
								{communicationOptions[opt]}
							</h1>
						))}
					{/* {activeIndex != 'communication' &&
						selectedOption.communication != [''] &&
						selectedOption.communication.map((value, index) => {
							<h1 className="text-pinhous-red">
								{communicationOptions[value]}
							</h1>;
						})} */}
				</div>
				<button
					className="flex items-center gap-2.5 px-6 py-3.5 mt-3 rounded-full bg-black text-white"
					onClick={() => {
						console.log('button clicked');
						indexArr.indexOf(activeIndex) === 3
							? console.log('submit')
							: setActiveIndex(indexArr[indexArr.indexOf(activeIndex) + 1]);
					}}
				>
					Continue
				</button>
			</div>
		</div>
	);
};

export default Onboarding;
