import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useMatch, useParams } from 'react-router-dom';
import { ReactComponent as Bed } from '../../icons/bed2.svg';
import { ReactComponent as Bath } from '../../icons/bath2.svg';
import { ReactComponent as Car } from '../../icons/car.svg';
import { ReactComponent as PinOutline } from '../../icons/pinOutline.svg';
import { ReactComponent as PinFill } from '../../icons/pinFilled.svg';
import { ReactComponent as AiSearchIcon } from '../../icons/ai_search.svg';
import { Trash2 } from 'lucide-react';
import ImageSlider from '../Slider/ImageSlider';
import { deleteSavedListingAsync } from '../../features/savedListings/savedListingsSlice';
import {
	fetchSingleActiveListing,
	toggleImageSearchModal,
	toggleBoardModal,
	setListingPinActive
} from '../../features/active/activeSlice';
import {
	setImageUrl,
	applySearchParams
} from '../../features/searchParams/searchParamsSlice';
import {
	fetchBoardsWithListingsAsync,
	selectSavedListingMlsNumbers
} from '../../features/boards/boardsSlice';
import { getDaysAgo } from '../../utils/date';

const pattern = [
	'medium',
	'large',
	'medium',
	'large',
	'medium',
	'small',
	'large',
	'large',
	'small',
	'small',
	'medium',
	'small',
	'large',
	'small',
	'large'
];

const removeNewLines = (obj) => {
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => [
			key,
			typeof value === 'string' ? value.replace(/\n/g, ' ').trim() : value
		])
	);
};

const formatPrice = (price) => {
	const numericPrice =
		typeof price === 'string' ? parseFloat(price.replace(/[^\d.-]/g, '')) : price;

	if (typeof numericPrice !== 'number' || isNaN(numericPrice)) {
		return '$0';
	}

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	});

	return formatter.format(numericPrice);
};

const formatAddress = (address) => {
	const cleanAddress = removeNewLines(address);

	const components = [
		cleanAddress.streetNumber,
		cleanAddress.streetDirectionPrefix,
		cleanAddress.streetName,
		cleanAddress.streetSuffix,
		cleanAddress.streetDirection,
		cleanAddress.unitNumber && `Unit ${cleanAddress.unitNumber}`,
		cleanAddress.city,
		cleanAddress.state,
		cleanAddress.zip,
		cleanAddress.country
	].filter(Boolean);

	return components.join(' ');
};

const Card2 = ({ data, index, isMap, isMapList, onDelete }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const match = useMatch('/boards/:id');
	const { boardId } = useParams();
	const savedListingMlsNumbers = useSelector(selectSavedListingMlsNumbers);

	const isLiked = savedListingMlsNumbers?.includes(data.mlsNumber);

	const handleLikeClick = (event) => {
		event.stopPropagation();
		dispatch(setListingPinActive(data));
		dispatch(toggleBoardModal());
	};

	const handleCardClick = () => {
		dispatch(fetchSingleActiveListing(data.mlsNumber));
		navigate(`/listings/${data.mlsNumber}`);
	};

	const handleAISearch = (event) => {
		event.stopPropagation();
		if (data.images && data.images.length > 0) {
			const imageUrl = `https://cdn.repliers.io/${data.images[0]}`;
			dispatch(setImageUrl(imageUrl));
			dispatch(applySearchParams());
		}
	};

	const handleDelete = (event) => {
		event.stopPropagation();
		dispatch(
			deleteSavedListingAsync({
				mlsNumber: data.mlsNumber,
				boardId
			})
		).then(() => {
			dispatch(fetchBoardsWithListingsAsync());
			if (onDelete) {
				onDelete(data.mlsNumber);
			}
		});
	};

	return (
		<div
			className={`group w-full flex flex-col overflow-hidden transition-transform duration-300 ease-in-out break-inside-avoid hover:-translate-y-[5px] card-${
				isMapList
					? 'small text-sm'
					: isMap
					? 'small h-[300px] w-[300px] mb-0 hover:transform-none'
					: pattern[index % 15]
			}`}
			onClick={handleCardClick}
		>
			<div className="relative w-full h-full">
				<ImageSlider images={data.images} isCard={true} />
				<div className="absolute top-4 left-4 w-20 h-8 z-20">
					<div className="w-full h-full bg-white/50 rounded-full flex items-center justify-center border border-black">
						{data.type === 'Sale' ? 'For Sale' : 'For Sale'}
					</div>
				</div>
				<div className="absolute top-4 right-4 flex space-x-2 z-20">
					{match && (
						<button
							onClick={handleDelete}
							className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
						>
							<Trash2 className="w-6 h-6" />
						</button>
					)}
					<button
						onClick={handleAISearch}
						className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
					>
						<AiSearchIcon className="w-6 h-6" />
					</button>
					<button
						onClick={handleLikeClick}
						className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors"
					>
						{isLiked ? (
							<PinFill className="text-red-500 text-2xl" />
						) : (
							<PinOutline className="text-gray-800 text-2xl" />
						)}
					</button>
				</div>
				<div className="absolute group-hover:opacity-0 bottom-4 left-4 w-6 h-6  z-20">
					<div className="w-full h-full bg-black/50 rounded-full flex items-center justify-center border border-black text-[#FFFFFF] italic">
						i
					</div>
				</div>
				{/* rounded */}
				{/* <div className="absolute bottom-0 w-full z-20 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 bg-gradient-to-t from-black to-transparent rounded-[31.65px]"> */}
				<div className="absolute bottom-0 w-full z-20 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 bg-gradient-to-t from-black to-transparent ">
					<div className=" px-5 pb-5 pt-50 flex flex-col items-start justify-center text-[#FFFFFF] text-sm">
						<div className="w-full flex justify-between items-center">
							<div className="font-bold text-3xl">
								{formatPrice(data.listPrice)}
							</div>
							<p className="text-lg">
								{/* {data.daysOnMarket === '0' && 'Today'}
								{data.daysOnMarket === '1' && 'a day ago'}
								{data.daysOnMarket !== '1' &&
									data.daysOnMarket !== undefined &&
									data.daysOnMarket !== '0' &&
									`${data.daysOnMarket} days ago`} */}
								{getDaysAgo(data.listDate)}
							</p>
						</div>
						<div className="whitespace-nowrap overflow-hidden text-ellipsis text-xl">
							{formatAddress(data.address)}
						</div>
						<div className="flex text-xl items-center gap-1">
							<Bed className="w-5 h-5" />
							<span className="text-xl">{`${data.details.numBedrooms}`}</span>{' '}
							<Bath className="w-6 h-6 p-1" />
							<span className="text">{`${data.details.numBathrooms}`}</span>
							{data.details.numGarageSpaces && (
								<Car className="w-6 h-6 p-1" />
							)}
							{data.details.numGarageSpaces && (
								<span className="text">{`${parseInt(
									data.details.numGarageSpaces
								)}`}</span>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card2;
