import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/logo_base.svg';

const SplashScreen = () => {
	const navigate = useNavigate();
	return (
		<div className="w-[100vw] min-h-screen flex flex-col justify-evenly items-center">
			<div className="h-full flex flex-col justify-end items-center gap-3">
				{/* <div className="h-[20%]"></div> */}
				<div className="flex-col justify-start items-center gap-3 flex pt-6">
					{/* <div className="h-[10%]"></div> */}
					<Logo />
					<div className="self-stretch text-center text-black text-3xl font-medium font-['Quicksand']">
						Pinhous
					</div>
				</div>
				<div className="py-2.5 bg-white flex-col justify-start items-center flex">
					<div className="text-center text-black text-lg font-normal font-['Quicksand']">
						The Right Way to Find Your
					</div>
					<div className="text-black text-lg font-normal font-['Quicksand']">
						Dream Home
					</div>
				</div>
			</div>

			<div className="h-full flex flex-col justify-end items-center gap-[22px]">
				<div className="px-6 py-3.5 bg-pinhous-red rounded-3xl flex-col justify-center items-center gap-2.5 flex">
					<button
						className="self-stretch text-center text-white text-[13px] font-bold font-['Quicksand']"
						onClick={() => {
							navigate('/signup');
						}}
					>
						Continue
					</button>
				</div>
				<div>
					<span className="text-black text-base font-normal font-['Quicksand'] leading-tight">
						Already have an account?{' '}
					</span>
					<span
						className="text-[#345b84] text-base font-medium font-['Quicksand'] leading-tight"
						onClick={() => {
							navigate('/login');
						}}
					>
						Login
					</span>
				</div>
				<div className="h-[15%]"></div>
			</div>
		</div>
	);
};

export default SplashScreen;
