import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlusCircle, Trash2 } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import {
	clearListingPinActive,
	selectBoardModalToggle,
	toggleBoardModal,
	selectListingPinActive
} from '../../features/active/activeSlice';
import {
	selectAllBoards,
	selectBoardsStatus,
	selectBoardsError,
	fetchBoardsAsync,
	createBoardAsync,
	deleteBoardAsync,
	selectAllBoardsWithListings,
	fetchBoardsWithListingsAsync
} from '../../features/boards/boardsSlice';

const Boards = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isOpen = useSelector(selectBoardModalToggle);
	// const boards = useSelector(selectAllBoards);
	const { boards, boardListings } = useSelector(selectAllBoardsWithListings);
	const boardsStatus = useSelector(selectBoardsStatus);
	const boardsError = useSelector(selectBoardsError);
	const listingToPin = useSelector(selectListingPinActive);

	useEffect(() => {
		if (boardsStatus === 'idle') {
			// dispatch(fetchBoardsAsync());
			dispatch(fetchBoardsWithListingsAsync());
		}
	}, [isOpen, boardsStatus, dispatch]);

	useEffect(() => {
		console.log(boards);
	}, []);

	const handleBoardClick = (boardId) => {
		console.log(`Board clicked: ${boardId}`);
		console.log('Listing Pin', listingToPin);
		navigate(`/boards/${boardId}`);
	};

	const handleCreateNewBoard = () => {
		const newBoardName = prompt('Enter a name for the new board:');
		if (newBoardName) {
			dispatch(createBoardAsync({ name: newBoardName }));
		}
	};

	let content;

	if (boardsStatus === 'loading') {
		content = <div>Loading boards...</div>;
	} else if (boardsStatus === 'failed') {
		content = (
			<div>Error: {boardsError ? boardsError.toString() : 'Unknown error'}</div>
		);
	} else if (boards.length === 0) {
		content = <div>No boards found. Create a new one!</div>;
	} else {
		content = (
			<div className="grid grid-cols-2 gap-4 overflow-y-auto">
				{boards.map((board) => (
					<div
						key={board.id}
						className="no-underline"
						// to={`/boards/${board.id.toString()}`}
					>
						<div
							className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
							onClick={() => handleBoardClick(board.id)}
						>
							<div className="h-24 w-full flex justify-end bg-pinhous-red">
								<div
									className="w-12 h-12 bg-white/50 rounded-full flex items-center justify-center hover:bg-white/75 transition-colors m-2"
									onClick={(e) => {
										e.stopPropagation();
										dispatch(deleteBoardAsync(board.id));
									}}
								>
									<Trash2 size={24} />
								</div>
							</div>
							<div className="p-2">
								<h3 className="font-semibold text-sm">{board.name}</h3>
								<p className="text-xs text-gray-500">
									{board.SavedListings?.length || 0} items
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col h-full p-6">
				<div className="flex justify-center items-center mb-6 w-full">
					<h2 className="text-2xl font-bold">Boards</h2>
				</div>

				{content}

				<div
					className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer mt-4"
					onClick={handleCreateNewBoard}
				>
					<div className="text-center p-4">
						<PlusCircle className="mx-auto mb-2" size={24} />
						<p className="text-sm font-semibold">Create new board</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Boards;
