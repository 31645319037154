import React from 'react';
import { Plus, Minus } from 'lucide-react';

const AccordionSection = ({ title, isExpanded, onToggle, icon: Icon, children }) => (
	<div
		className={`
      flex flex-col 
      border-t 
      rounded-sm 
      border-gray-100 
      bg-gray-100 
      transition-colors
      duration-700
      ease-in-out
      ${isExpanded ? 'pb-2 bg-gray-100' : 'hover:bg-gray-200'}
    `}
	>
		<button
			onClick={onToggle}
			className="w-full flex items-center justify-between p-4 "
		>
			<div className="flex items-center gap-3">
				<Icon className="w-5 h-5 text-gray-800" />
				<span className="text-gray-500 font-light">{title}</span>
			</div>
			{isExpanded ? (
				<Minus className="w-5 h-5 text-gray-400" />
			) : (
				<Plus className="w-5 h-5 text-gray-400" />
			)}
		</button>
		{isExpanded && <div className="mx-2  rounded-lg bg-white">{children}</div>}
	</div>
);

export default AccordionSection;
